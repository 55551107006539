import { Component, Input } from '@angular/core';
import { RouteHelper } from '@/helpers/route.helper';
import { ProfileService } from '@/services/profile.service';

@Component({
  selector: 'tc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() tabs: { label: string, path: string, show: boolean }[] = [];

  constructor(
    public routeHelper: RouteHelper,
    public profile: ProfileService,
  ) { }
}
