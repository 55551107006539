<tpt-dialog>
  <div class="fw-700 text-black title">
    Чаты с исполнителем
  </div>

  <div class="fs-14 lh-20 fw-500 text-gray-800 mb-32">
    У вас уже есть активные проекты с этим исполнителем. Вы можете перейти
    в уже созданные чаты или же пригласить исполнителя на другой проект
  </div>

  <div class="chat" *ngFor="let chat of chats" (click)="goToChat(chat)">
    <div class="">
      <div class="fs-14 fw-600 text-black lh-20 mb-4">{{chat.projectName}}</div>
      <div class="fs-12 fw-500 text-gray-600">{{chat.jobName}}</div>
    </div>
    <mat-icon [svgIcon]="svgIconsEnum.ArrowForward" class="text-green"></mat-icon>
  </div>

  <div class="buttons-row">
    <tc-button type="tertiary" size="x-large">Отмена</tc-button>
    <tc-button type="primary" size="x-large"
               class="full-width"
               (onClick)="openHireDialog()"
    >
      Пригласить на другой проект
    </tc-button>
  </div>

</tpt-dialog>
