import { Injectable } from '@angular/core';
import { Observable, share } from 'rxjs';

import { BaseService } from './base.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private baseService: BaseService) {}

  public getNotifications = (): Observable<any> => {
    return this.baseService.executeGet('v2/notifications');
  }

  public getCountNotifications = (): Observable<any> => {
    return this.baseService.executeGet('v2/notifications/unreadCount').pipe(share());
  }

  public markAllAsRead = (): Observable<any> => {
    return this.baseService.executePut('v2/notifications/all', null);
  }

  public markOneAsRead = (id: string): Observable<any> => {
    return this.baseService.executePut('v2/notifications', null, { params: { id }});
  }

  public deleteNotification = (id: string): Observable<any> => {
    return fromPromise(this.baseService.executeDelete('v2/notifications', { params: { id } }));
  }
}
