import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ValidationErrorService } from '@/modules/tpt-forms/services/validation-error.service';
import { JobDocumentsModel } from '@/models/financev2/job-documents.model';
import { ResourcesService } from '@/services/resources.service';
import { ReviewModel } from '@/models/feedback/rating-variables';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { ProjectApiService } from '@/services/project.api.service';
import {SimpleProjectResponseModel} from '@/modules/employer/models/simple-project-response.model';

@Component({
  selector: 'app-finish-job-dialog',
  templateUrl: './finish-job-dialog.component.html',
  styleUrls: ['./finish-job-dialog.component.scss']
})
export class FinishJobDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() updateJob = new EventEmitter();

  public svgIconsEnum = SvgIconsEnum;

  public docs: JobDocumentsModel;

  public acceptTerms = {
    confirmResults: false,
    confirmPayment: false,
    confirmCommission: false,
  };

  public project: SimpleProjectResponseModel;

  private className = 'app-finish-job-dialog';

  private config: MatDialogConfig = {
    width: '600px',
    maxWidth: 'calc(100vw - 32px)',
    minHeight: '390px',
    maxHeight: '100vh',
  };

  constructor(private validationErrorService: ValidationErrorService,
              private resource: ResourcesService,
              private spinnerService: SpinnerService,
              private projectService: ProjectApiService,
              ) { }

  public open(docs: JobDocumentsModel, project: SimpleProjectResponseModel) {
    this.dialog.config = this.config;
    this.docs = docs;
    this.project = project;
    this.acceptTerms = {
      confirmResults: false,
      confirmPayment: false,
      confirmCommission: false,
    };

    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public openDoc(fileId): void {
    this.resource.getFileByUUID(fileId).subscribe(this.handleFile);
  }

  public handleFile = (file: File): void => {
    const objectURL = this.resource.createObjectURL(file);

    window.open(objectURL, '_blank');
    URL.revokeObjectURL(objectURL);
  }

  public error(ctrl): string {
    return this.validationErrorService.getValidationErrorMessage(ctrl);
  }

  public errorState(ctrl): boolean {
    return ctrl.invalid && (ctrl.dirty || ctrl.touched);
  }

  public finishNoFeedback(): void {
    if (this.checkBoxesNotValid()) {
      return;
    }

    this.handleTaskAccept();
  }

  public handleTaskAccept = (body: ReviewModel = null): void => {
    this.spinnerService.startSpinner();
    this.projectService.finishJob(this.project.id, body).subscribe({
      next: () => {
        this.close();
        this.updateJob.emit();
        this.spinnerService.stopSpinner();
      },
      error: () => this.spinnerService.stopSpinner()
    });
  };

  public checkBoxesNotValid(): boolean {
    if (!(this.acceptTerms.confirmPayment && this.acceptTerms.confirmResults)) {
      return true;
    }
    // это поле обязательно только если комиссия > 0
    if (!!this.project.commissionValue && !this.acceptTerms.confirmCommission) {
      return true;
    }

    return false;
  }
}
