import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from '@/services/profile.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { EditProfileService } from '@/modules/edit-profile/services/edit-profile.service';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileFilledService } from '@/services/profile-filled.service';

@Component({
  selector: 'tc-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent implements OnInit, OnDestroy {

  @Input() showIcon = true;

  public svgIconsEnum = SvgIconsEnum;

  public clientDataInvalid = false;

  public loaded = false;

  private readonly destroy$ = new Subject<void>();

  constructor(public profile: ProfileService,
              public legalService: ProfilesApiService,
              private editProfileService: EditProfileService,
              ) {

  }

  async ngOnInit(): Promise<void> {
    if (this.profile.currentProfile.isEmployer()) {
      await this.getEmployerStatus();

      this.legalService.legalDataUpdated.pipe(takeUntil(this.destroy$)).subscribe(async () => {
        await this.getEmployerStatus();
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async getEmployerStatus(): Promise<void> {
    await lastValueFrom(this.editProfileService.getUserLegal());
    const legalStatus = this.editProfileService.legalStatus;
    this.clientDataInvalid = ProfileFilledService.clientDataInvalid(this.profile.currentProfile, legalStatus);
    this.loaded = true;
  }

}
