import { Component, Input, OnInit } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ResourcesService } from '@/services/resources.service';

@Component({
  selector: 'tc-job-tasks-list',
  templateUrl: './job-tasks-list.component.html',
  styleUrls: ['./job-tasks-list.component.scss']
})
export class JobTasksListComponent implements OnInit {

  @Input() project;

  @Input() showFiles: boolean;

  public svgIconsEnum = SvgIconsEnum;

  constructor(private resource: ResourcesService) { }

  ngOnInit(): void {
  }

  public downloadFile(doc) {
    this.resource.getFile(doc?.id);
  }
}
