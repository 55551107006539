import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'tc-jobs-extended-sidebar',
  templateUrl: './jobs-extended-sidebar.component.html',
  styleUrls: ['./jobs-extended-sidebar.component.scss']
})
export class JobsExtendedSidebarComponent implements OnInit {

  public get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  @Output() scrollTo = new EventEmitter<string>();

  @Output() goToCreateProject = new EventEmitter<void>();

  @Input() public activeJobs = [];
  @Input() public draftJobs = [];
  @Input() public completedJobs = [];

  public svgIconsEnum = SvgIconsEnum;

  public menuItems = {
    active: { icon: this.svgIconsEnum.ActiveProject, label: 'Активные', expanded: true },
    ended: { icon: this.svgIconsEnum.Ended, label: 'Завершенные', expanded: false },
    draft: { icon: this.svgIconsEnum.Draft, label: 'Черновики', expanded: false },
  };

  constructor(private profileService: ProfileService,
              public routeHelper: RouteHelper,

              ) { }

  ngOnInit(): void {
  }

}
