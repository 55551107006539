import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable, Subject } from 'rxjs';

import { ProfileModel } from '../models/profile.model';
import { UpdateEmailRequestModel } from '../modules/edit-profile/models/update-email-request.model';
import { UpdatePassRequestModel } from '../modules/edit-profile/models/update-pass-request.model';
import { BankDetailsModel, LegalStatus } from '../models/userLegalStatus.model';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import {FileUpload} from '@/models/fileUpload';
import { SpecializationModel } from '@/models/specialization.model';

@Injectable({
  providedIn: 'root',
})
export class ProfilesApiService {

  public legalDataUpdated = new Subject<void>();

  constructor(
    private baseService: BaseService,
  ) {
  }

  public getUserProfile = (): Observable<ProfileModel> => {
    return this.baseService.executeGet('v2/users/me');
  }

  public getUserLegal = (): Observable<LegalStatus> => {
    return this.baseService.executeGet('v2/users/legal');
  }

  public updateMainInfo = (model: ProfileModel): Observable<void> => {
    return this.baseService.executePut('v2/users/mainInfo', model);
  }

  public updateContacts = (model: ProfileModel): Observable<void> => {
    return this.baseService.executePut('v2/users/contacts', model);
  }

  public updateSpecializations = (model: SpecializationModel[]): Observable<void> => {
    return this.baseService.executePut('v2/users/specialization', model);
  }

  public updateEmail = (model: UpdateEmailRequestModel): Observable<void> => {
    return this.baseService.executePut('v2/users/email', model);
  }

  public updatePassword = (model: UpdatePassRequestModel): Observable<void> => {
    return this.baseService.executePut('v2/users/password', model);
  }

  public setLocale = (): Observable<void> => {
    return this.baseService.executePut('v2/users/setLocale', null);
  }

  public getMyReviews = (): Observable<any> => {
    return this.baseService.executeGet('v2/users/reviews/aboutMe');
  }

  public resendUpdateEmail = (): Observable<any> => {
    return fromPromise(this.baseService.executePost('v2/users/resendUpdateEmailMail', null));
  }

  public getUserReviews = (profileId: number = null, estimatorId: number = null): Observable<any> => {
    const params = {
      profileId,
      estimatorId
    };

    if (!estimatorId) {
      delete params.estimatorId;
    }

    return this.baseService.executeGet('v2/users/reviews', { params });
  }

  public getReviewsLink(fileId: string): string {
    return `${this.baseService.baseUrl}storage?fileId=${fileId}`;
  }

  public resendRegistrationEmail(email): Observable<any> {
    return fromPromise(this.baseService.executePost(`v2/users/resendRegistrationEmail`, null, {params: {email}}));
  }

  public getUser = (id: string): Observable<any> => {
    return this.baseService.executeGet(`v2/users/${id}`);
  }

  public getIncomeStatement = (year: number, leap: boolean): Observable<FileUpload> => {
    return this.baseService.executeGet(`v2/users/legal/selfEmployed/incomeStatement`, {params: { year, leap }});
  }

  public getRegistrationCertificate = (year: number, leap: boolean): Observable<FileUpload> => {
    return this.baseService.executeGet(`v2/users/legal/selfEmployed/registrationCertificate`, {params: { year, leap }});
  }

  public saveBankDetails = (bankDetails: BankDetailsModel[]): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/bankDetails`, bankDetails);
  }

  public saveForeignBankDetails = (bankDetails: BankDetailsModel): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/foreignBankDetails`, bankDetails);
  }

  public saveAddress = (address): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/address`, address);
  }

  public savePassport = (passport): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/passport`, passport);
  }

  public setLegalStatus = (legalStatus): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/setLegalStatus`, null, { params: { legalStatus } });
  }

  public saveSigner = (signer): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/signer`, signer);
  }

  public saveIeInfo = (info): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/iePersonInfo`, info);
  }

  public saveLegalInfo = (info): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/legalInfo`, info);
  }

  public saveForeignAgencyLegalInfo = (info): Observable<any> => {
    return this.baseService.executePut(`v2/users/legal/foreignAgencyLegalInfo`, info);
  }

  public hasActiveProjects = (): Observable<boolean> => {
    return this.baseService.executeGet(`v2/users/hasActiveProjects`);
  }

  public commissionInfo = (): Observable<{ fiatMin: number, valuePercent: number }> => {
    return this.baseService.executeGet('v2/employer/commission');
  }

  public forgotPasswordOtherAccount = (body): Observable<any> => {
    return fromPromise(this.baseService.executePost(`v2/users/legal/selfEmployed/forgotPasswordOtherAccount`, body));
  }

  public acceptTalentCloud = (): Observable<any> => {
    return fromPromise(this.baseService.executePost(`v2/users/acceptTalentCloud`, null));
  }

  public acceptCreativeObject = (): Observable<any> => {
    return fromPromise(this.baseService.executePost(`v2/users/acceptCreativeObject`, null));
  }

  public acceptPayYourTeam = (): Observable<any> => {
    return fromPromise(this.baseService.executePost(`v2/users/acceptPayYourTeam`, null));
  }

  public getSelfEmployedMaxPayment = (id: string): Observable<{amount: number}> => {
    return this.baseService.executeGet('v2/users/legal/selfEmployed/getSelfEmployedMaxPayment', { params: { id } });
  }
}
