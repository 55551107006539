import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
}) export class StateHelperService {

  public sidebarOpened = new Subject<void>();

  public desktopSidebarOpened = new BehaviorSubject<boolean>(false);

}
