<tpt-dialog>
  <div class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>
    <div class="fs-20 fw-700 text-black mb-16">
      Ограниченный доступ
    </div>

    <div class="text-gray-600 fw-500 fs-14 mb-28">
      {{isInvite ? 'Заполните личный кабинет для отправки приглашения на проект' : 'Для публикации проекта необходимо заполнить профиль.'}}
    </div>
    <div class="actions-row mt-48 gg-12">
      <tc-button type="tertiary" size="large" (onClick)="close()">
        Закрыть
      </tc-button>
      <tc-button type="primary" size="large" class="full-width"
                  (onClick)="fillProfile()"
                  [routerLink]="routeHelper.settingsLegalData">
        Заполнить
      </tc-button>
    </div>
  </div>
</tpt-dialog>
