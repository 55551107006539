import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';

import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { CoSvgIconsEnum } from '@/creative-objects/models/co-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { Role } from '@/classes/enums';
import { RouteHelper } from '@/helpers/route.helper';
import { CoRouteHelper } from '@/creative-objects/helpers/co-router-helper';
import { ChatApiService } from '@/services/chat.api.service';
import { AuthV2Service } from '@/services/auth/authV2.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent {

  svgIconsEnum = SvgIconsEnum;
  coSvgIconsEnum = CoSvgIconsEnum;
  profileMenuOpened = false;
  serviceMenuOpened = false;
  currentFlow = 'TC';
  @Input() color: 'dark' | 'light' = 'light';

  constructor(public profileService: ProfileService,
              private router: Router,
              private routeHelper: RouteHelper,
              private coRouteHelper: CoRouteHelper,
              private chatService: ChatApiService,
              public authV2Service: AuthV2Service,
  ) { }

  public menuSwitch(state: boolean, menu: string) {
    switch (menu) {
      case 'PROFILE': this.profileMenuOpened = state;
                      break;
      case 'SERVICE': this.serviceMenuOpened = state;
    }
  }

  public goToCo(): void {
    const roles = this.profileService.currentProfile.roles;

    if (roles.length === 1 && !roles?.includes(Role.CREATIVE_OBJECTS_USER)) {
      this.router.navigate(this.coRouteHelper.acceptPage);
      return;
    }

    this.router.navigate(this.routeHelper.creativeObjects);
  }

  public logout(): void {
    this.authV2Service.logout();
  }

  public createChatWithSupport(): void {
    this.chatService.createSupportChat().subscribe({
      next: (chat) => {
        this.router.navigate(this.routeHelper.contractsChatsUrl(chat.id));
      },
      error: () => {}
    });
  }
}
