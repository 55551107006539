import { Component, Input } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';
import moment, { Moment } from 'moment';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'tc-date-range-picker',
  templateUrl: './tc-date-range-picker.component.html',
  styleUrls: ['./tc-date-range-picker.component.scss']
})
export class TcDateRangePickerComponent extends TptControlDirective {

  @Input() label: string;

  @Input()
  public icon = null;

  @Input()
  public showLabel = true;

  @Input()
  public min: Moment;

  @Input()
  public max: Moment;

  @Input()
  public comparisonEnd: Moment;

  @Input()
  public comparisonStart: Moment;

  @Input() start: AbstractControl;

  @Input() end: AbstractControl;

  public focused = false;

  public dateRange: FormGroup;

  public dateChanged(input): void {
    input.focus();
    input.blur();
  }

  public errorStateCtrl(ctrl): boolean {
    return ctrl.invalid && (ctrl.dirty || ctrl.touched);
  }

  public ctrlError(ctrl): string {
    return this.validationErrorService.getValidationErrorMessage(ctrl);
  }

  public formErrorState(): boolean {
    const startDate = moment(this.start.value);
    const endDate = moment(this.end.value);
    return startDate && endDate && startDate > endDate;
  }
}
