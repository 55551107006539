<div class="component-wrapper mb-16">
  <div class="d-flex align-items-center gg-8 mb-8" *ngIf="project.parentCategoryId">
    <mat-icon [svgIcon]="svgIconsEnum.CategorySmall" class="text-gray-600 w-14"></mat-icon>
    <div class="fs-14 fw-500 text-black">{{getProjectCategory()}}</div>
  </div>

  <div class="fs-18 fw-700 text-black mb-32">{{project?.projectName}}</div>

  <div class="d-flex gg-12 flex-wrap">
    <div class="d-flex align-items-center" *ngIf="project?.expertLevels?.length">
      <mat-icon [svgIcon]="svgIconsEnum.Expert" class="text-gray-600 mr-4"></mat-icon>
      <div class="fs-14 fw-500 text-black">{{(project?.expertLevels || []) | tptExpertLevels}}</div>
    </div>
    <div class="d-flex align-items-center" *ngIf="project.country">
      <mat-icon [svgIcon]="svgIconsEnum.Location" class="text-gray-600 w-14 mr-8"></mat-icon>
      <div class="fs-14 fw-500 text-black">{{getTranslation(project?.country)}}</div>
    </div>
    <div *ngIf="project.languages?.length" class="d-flex align-items-center">
      <mat-icon [svgIcon]="svgIconsEnum.Language" class="mr-8 text-gray-600"></mat-icon>
      <div class="fs-14 text-black fw-500">
        {{getLangByCode(project.languages[0])}}
        - {{'CREATE_PROJECT.STEP3.' + project.languages[0].level | translate}}
      </div>
      <span *ngIf="project.languages.length > 1"
            (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="langMenu"
            class="d-flex align-items-center more-links">, еще {{project.languages.length - 1}}
        <mat-icon [svgIcon]="svgIconsEnum.Expand"></mat-icon>
      </span>
      <mat-menu #langMenu="matMenu">
        <div class="py-12 px-16">
          <span *ngFor="let lang of project.languages.slice(1)" class="fs-14 text-black fw-500">
            {{getLangByCode(lang)}} - {{'CREATE_PROJECT.STEP3.' + lang.level | translate}}
          </span>
        </div>
      </mat-menu>
    </div>
  </div>

  <div class="d-flex flex-wrap gg-12 mt-8 mb-32" *ngIf="project.skills?.length || project.customSkills?.length">
    <tpt-chip class="chip" type="gray" size="small"
              *ngFor="let skill of specSkills(project)">
      {{getTranslation(skill) || skill}}
    </tpt-chip>
  </div>

  <div class="tasks">
    <div *ngFor="let task of project?.tasks; let idx = index;" class="project-task">
      <div class="d-flex justify-between align-items-center mb-8">
        <div class="fs-12 text-gray-600 fw-600">{{'CONTRACTS.TASK' | translate}} №{{ idx + 1 }}</div>
        <div class="fs-12 text-gray-600 fw-600" *ngIf="task.deadline">
          Срок выполнения до {{task.deadline | localDate: 'dd.MM.yyyy'}}
        </div>
      </div>

      <div class="fs-14 fw-600 text-black mb-8">{{ task?.taskTitle }}</div>
      <div class="fs-14 fw-500 text-gray-600">{{ task?.description }}</div>

      <div class="mt-16 files-row" *ngIf="task?.files?.length">
        <tpt-uploaded-file
          *ngFor="let file of task?.files"
          [file]="file"
        ></tpt-uploaded-file>
      </div>
    </div>
  </div>

  <div class="pb-16">
    <div class="fs-12 text-gray-600 fw-600">Стоимость</div>
    <div class="fs-24 fw-700 text-black lh-32">
      {{ project?.budget | number}} {{currencySymbol.RUB}}
    </div>
  </div>
</div>
