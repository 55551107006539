import { Component, OnInit } from '@angular/core';
import { StateHelperService } from '@/services/state-helper.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'app-mobile-menu-button',
  templateUrl: './mobile-menu-button.component.html',
  styleUrls: ['./mobile-menu-button.component.scss']
})
export class MobileMenuButtonComponent implements OnInit {

  public svgIconsEnum = SvgIconsEnum;

  constructor(private stateHelperService: StateHelperService, ) { }

  ngOnInit(): void {
  }

  toggleSidebar() {
    this.stateHelperService.sidebarOpened.next();
  }

}
