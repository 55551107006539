<header class="header" [ngClass]="{'dark': isDark}">
  <div class="header__wrapper">
    <mat-toolbar class="header__toolbar">
      <mat-icon [svgIcon]="svgIconsEnum.BurgerMenu" class="burger-menu" (click)="toggleMobileSidebar.emit()"></mat-icon>
      <div class="header__portal"><ng-template [cdkPortalOutlet]="headerPortal"></ng-template></div>
      <app-header-menu *ngIf="profileService.currentProfile" class="tpt-header"></app-header-menu>
    </mat-toolbar>
  </div>
</header>

