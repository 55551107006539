import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-unbind-dialog',
  templateUrl: './unbind-dialog.component.html',
  styleUrls: ['./unbind-dialog.component.scss']
})
export class UnbindDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  unbindEvent: EventEmitter<any> = new EventEmitter<any>();

  public svgIconsEnum = SvgIconsEnum;

  private readonly className = 'tpt-unbind-dialog';

  private readonly config: MatDialogConfig = {
    width: '518px',
    minHeight: '316px',
    maxWidth: 'calc(100vw - 32px)',
  };

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public unbind(): void {
    this.unbindEvent.emit();
    this.close();
  }
}
