import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { ResourcesService } from '@/services/resources.service';
import moment from 'moment';
import { NoDataDialogComponent } from '../no-data-dialog/no-data-dialog.component';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'tpt-download-documents-dialog',
  templateUrl: './download-documents-dialog.component.html',
  styleUrls: ['./download-documents-dialog.component.scss']
})
export class DownloadDocumentsDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(NoDataDialogComponent)
  public noDataDialog: NoDataDialogComponent;

  availableDocuments = [
    {id: 'registrationCertificate', label: 'Справка о постановке на учет'},
    {id: 'incomeStatement', label: 'Справка о доходах'},
  ];

  docForm: UntypedFormGroup = new UntypedFormGroup({
    document: new UntypedFormControl(null, [Validators.required]),
    year: new UntypedFormControl(null, [Validators.required])
  });

  years = [];

  public svgIconsEnum = SvgIconsEnum;

  private readonly className = 'tpt-download-documents-dialog';

  private readonly config: MatDialogConfig = {
    width: '460px',
    maxWidth: 'calc(100vw - 32px)'
  };

  constructor(private profilesApiService: ProfilesApiService,
              private spinner: SpinnerService,
              private resourcesService: ResourcesService) {
  }

  public open(): void {
    const startYear = 2019;
    const currentYear = moment().year();
    this.years = [];

    for (let i = startYear; i <= currentYear; i++) {
      this.years.push({ value: i, label: i });
    }

    this.docForm = new UntypedFormGroup({
      document: new UntypedFormControl(null, [Validators.required]),
      year: new UntypedFormControl(null, [Validators.required])
    });

    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public download(): void {
    if (this.docForm.invalid) {
      return;
    }

    const doc = this.docForm.value;
    const year = doc.year;
    const leap = moment([year]).isLeapYear();

    if (doc.document === 'registrationCertificate') {
      this.spinner.startSpinner();
      this.profilesApiService.getRegistrationCertificate(year, leap).subscribe({
        next: this.handleSuccess,
        error: (err) => this.handleError(err, year)
      });
    }

    if (doc.document === 'incomeStatement') {
      this.spinner.startSpinner();
      this.profilesApiService.getIncomeStatement(year, leap).subscribe({
        next: this.handleSuccess,
        error: (err) => this.handleError(err, year)
      })
    }
  }

  private handleSuccess = (file) => {
    this.spinner.stopSpinner();
    this.getFile(file.id);
  }

  private handleError = (err, year) => {
    const message: string = err?.errorCode?.message;
    let type;

    switch (true) {
      case message.includes('TAXPAYER_UNREGISTERED'):
        type = 'TAXPAYER_UNREGISTERED';
        break;
      case message.includes('TAXPAYER_UNBOUND'):
        type = 'TAXPAYER_UNBOUND';
        break;
      // case message.includes('REQUEST_VALIDATION_ERROR'):
      //   type = 'REQUEST_VALIDATION_ERROR';
      //   break;
      default:
        type = 'SOMETHING_WENT_WRONG';
    }

    this.spinner.stopSpinner();
    this.noDataDialog.open(year, type, err?.message);
  }

  private getFile(fileId): void {
    if (!fileId) {
      return;
    }

    this.resourcesService.getFileByUUID(fileId).subscribe(this.handleFile);
  }

  private handleFile = (file: File): void => {
    const objectURL = this.resourcesService.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }
}
