<div class="mobile-sidebar-root" *ngIf="show && profileService.currentProfile">
  <div class="logo-and-menu">
    <div class="menu-header">
      <button mat-icon-button class="menu-button" (click)="toggleSidebar()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1016 8.87891L13.2305 11.75L16.1016 14.6484C16.457 14.9766 16.457 15.5508 16.1016 15.8789C15.7734 16.2344 15.1992 16.2344 14.8711 15.8789L12 13.0078L9.10156 15.8789C8.77344 16.2344 8.19922 16.2344 7.87109 15.8789C7.51562 15.5508 7.51562 14.9766 7.87109 14.6484L10.7422 11.75L7.87109 8.87891C7.51562 8.55078 7.51562 7.97656 7.87109 7.64844C8.19922 7.29297 8.77344 7.29297 9.10156 7.64844L12 10.5195L14.8711 7.64844C15.1992 7.29297 15.7734 7.29297 16.1016 7.64844C16.457 7.97656 16.457 8.55078 16.1016 8.87891Z" fill="#0F1115"/>
        </svg>
      </button>

      <div class="header-actions">
        <app-header-menu color="dark"></app-header-menu>
      </div>
    </div>

    <mat-icon
      class="logo"
      [svgIcon]="svgIconsEnum.FooterLogo"
      [routerLink]="'/landing'"
    ></mat-icon>

    <div class="divider mx-32"></div>
    <div class="menu">
      <div class="top-menu">
        <a [routerLink]="routeHelper.finances" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarFinances"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.FINANCE' | translate}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.executorsPage" *ngIf="isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarTeam"
            ></mat-icon>
            <span class="item-text">
                <span class="tab-name wrap">Мои исполнители</span>
              </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.massPayments" *ngIf="isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon [svgIcon]="svgIconsEnum.SidebarPayments"></mat-icon>
            <span class="item-text">
            <span class="tab-name wrap">Выплаты</span>
            <div *ngIf="unreadPaymentsCount" class="unread-messages">{{unreadPaymentsCount > 99 ? '99+' : unreadPaymentsCount}}</div>
          </span>
          </div>
        </a>

        <a [routerLink]="routeHelper.clientsPage" *ngIf="!isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon [svgIcon]="svgIconsEnum.SidebarTeam"></mat-icon>
            <span class="item-text">
              <span class="tab-name">Мои заказчики</span>
            </span>
          </div>
        </a>

        <a [routerLink]="routeHelper.payments" *ngIf="!isEmployer" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon [svgIcon]="svgIconsEnum.SidebarPayments"></mat-icon>
            <span class="item-text">
              <span class="tab-name">Выплаты</span>
            </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.contracts" (click)="show = false">
          <div
            class="menu-item message"
            routerLinkActive="active-link"
          >
            <div class="d-flex align-items-center">
              <mat-icon [svgIcon]="svgIconsEnum.SidebarMessages"
              ></mat-icon>
              <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.MESSAGES' | translate}}</span>
              <div *ngIf="unreadCount" class="unread-messages">{{unreadCount > 99 ? '99+' : unreadCount}}</div>
            </span>
            </div>
          </div>
        </a>
      </div>

      <div class="bottom-menu">
        <div class="divider mb-24 mx-16"></div>

        <a [routerLink]="isEmployer ? routeHelper.freelancerSearch : routeHelper.projectSearch"
           (click)="show = false">
          <div
            class="menu-item"
            [class.active-link]="searchIsActive"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSearch"
              routerLinkActive="active-link"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">{{isEmployer ? 'Поиск исполнителей' : 'Поиск проектов'}}</span>
          </span>
          </div>
        </a>
        <a [routerLink]="projectsRoute" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarProjects"
            ></mat-icon>
            <span class="item-text">
            <span class="tab-name">Мои проекты</span>
          </span>
          </div>
        </a>
        <a [routerLink]="routeHelper.settings" (click)="show = false">
          <div
            class="menu-item"
            routerLinkActive="active-link"
          >
            <mat-icon
              [svgIcon]="svgIconsEnum.SidebarSettings"
            ></mat-icon>
            <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.SETTINGS' | translate}}</span>
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
