import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'tc-email-not-found-dialog',
  templateUrl: './email-not-found-dialog.component.html',
  styleUrls: ['./email-not-found-dialog.component.scss']
})
export class EmailNotFoundDialogComponent {

  public svgIconsEnum = SvgIconsEnum;

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() useAnotherEmailEvent = new EventEmitter();

  private config: MatDialogConfig = {
    width: '600px',
  };

  private className = 'tc-email-not-found-dialog';

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public goToSupport(): void {
    window.open('mailto:info@tp.team', '_blank');
  }
}
