
export class Constants {
    public static PASSWORD_MIN_LENGTH = 12;
    public static EXPIRES_KEY = 'expires_at';
    public static REFRESHTOKEN_KEY = 'auth-refreshtoken';
    public static TOKEN_KEY = 'auth-token';
    public static USER_KEY = 'auth-user';
    public static TC = 'TALENT_CLOUD';
    public static PYT = 'PAY_YOUR_TEAM';
    public static CO = 'CREATIVE_OBJECTS';
    public static SE_INCOME_LIMIT = 2400000;
    public static PLATFORM_PROJECT_BUDGET_LIMIT = 599999;
}
