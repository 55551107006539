<tpt-dialog>
  <div class="fs-22 fw-700 text-black mb-20">
    {{'TRACKER.OPEN_DISPUTE' | translate}}
  </div>
  <div class="fs-14 fw-500 text-gray-700 mb-20"
       [innerHTML]="(isEmployer ? 'TRACKER.OPEN_DISPUTE_TEXT_EMP' : 'TRACKER.OPEN_DISPUTE_TEXT_FR')| translate">
  </div>
  <tc-textarea [control]="reason">
    <ng-container label>{{'TRACKER.DISPUTE_PLACEHOLDER' | translate}}<span class="red-asterisk">*</span></ng-container>
  </tc-textarea>

  <div class="actions-row">
    <tc-button type="tertiary" size="large" class="cancel" (onClick)="close()">
      {{'BUTTON.CANCEL' | translate}}
    </tc-button>
    <tc-button type="primary" size="large" (onClick)="openDispute()" [disabled]="!reason.value.trim().length">
      {{'TRACKER.OPEN_DISPUTE' | translate}}
    </tc-button>
  </div>
</tpt-dialog>
