<mat-chip-grid #chipList>
  <div class="input-wrapper" [class.has-value]="!!itemCtrl.value">
    <div class="label" (click)="$event.stopPropagation(); itemInput.click()">
      Навыки
    </div>
    <input
      matInput
      #itemInput
      [formControl]="itemCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="false"
      (matChipInputTokenEnd)="addItem($event)"
      maxlength="64"
    />
    <span class="enter-tip" *ngIf="showTip">
        Enter
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.5 3.5V5.5H2.915L4.705 3.705L4 3L1 6L4 9L4.705 8.295L2.915 6.5H10.5V3.5H9.5Z" fill="#434C58"/>
        </svg>
      </span>
  </div>
</mat-chip-grid>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="autocomplete-panel">
  <mat-option *ngFor="let item of filteredItems" [value]="item" class="option">
    {{ item[viewField] || item | translate }}
  </mat-option>
</mat-autocomplete>


<div class="mt-12 chips">
  <tpt-chip type="gray"
            *ngFor="let item of selectedItems, let i = index">
    <span>{{ (item[viewField] || item) | translate }}</span>
    <mat-icon [svgIcon]="svgIconsEnum.Close" (click)="removeItem(i, item)"></mat-icon>
  </tpt-chip>
</div>

