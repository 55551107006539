import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { SnackbarComponent } from './snackbar/snackbar.component';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';

@Component({
  selector: 'tpt-snackbar-notifications',
  templateUrl: './snackbar-notifications.component.html',
  styleUrls: ['./snackbar-notifications.component.scss'],
  standalone: true,
})
export class SnackbarNotificationsComponent implements OnInit {
  constructor(
    private notificationService: SnackbarNotificationsService,
    private _snackBar: MatSnackBar
  ) {}

  openSnackBar(type, message) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: this.notificationService.notificationTimeout,
      horizontalPosition: 'right',
      panelClass: 'default-snackbar',
      data: {
        message,
        type,
      },
    });
  }

  ngOnInit(): void {
    this.notificationService
      .getNotifications()
      .subscribe(({ type, message }) => {
        this.openSnackBar(type, message);
      });
  }
}
