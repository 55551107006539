
export enum ProjectStateEnum {

  Draft = 'DRAFT',

  Init = 'INIT',

  Accepted = 'ACCEPTED',

  Started = 'STARTED',

  Ended = 'ENDED',

  Withdrawn = 'WITHDRAWN',

  EndedWithDispute = 'ENDED_WITH_DISPUTE',

  Active = 'ACTIVE'
}
