import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RouteHelper } from '@/helpers/route.helper';
import * as moment from 'moment';

import { ContractsService } from '../../../contracts/services/contracts.service';
import { ProfileService } from '../../../../services/profile.service';
import { ContractSignDialogComponent } from '../../../common-dialogs/components/contract-sign-dialog/contract-sign-dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { ResourcesService } from '../../../../services/resources.service';
import { contractsStates, greenMessages, payrollStates, redMessages } from '../../../contracts/models/message.model';
import { ProjectApiService } from '../../../../services/project.api.service';
import { SnackbarNotificationsService } from '../../../../services/snackbar-notifications.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { ContractEventModel } from '../../../contracts/models/contract-event.model';
import { environment } from '../../../../../environments/environment';
import { ProfileModel } from '@/models/profile.model';
import { BaseService } from '@/services/base.service';

@Component({
  selector: 'chat-companion-message',
  templateUrl: './chat-companion-message.component.html',
  styleUrls: [ './chat-companion-message.component.scss', './../common-messages-styles.scss' ],
})
export class ChatCompanionMessageComponent {

  @Input()
  public message: ContractEventModel;

  @Input()
  public interlocutorUser?: ProfileModel;

  @Input()
  public chatType: 'JOB_CHAT' | 'PROJECT_CHAT' | 'SUPPORT' | 'CREATIVE_OBJECTS_PRODUCT';

  @ViewChild(ContractSignDialogComponent) documentSignDialogComponent: ContractSignDialogComponent;

  @Output()
  public updateChatEvents = new EventEmitter<void>();

  @Output()
  public closeProjectCard = new EventEmitter<void>();

  public svgIconsEnum = SvgIconsEnum;

  public greenMessages = greenMessages;

  public redMessages = redMessages;

  constructor(public contractsService: ContractsService,
              public profileService: ProfileService,
              private projectApiService: ProjectApiService,
              private spinner: SpinnerService,
              private resource: ResourcesService,
              private snack: SnackbarNotificationsService,
              private router: Router,
              private routeHelper: RouteHelper,
              private baseService: BaseService,
              ) {}

  public get sendDate(): string {
    return moment(this.message.date).format('DD.MM.YYYY HH:mm');
  }

  public get isTextMessage(): boolean {
    return this.message.type === 'm.chat.message' && (this.message.content.body || this.message.content.files.length);
  }

  public get isStateMessage(): boolean {
    const states = ['state.owner_paid_job', 'state.owner_paid_job_milestone', ...contractsStates, ...payrollStates,
      'state.freelancer_was_invited', 'state.freelancer_was_accepted', 'state.freelancer_made_proposal'];
    return states.includes(this.message.type);
  }

  public get isGroupChatEventMessage(): boolean {
    const groupChatEvents = ['m.room.avatar', 'm.room.name', 'm.room.create', 'm.room.member'];
    return groupChatEvents.includes(this.message.type);
  }

  public get isProductChatEventMessage(): boolean {
    const productChatEvents = ['state.product.purchase', 'state.product.act', 'state.product', 'state.product.deal_completed'];
    return productChatEvents.includes(this.message.type);
  }

  public get isEmployer(): boolean {
    if (!this.profileService.currentProfile) { return; }
    return this.profileService.currentProfile.isEmployer();
  }

  public downloadFile(fileId): void {
    this.resource.getFileByUUID(fileId).subscribe(this.handleFile);
  }

  public getReceiptLink(content): void {
    const baseUrl = this.baseService.baseUrl;
    window.open(`${baseUrl}v1/receipt/${content.inn}/${content.receiptId}/print`, '_blank');
  }

  public handleFile = (file: File): void => {
    const objectURL = this.resource.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }

  public signDocument(docType: 'contract' | 'wcc'): void {
    const projectId = this.contractsService.selectedChat.projectId;

    switch (docType) {
      case 'contract':
        this.spinner.startSpinner();
        this.projectApiService.signContract(projectId).subscribe({next: () => {
            this.spinner.stopSpinner();
            this.updateChatEvents.emit();
            this.closeProjectCard.emit();
          },
          error: () => {
            this.spinner.stopSpinner();
            this.snack.showSmthWentWrong();
          }});
        return;
      case 'wcc':
        this.spinner.startSpinner();
        this.projectApiService.signWCC(projectId).subscribe({next: () => {
            this.spinner.stopSpinner();
            this.updateChatEvents.emit();
            this.closeProjectCard.emit();
          },
          error: (err) => {
            this.spinner.stopSpinner();
            if (['FNS.TAXPAYER_UNBOUND', 'FNS.TAXPAYER_UNREGISTERED'].includes(err?.errorCode?.message)) {
              this.snack.showNotification(err.message, 'fail');
              return;
            }
            this.snack.showSmthWentWrong();
          }});
    }
  }

  public topUpBalance(amount) {
    this.router.navigate(this.routeHelper.financesCommon, {queryParams: {amount}});
  }
}
