import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'tpt-auth-error-dialog',
  templateUrl: './auth-error-dialog.component.html',
  styleUrls: ['./auth-error-dialog.component.scss']
})
export class AuthErrorDialogComponent {

  @ViewChild(DialogComponent, { static: false })
  public dialog: DialogComponent;

  public title: string;
  public message: string;
  public email: string;
  public showSendEmailBtn = false;
  public svgIconsEnum = SvgIconsEnum;
  public accountBlocked = false;

  private config: MatDialogConfig = {
    width: '600px',
  };

  private className = 'tpt-auth-error-dialog';

  constructor(private snack: SnackbarNotificationsService,
              private spinner: SpinnerService,
              private profileApiService: ProfilesApiService) { }

  public open(title: string, message: string, email: string): void {
    this.title = title;
    this.message = message;
    this.email = email;
    this.showSendEmailBtn = message === 'SERVER_ERRORS.ACCOUNT_DISABLED';
    this.accountBlocked = message === 'SERVER_ERRORS.ACCOUNT_LOCKED';
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public sendEmail(): void {
    this.spinner.startSpinner();
    this.profileApiService.resendRegistrationEmail(this.email).subscribe(
      () => {
        this.spinner.stopSpinner();
        this.snack.showNotification('LOGIN_COMPONENT.CONFIRM_SEND_ALERT', 'success');
        this.close();
      }, () => {
        this.spinner.stopSpinner();
        this.snack.showSmthWentWrong();
        this.close();
      }
    );
  }

  public close(): void {
    this.dialog.close();
  }

}
