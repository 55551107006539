import { CategoryV2Model } from '../../../models/category-v2.model';
import { TaskAssigneeModel } from './task-assignee.model';
import { ProjectStatusEnum } from '../types/project-status.enum';
import { PriorityTypeEnum } from '../types/priority-type.enum';
import { ProfileModel } from '../../../models/profile.model';
import { ArbitrationModel } from '../../../models/arbitration.model';
import { TaskStatesEnum } from '../../../types/task-states.enum';
import { CurrencyModel } from '../../../models/financev2/currency.model';

export class ProjectDetailedTaskModel {

  public id: string;

  public taskTitle: string;

  public description: string;

  public deadline: string;

  public startDate: string;

  public category: CategoryV2Model;

  public assignee: TaskAssigneeModel;

  public files: Array<any> = [];

  public status: ProjectStatusEnum = ProjectStatusEnum.ToDo;

  public projectId: string;

  public job: any;

  public jobId: string;

  public jobDetails: {
    budget: number,
    budgetWithCommission: number,
    commission: number,
    deadline: string,
    milestones: any[],
    paymentMethod: string,
    reviewPeriod: number,
    state: string,
    currency: CurrencyModel,
    paymentState: string;
  };

  public freelancer: ProfileModel;

  public employer: ProfileModel;

  public state: string | TaskStatesEnum;

  public owner: ProfileModel;

  public priority: PriorityTypeEnum;

  public paymentPeriod: string;

  public canComment: boolean;

  public reviewCount: number;

  public arbitrage: ArbitrationModel;

  public archived: boolean;

  public chatId: string;

  public paymentState: string;
}
