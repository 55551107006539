import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';

@Component({
  selector: 'tpt-access-recovery-dialog',
  templateUrl: './access-recovery-dialog.component.html',
  styleUrls: ['./access-recovery-dialog.component.scss']
})
export class AccessRecoveryDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public email = '';

  @Output() public openSupportChat = new EventEmitter();

  private config: MatDialogConfig = {
    width: '520px',
  };

  private className = 'chat-info-dialog';

  public open(email = ''): void {
    this.email = '***' + email.slice(3);
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public goToSupport(): void {
    this.close();
    this.openSupportChat.emit();
  }
}
