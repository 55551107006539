<tpt-dialog>
  <div [ngSwitch]="noDataType">
    <div *ngSwitchCase="'REQUEST_VALIDATION_ERROR'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">Нет данных за {{year || 'выбранный'}} год.</div>

      <div class="text-gray-600 fw-500 fs-14 mb-28 ">
        Пожалуйста, выберите другой год или повторите позже
      </div>

      <div class="actions-row mt-48 gg-12">
        <tc-button type="tertiary" size="large" class="full-width" (onClick)="closeModals()">
          Закрыть
        </tc-button>
        <tc-button type="primary" size="large" class="full-width" (onClick)="close()">
          Выбрать другой год
        </tc-button>
      </div>
    </div>

    <div *ngSwitchCase="'TAXPAYER_UNREGISTERED'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">
        Потеря статуса самозанятого
      </div>

      <div class="text-gray-600 fw-500 fs-14 mb-28">
        Кажется, вы перестали быть самозанятым.<br/>
        Проверьте статус в приложении “Мой налог” или на сайте <a href="https://lknpd.nalog.ru/" target="_blank"
                                                                  class="detected-link">https://lknpd.nalog.ru/</a>
        или повторите попытку позже
      </div>

      <div class="actions-row mt-48 gg-12">
        <tc-button type="tertiary" size="large" class="full-width" (onClick)="closeModals()">
          Закрыть
        </tc-button>
        <tc-button type="primary" size="large" class="full-width" (onClick)="openLink()">
          Проверить статус
        </tc-button>
      </div>
    </div>

    <div *ngSwitchCase="'TAXPAYER_UNBOUND'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">
        Отзыв прав
      </div>

      <div class="text-gray-600 fw-500 fs-14 mb-28">
        Кажется, вы отозвали права у Talent Cloud.<br/>
        Проверьте привязку Talent Cloud в приложении “Мой налог” или на сайте <a href="https://lknpd.nalog.ru/" target="_blank"
                                                                        class="detected-link">https://lknpd.nalog.ru/</a>
        в разделе “Партнеры” или повторите попытку позже
      </div>

      <div class="actions-row mt-48 gg-12">
        <tc-button type="tertiary" size="large" class="full-width" (onClick)="closeModals()">
          Закрыть
        </tc-button>
        <tc-button type="primary" size="large" class="full-width" (onClick)="openLink()">
          Проверить привязку
        </tc-button>
      </div>
    </div>

    <div *ngSwitchCase="'SOMETHING_WENT_WRONG'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">
        Что-то пошло не так
      </div>

      <div class="text-gray-600 fs-14 fw-500 mb-28">
        {{message || 'Пожалуйста, повторите попытку позже'}}
      </div>
    </div>
  </div>
</tpt-dialog>
