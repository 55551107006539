import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ValidationErrorService } from '@/modules/tpt-forms/services/validation-error.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

@Component({
  selector: 'tc-dropdown',
  templateUrl: './tc-dropdown.component.html',
  styleUrls: ['./tc-dropdown.component.scss']
})
export class TcDropdownComponent extends TptControlDirective implements OnInit {

  @ViewChild('dropdown')dropdown: CdkOverlayOrigin;
  @ViewChild('panel')panel: ElementRef;

  @Output()
  searchOptions: EventEmitter<any> = new EventEmitter<any>();

  @Input() options: Array<{ id?: number, key?: any, code?: any, value?: string, nameRu?: string,
    name?: string, zoneId?: string, label?: string, disabled?: boolean }> = [];

  @Input() label: string;

  @Input()
  public icon = null;

  @Input()
  public showLabel = true;

  @Input()
  public searchMode = false;

  @Input()
  public setFullObjectAsValue = false;

  @Input()
  public nameField = 'label';

  @Input()
  public valueField = 'value';

  @Input()
  public disabled = false;

  public panelOpened = false;

  public svgIconsEnum = SvgIconsEnum;

  public position: 'below' | 'above';

  public inputForm: FormControl<string>;

  public overlayWidth = 180;

  constructor(validationErrorService: ValidationErrorService, private renderer: Renderer2) {
    super(validationErrorService);
  }

  ngOnInit() {
    this.inputForm = new FormControl('');

    this.inputForm.valueChanges.pipe(debounceTime(200)).subscribe((val) => {
      if (!val.trim) {
        return;
      }
      this.searchOptions.emit(val);
    });
  }

  setOption(option: any) {
    if (option?.disabled) {
      return;
    }
    if (!!this.setFullObjectAsValue) {
      this.control.setValue(option);
    } else {
      this.control.setValue(option[this.valueField]);
    }
    this.panelOpened = false;
  }

  closePanel() {
    if (this.panelOpened) {
      this.panelOpened = false;
    }
  }

  public getLabel = (value, options = []) => {
    if (this.setFullObjectAsValue) {
      return options.find(item => item?.[this.valueField] === value?.[this.valueField])?.[this.nameField];
    }

    return options.find(item => item?.[this.valueField] === value)?.[this.nameField];
  }

  openPanel() {
    if (this.disabled) {
      return;
    }
    this.panelOpened = true;
    this.calculateWidth();
  }

  calculateWidth(): void {
    this.overlayWidth = this.dropdown?.elementRef?.nativeElement?.getBoundingClientRect()?.width || 180;
  }
}
