import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from '@/services/base.service';

export interface Config {
  MAIN_HOST: string;
  authConfig: {
    issuer: string,
    allowedUrls: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigLoaderService {

  public config: Config = null;

  constructor(private http: HttpClient,
              private baseService: BaseService) {}

  public async loadConfig(): Promise<Config> {
    if (!this.config) {
      return await lastValueFrom(this.http.get('conf.json'))
        .then((config: Config) => {
          this.config = config;
          if (config.MAIN_HOST) {
            this.baseService.updateBaseUrl(config.MAIN_HOST);
          }
          return this.config;
        })
        .catch((error: Error) => {
          this.config = {
            MAIN_HOST: environment.MAIN_HOST,
            authConfig: environment.authConfig,
          };
          return this.config;
        });
    } else {
      return new Promise((resolve) => resolve(this.config));
    }
  }
}
