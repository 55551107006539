import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function dateIsBeforeValidator(fromDate: string, dateToCompare: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const compareDate = moment(dateToCompare);
    const ctrlDate = moment(control?.value);

    return ctrlDate.isSameOrBefore(compareDate) ? null : {
      contractDatesRangeError: true,
      fromDate: moment(fromDate).format('DD.MM.YYYY'),
      toDate: moment(dateToCompare).format('DD.MM.YYYY'),
    };
  };
}
