import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { FormContext } from '../../tpt-forms/base/form.context';
import { AddressModel } from '../../../models/userLegalStatus.model';

export class AddressForm extends FormContext<AddressModel> {

  static createForm(address?: AddressModel): AddressForm {
    const form = new FormGroup({
      legalAddress: new FormGroup({
        flatAddress: new FormControl(address?.legalAddress?.flatAddress || '', [Validators.required]),
      }),
      actualAddress: new FormGroup({
        flatAddress: new FormControl({ value: address?.actualAddress?.flatAddress || '', disabled: address?.actualAddressEqualLegal === true }, [
          Validators.required]),
      }),
      actualAddressEqualLegal: new FormControl(address?.actualAddressEqualLegal)
    });

    return new AddressForm(form);
  }

  public get legalAddress(): AbstractControl {
    return (this.form.get('legalAddress') as FormGroup).get('flatAddress');
  }

  public get actualAddress(): AbstractControl {
    return (this.form.get('actualAddress') as FormGroup).get('flatAddress');
  }

  public get actualAddressEqualLegal(): AbstractControl {
    return this.getControl('actualAddressEqualLegal');
  }

  constructor(
    public form: FormGroup,
  ) {
    super(form);
  }

  public getFormData(): AddressModel {
    return this.form.value;
  }

}
