<tpt-dialog>
  <div class="dialog-wrapper">
    <div class="dialog-title">
      Выберите тип личного кабинета
    </div>

    <div class="dialog-text" *ngIf="role === roleEnum.EMPLOYER">
      Ваш юридический статус и&nbsp;реквизиты нужны для&nbsp;формирования документов для&nbsp;работы с&nbsp;исполнителями. Вы&nbsp;сможете изменить юридический статус позже.
    </div>

    <div class="dialog-text" *ngIf="role === roleEnum.FREELANCER">
      Ваш юридический статус и реквизиты нужны для формирования документов для работы с заказчиками.<br/>
      Если потребуется, вы сможете изменить свой юридический статус.
    </div>

    <div class="cards-wrap">
      <div *ngFor="let card of cards"
           class="card"
           [class.active]="card.id === entityType"
           matRipple
           matRippleColor="rgba(0, 0, 0, 0.03)"
           (click)="selectType(card.id)"
      >
        <mat-icon [svgIcon]="card.icon"></mat-icon>

        <div class="">
          <div class="card-name">{{('SETTINGS.' + card.name) | translate}}</div>
          <div class="card-text">{{card.description}}</div>
        </div>
      </div>
    </div>

    <div class="actions-row mt-32">
      <tc-button type="tertiary" size="x-large" class="full-width" (onClick)="close()">{{'BUTTON.CANCEL' | translate}}</tc-button>
      <tc-button type="primary" size="x-large" class="full-width" (onClick)="changeType()">
        <svg width="15" class="mr-8" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.1875 1.3125C14.5938 1.6875 14.5938 2.34375 14.1875 2.71875L6.1875 10.7188C5.8125 11.125 5.15625 11.125 4.78125 10.7188L0.78125 6.71875C0.375 6.34375 0.375 5.6875 0.78125 5.3125C1.15625 4.90625 1.8125 4.90625 2.1875 5.3125L5.46875 8.59375L12.7812 1.3125C13.1562 0.90625 13.8125 0.90625 14.1875 1.3125Z" fill="white"/>
        </svg>
        Изменить тип кабинета
      </tc-button>
    </div>

  </div>
</tpt-dialog>
