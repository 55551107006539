<div *ngFor="let task of project?.tasks; let idx = index;" class="task-card">

  <div class="d-flex justify-between align-items-center mb-8">
    <div class="caption"> {{'CONTRACTS.TASK' | translate}} №{{ idx + 1 }}</div>

    <div class="caption">
      Дедлайн до {{ task?.deadline | localDate : 'dd.MM.yy' }}
    </div>
  </div>

  <div class="title">{{task?.taskTitle}}</div>
  <div class="task-description">{{ task?.description }}</div>

  <div class="docs-wrap mt-16" *ngIf="showFiles && task?.files?.length">
    <div class="doc" *ngFor="let doc of task.files" (click)="downloadFile(doc)">
      <mat-icon [svgIcon]="svgIconsEnum.Doc"></mat-icon>
      {{doc?.name}}
    </div>
  </div>
</div>
