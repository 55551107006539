import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { TptUiModule } from '../tpt-ui/tpt-ui.module';
import { TptFormsModule } from '../tpt-forms/tpt-forms.module';
import { WithdrawFundsDialogComponent } from './components/withdraw-funds-dialog/withdraw-funds-dialog.component';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { AvatarModule } from 'ngx-avatars';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDestructionDialogComponent } from './components/confirm-destruction-dialog/confirm-destruction-dialog.component';
import { RatingMetricsDialogComponent } from './components/rating-metrics-dialog/rating-metrics-dialog.component';
import { ReviewCardComponent } from './components/review-card/review-card.component';
import { ContactUsMobileComponent } from './contact-us-mobile/contact-us-mobile.component';
import { ChipsAutocompleteModule } from '../chips-autocomplete/chips-autocomplete.module';
import { LegalStatusDialogComponent } from './components/legal-status-dialog/legal-status-dialog.component';
import { OpenDisputeDialogComponent } from './components/open-dispute-dialog/open-dispute-dialog.component';
import { AuthErrorDialogComponent } from './components/auth-error-dialog/auth-error-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { GetAcquaintedDialogComponent } from './components/get-acquainted-dialog/get-acquainted-dialog.component';
import { ReceiptDialogComponent } from './components/receipt-dialog/receipt-dialog.component';
import { CancelReceiptDialogComponent } from './components/cancel-receipt-dialog/cancel-receipt-dialog.component';
import { ContractSignDialogComponent } from './components/contract-sign-dialog/contract-sign-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { JobApplyErrorDialogComponent } from './components/job-apply-error-dialog/job-apply-error-dialog.component';
import { JobInviteErrorDialogComponent } from './components/job-invite-error-dialog/job-invite-error-dialog.component';
import { ChatsDialogComponent } from './components/chats-dialog/chats-dialog.component';
import { SignupErrorDialogComponent } from './components/signup-error-dialog/signup-error-dialog.component';
import { SelectContractDialogComponent } from './components/select-contract-dialog/select-contract-dialog.component';
import {
  DownloadDocumentsDialogComponent
} from '@/modules/common-dialogs/components/download-documents-dialog/download-documents-dialog.component';
import { UnbindDialogComponent } from '@/modules/common-dialogs/components/unbind-dialog/unbind-dialog.component';
import {
  AccessRecoveryDialogComponent
} from '@/modules/common-dialogs/components/access-recovery-dialog/access-recovery-dialog.component';
import { NoDataDialogComponent } from '@/modules/common-dialogs/components/no-data-dialog/no-data-dialog.component';
import {
  BindInstructionDialogComponent
} from '@/modules/common-dialogs/components/bind-instruction-dialog/bind-instruction-dialog.component';
import {
  BindingErrorDialogComponent
} from '@/modules/common-dialogs/components/binding-error-dialog/binding-error-dialog.component';
import { PhotoCropDialogComponent } from './components/photo-crop-dialog/photo-crop-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FinishJobDialogComponent } from './components/finish-job-dialog/finish-job-dialog.component';
import { MatSliderModule } from '@angular/material/slider';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { BalanceTopUpDialogComponent } from './components/balance-top-up-dialog/balance-top-up-dialog.component';
import {
  NewPaymentDialogComponent
} from '@/modules/common-dialogs/components/new-payment-dialog/new-payment-dialog.component';
import {
  EmailNotFoundDialogComponent
} from '@/modules/common-dialogs/components/email-not-found-dialog/email-not-found-dialog.component';
import { CancelBindingDialogComponent } from './components/cancel-binding-dialog/cancel-binding-dialog.component';
import { TcCheckboxComponent } from '@/modules/tpt-forms/tc-components/tc-checkbox/tc-checkbox.component';

@NgModule({
  imports: [
    CommonModule,
    TptUiModule,
    TptFormsModule,
    RouterModule,
    TranslateModule,
    CommonPipesModule,
    AvatarModule,
    MatDividerModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    ChipsAutocompleteModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatRippleModule,
    MatCardModule,
    PdfViewerModule,
    ImageCropperModule,
    MatSliderModule,
    TcCheckboxComponent,
  ],
  declarations: [
    WithdrawFundsDialogComponent,
    ConfirmDestructionDialogComponent,
    RatingMetricsDialogComponent,
    ReviewCardComponent,
    ContactUsMobileComponent,
    LegalStatusDialogComponent,
    OpenDisputeDialogComponent,
    AuthErrorDialogComponent,
    GetAcquaintedDialogComponent,
    ReceiptDialogComponent,
    CancelReceiptDialogComponent,
    ContractSignDialogComponent,
    JobApplyErrorDialogComponent,
    JobInviteErrorDialogComponent,
    ChatsDialogComponent,
    SignupErrorDialogComponent,
    SelectContractDialogComponent,
    PhotoCropDialogComponent,
    FinishJobDialogComponent,
    DownloadDocumentsDialogComponent,
    UnbindDialogComponent,
    AccessRecoveryDialogComponent,
    NoDataDialogComponent,
    BindInstructionDialogComponent,
    BindingErrorDialogComponent,
    WarningDialogComponent,
    BalanceTopUpDialogComponent,
    NewPaymentDialogComponent,
    EmailNotFoundDialogComponent,
    CancelBindingDialogComponent,
  ],
  exports: [
    WithdrawFundsDialogComponent,
    ConfirmDestructionDialogComponent,
    RatingMetricsDialogComponent,
    ContactUsMobileComponent,
    ReviewCardComponent,
    LegalStatusDialogComponent,
    OpenDisputeDialogComponent,
    AuthErrorDialogComponent,
    GetAcquaintedDialogComponent,
    ReceiptDialogComponent,
    ContractSignDialogComponent,
    CancelReceiptDialogComponent,
    JobApplyErrorDialogComponent,
    JobInviteErrorDialogComponent,
    ChatsDialogComponent,
    SignupErrorDialogComponent,
    EmailNotFoundDialogComponent,
    PhotoCropDialogComponent,
    SelectContractDialogComponent,
    PhotoCropDialogComponent,
    DownloadDocumentsDialogComponent,
    UnbindDialogComponent,
    AccessRecoveryDialogComponent,
    NoDataDialogComponent,
    BindInstructionDialogComponent,
    BindingErrorDialogComponent,
    FinishJobDialogComponent,
    WarningDialogComponent,
    BalanceTopUpDialogComponent,
    NewPaymentDialogComponent,
    CancelBindingDialogComponent,
  ],
})
export class CommonDialogsModule {

}
