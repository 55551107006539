<ng-container>
  <tpt-spinner></tpt-spinner>
  <tpt-loading-bar></tpt-loading-bar>
  <ng-container *ngIf="loaded">
    <div class="xln body">
      <tpt-sidebar *ngIf="!hideSidebar && profileService?.currentProfile" class="desktop"></tpt-sidebar>
      <app-mobile-sidebar *ngIf="!hideSidebar && profileService?.currentProfile" class="mobile"></app-mobile-sidebar>
      <div class="app-content">
        <ng-container *ngIf="!hideHeader && profileService.currentProfile">
          <tpt-header
            [ngClass]="{'loaded': loaded}"
            class="app-header"
            *ngIf="!showNewHeader"
            [class.loaded]="loaded"
            (toggleMobileSidebar)="toggleMobileSidebar()"
          >
          </tpt-header>
          <app-header-menu *ngIf="showNewHeader"></app-header-menu>
        </ng-container>

        <tpt-header-light *ngIf="!profileService?.currentProfile"
                          class="app-header"
                          [ngClass]="{'hide-on-main-page': hideHeaderOnMainPage}">
        </tpt-header-light>

        <div class="body-content" [ngClass]="{'body-content_main': hideHeader || showNewHeader}" cdkScrollable>
          <router-outlet></router-outlet>
          <tpt-snackbar-notifications></tpt-snackbar-notifications>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
