<div
  [class.error-state]="errorState"
  class="input-field"
  [class.has-icon]="!!icon"
  [class.has-value]="!!control.value"
  (clickOutside)="closePanel()"
  (click)="openPanel()"
  cdkOverlayOrigin
  #dropdown="cdkOverlayOrigin"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon" class="input-icon"></mat-icon>

  <div class="input select-trigger"
       [class.focus]="panelOpened">
    {{getLabel(control.value, options) || ''}}
  </div>
  <mat-icon [svgIcon]="svgIconsEnum.Expand" class="expand-icon"></mat-icon>
  <div *ngIf="showLabel" class="label">
    <ng-content select="[label]"></ng-content>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dropdown"
  [cdkConnectedOverlayOpen]="panelOpened"
  [cdkConnectedOverlayWidth]="overlayWidth"
>
  <div #panel class="project-dropdown" *ngIf="panelOpened" [ngClass]="position">
    <ng-container *ngIf="searchMode">
      <div class="search-project" (click)="$event.stopPropagation()">
        <mat-icon [svgIcon]="svgIconsEnum.Search"></mat-icon>
        <input (keyup)="$event.preventDefault()"
               [formControl]="inputForm" (keydown)="$event.stopPropagation()">
      </div>
    </ng-container>

    <div *ngFor="let option of options"
         (click)="setOption(option)"
         [class.selected]="setFullObjectAsValue ? option === control.value : option[valueField] === control.value"
         class="option">
      <div class="fs-14 text-black fw-500">{{option?.[nameField]}}</div>
      <div *ngIf="disabledJob(option)">
        <tc-button type="tertiary" (onClick)="goToChat.emit(option)">Перейти в чат</tc-button>
      </div>
    </div>
  </div>
</ng-template>

<mat-hint [align]="hintAlign" *ngIf="!errorState">
  <ng-content select="[hint]"></ng-content>
</mat-hint>

<mat-error [class.invisible]="!errorState">
  {{ error | translate}}
</mat-error>
