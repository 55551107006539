<div class="tooltip" [class.show]="showTooltip">
  <div class="strength-info">
    <div class="arrow"></div>
    <div class="title">Пароль должен содержать:</div>
    <div class="info-row" *ngIf="passwordComponent.enableLengthRule">
      <div *ngIf="passwordComponent.containAtLeastMinChars; then done else error">
      </div>
      <span [class.done]="passwordComponent.containAtLeastMinChars">Как минимум 12 символов</span>
    </div>
    <div class="info-row" *ngIf="passwordComponent.enableDigitRule">
      <div *ngIf="passwordComponent.containAtLeastOneDigit; then done else error">
      </div>
      <span [class.done]="passwordComponent.containAtLeastOneDigit">Как минимум одну цифру</span>
    </div>
    <div class="info-row" *ngIf="passwordComponent.enableLowerCaseLetterRule">
      <div *ngIf="passwordComponent.containAtLeastOneLowerCaseLetter; then done else error">
      </div>
      <span [class.done]="passwordComponent.containAtLeastOneLowerCaseLetter">Как минимум одну букву в нижнем регистре</span>
    </div>
    <div class="info-row" *ngIf="passwordComponent.enableUpperCaseLetterRule">
      <div *ngIf="passwordComponent.containAtLeastOneUpperCaseLetter; then done else error">
      </div>
      <span [class.done]="passwordComponent.containAtLeastOneUpperCaseLetter">Как минимум одну букву в верхнем регистре</span>
    </div>
    <div class="info-row" *ngIf="passwordComponent.enableSpecialCharRule">
      <div *ngIf="passwordComponent.containAtLeastOneSpecialChar; then done else error">
      </div>
      <span [class.done]="passwordComponent.containAtLeastOneSpecialChar">Как минимум один специальный символ</span>
    </div>
    <ng-template #done>
      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 12.5C4.84375 12.5 2.875 11.375 1.79688 9.5C0.71875 7.64844 0.71875 5.375 1.79688 3.5C2.875 1.64844 4.84375 0.5 7 0.5C9.13281 0.5 11.1016 1.64844 12.1797 3.5C13.2578 5.375 13.2578 7.64844 12.1797 9.5C11.1016 11.375 9.13281 12.5 7 12.5ZM9.64844 5.39844H9.625C9.85938 5.1875 9.85938 4.83594 9.625 4.60156C9.41406 4.39062 9.0625 4.39062 8.85156 4.60156L6.25 7.22656L5.14844 6.125C4.91406 5.89062 4.5625 5.89062 4.35156 6.125C4.11719 6.33594 4.11719 6.6875 4.35156 6.89844L5.85156 8.39844C6.0625 8.63281 6.41406 8.63281 6.64844 8.39844L9.64844 5.39844Z"
          fill="#458D70"/>
      </svg>
    </ng-template>
    <ng-template #error>
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.875 6.5C10.875 4.76562 9.9375 3.17188 8.4375 2.28125C6.91406 1.41406 5.0625 1.41406 3.5625 2.28125C2.03906 3.17188 1.125 4.76562 1.125 6.5C1.125 8.25781 2.03906 9.85156 3.5625 10.7422C5.0625 11.6094 6.91406 11.6094 8.4375 10.7422C9.9375 9.85156 10.875 8.25781 10.875 6.5ZM0 6.5C0 4.36719 1.125 2.39844 3 1.32031C4.85156 0.242188 7.125 0.242188 9 1.32031C10.8516 2.39844 12 4.36719 12 6.5C12 8.65625 10.8516 10.625 9 11.7031C7.125 12.7812 4.85156 12.7812 3 11.7031C1.125 10.625 0 8.65625 0 6.5Z"
          fill="#707D99"/>
      </svg>
    </ng-template>
  </div>
</div>
