import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'tpt-dialog-header',
  template: `
    <div mat-dialog-title>
      <ng-content></ng-content>
    </div>
  `
})
export class DialogHeaderComponent {

  @HostBinding('class.tpt-dialog-header')
  public baseClass = true;

}
