export enum CoSvgIconsEnum {

  Image = 'image',

  Books = 'books',

  Quotes = 'quotes',

  Headphones = 'headphones',

  Photo = 'photo',

  Planet = 'planet',

  Puzzle = 'puzzle',

  Crop = 'crop',

  Text = 'text',

  Comics = 'comics',

  Code = 'code',

  Play = 'play',

  Seller = 'seller',

  Buyer = 'buyer',

  SmallLogo = 'co-small-logo',

  FullLogo = 'co-full-logo',

  Folder = 'folder',

  Study = 'study',

  PO = 'po',

  Design = 'brush',

  Book = 'book',

  Audio = 'audio',

  Music = 'music',

  Buy = 'buy',

  CoSwitcher = 'switcher-co-logo',

  TcSwitcher = 'switcher-tc-logo',

  Cart = 'cart',

  TopUp = 'top-up',

  PriceTag = 'price-tag',

  Withdraw = 'withdraw',

  Exit = 'exit',

  SidebarHome = 'co-home',

  SidebarProducts = 'box',

  SidebarMessages = 'co-messages',

  SidebarWallet = 'wallet',

  SidebarSettings = 'co-settings',

  // common TC
  ConfirmSuccess = 'confirm-success',

  Grid = 'grid',

  InfoIcon = 'info-icon',

  SmallArrowDown = 'small-arrow-down',

  Search = 'search',

  CheckSuccess = 'check-success',

  InfoWarn = 'info-warn',

  MoreHoriz = 'more-horiz',

  WarnIcon = 'warn-icon',

  CopyLink = 'copy-link',

  Download = 'download',

  ExpandMore = 'expand-more',

  UploadSuccess = 'upload-success',

  Trash = 'trash',

  SidebarFns = 'sidebar-fns',

  CoInfo = 'co-info',

  Info = 'info',

  Staple = 'staple',

  Cross = 'cross',

  File = 'file',

}
