import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../classes/enums';
import { ProfileService } from '../services/profile.service';
import { RouteHelper } from '../helpers/route.helper';
import { AuthV2Service } from '@/services/auth/authV2.service';

const USER_KEY = 'auth-user';

@Injectable()
export class MainPageGuard  {

  constructor(private profileService: ProfileService,
              private auth2Service: AuthV2Service,
              private router: Router,
              private routeHelper: RouteHelper) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hasValidToken = this.auth2Service.hasValidToken();
    const rolesFromClaimsObject = this.auth2Service.identityClaims?.groups || [];
    const claimsObject = this.auth2Service.identityClaims;
    const currentUser = this.profileService?.currentProfile;

    if (!this.auth2Service.hasValidToken()) {
      // not authorized so return true
      return true;
    }

    const page = route.data.page;
    if (page === 'product-page') {
      const routerStateSnapshotUrl: string = state.url;
      if (routerStateSnapshotUrl.includes('/creative-objects/catalog/')) {
        const idArray = routerStateSnapshotUrl.split('/');
        const id = idArray[idArray.length - 1];
        this.router.navigate([`/creative-objects/app/product/${id}`]);
        return false;
      }
    }

    if (hasValidToken && rolesFromClaimsObject.includes(Role.EMPLOYER)) {
      this.router.navigate([RouteHelper.PROJECTS_DASHBOARD_PATH]);
      return false;
    }

    if (hasValidToken && rolesFromClaimsObject.includes(Role.FREELANCER)) {
      const invitedUser = localStorage.getItem('invitedUser');
      if (route.queryParams?.inviteCode || invitedUser === claimsObject.email) {
        localStorage.removeItem('invitedUser');
        this.router.navigate(this.routeHelper.clientsPage, {queryParams: route.queryParams});
        return false;
      }

      if (currentUser && currentUser.selfEmployedStatus !== 'ACTIVE') {
        this.router.navigate(this.routeHelper.settingsLegalData);
        return false;
      }

      this.router.navigate([RouteHelper.SEARCH_PATH]);
      return false;
    }

    if (hasValidToken && rolesFromClaimsObject.includes(Role.CREATIVE_OBJECTS_USER) &&
      ![Role.FREELANCER, Role.EMPLOYER].some((item) => rolesFromClaimsObject.includes(item))) {
      this.router.navigate(['/accept']);
      return false;
    }

    this.router.navigate([RouteHelper.PROJECTS_DASHBOARD_PATH]);
    return false;
  }
}
