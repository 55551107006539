import { Injectable } from '@angular/core';
import { ProfilesApiService } from '../../../services/profiles.api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ProfileModel } from '../../../models/profile.model';
import { tap } from 'rxjs/operators';
import { UpdateEmailForm } from '../forms/update-email.form';
import { UpdatePasswordForm } from '../forms/update-password.form';
import { LegalStatus } from '../../../models/userLegalStatus.model';
import { cloneDeep } from 'lodash';
import { GeneralProfileInfoForm } from '../forms/general-profile-info.form';
import { ContactsProfileForm } from '../forms/contacts-profile.form';
import { SpecializationsForm } from '../forms/specializations.form';
import { ProfileService } from '../../../services/profile.service';
import { deserializationJacksonKey } from '../../../classes/jacksonUtils';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  public profileUpdatedSubject: Subject<void>;

  public legalStatus: LegalStatus;
  public legalStatus$: BehaviorSubject<LegalStatus> = new BehaviorSubject<LegalStatus>(null);

  constructor(
    private profilesApiService: ProfilesApiService,
    private profileService: ProfileService,
  ) {
    this.profileUpdatedSubject = new Subject<void>();
  }

  public getUserProfile = (): Observable<ProfileModel> => {
    return this.profilesApiService.getUserProfile().pipe(
      tap((profile: ProfileModel) => {
        this.profileService.currentProfile = deserializationJacksonKey(ProfileModel, profile);
        this.profileService.newProfileObject.next(profile);
        localStorage.setItem(USER_KEY, JSON.stringify(profile));
      }),
    );
  }

  public getUserLegal = (): Observable<LegalStatus> => {
    return this.profilesApiService.getUserLegal().pipe(
      tap((legalStatus: LegalStatus) => {
        this.legalStatus = legalStatus;
        this.legalStatus$.next(legalStatus);
      }),
    );
  }

  public updateGeneralInfo = (form: GeneralProfileInfoForm): Observable<void> => {
    const model = form.getRawValue();

    if (model.languages && model.languages.length) {
      model.languages = model.languages.filter(item => item.code && item.level);
    }
    if (model.countryCode === 'Irrelevant') {
      model.countryCode = '';
    }

    return this.profilesApiService.updateMainInfo(model);
  }

  public updateContactsInfo = (form: ContactsProfileForm): Observable<void> => {
    const model = form.getFormData();

    if (model.links && model.links.length) {
      model.links = model.links.filter(item => item.trim().length);
    }

    return this.profilesApiService.updateContacts(model);
  }

  public updateSpecializationsInfo = (form: SpecializationsForm): Observable<void> => {
    const model = cloneDeep(form.getFormData());

    if (model.specializations?.length) {
      model.specializations.forEach(spec  => {
        if (spec.skillIds && spec.skillIds.length) {
          const skillsObjects = spec.skillIds.filter(item => typeof item !== 'string');
          const customSkills = spec.skillIds.filter(item => typeof item === 'string');

          spec.skillIds = skillsObjects.map((skill: any) => skill.id);
          spec.customSkills = customSkills;
        } else {
          if (spec.skills && spec.skills.length) {
            spec.skillIds = model.skills.map(item => item.id);
          }
        }

        if (spec.parentCategory) {
          spec.parentCategoryId = spec.parentCategory.id;
        }

        if (spec.subCategory) {
          spec.subCategoryId = spec.subCategory.id;
        }

        const specFields = ['parentCategory', 'skills', 'subCategory'];

        specFields.forEach(item => {
          if (spec[item]) {
            delete spec[item];
          }
        });
      });

      if (!model.specializations.some(spec => spec.main)) {
        model.specializations[0].main = true;
      }
    }

    if (model.specializations.length === 1 && !model.specializations[0]?.parentCategoryId) {
      model.specializations = [];
    }

    return this.profilesApiService.updateSpecializations(model.specializations);
  }

  public updateEmail = (form: UpdateEmailForm): Observable<void> => {
    const model = form.getFormData();
    model.email = model.email.toLowerCase();

    return this.profilesApiService.updateEmail(model);
  }

  public updatePassword = (form: UpdatePasswordForm): Observable<void> => {
    const model = form.getFormData();
    return this.profilesApiService.updatePassword(model);
  }

  public resendUpdateEmail = (): Observable<void> => {
    return this.profilesApiService.resendUpdateEmail();
  }

}
