<tpt-dialog>
  <div class="dialog">
    <div class="dialog-title" *ngIf="title">{{ title | translate}}</div>
    <div *ngIf="description" class="dialog-description">{{ description | translate}}</div>
    <ng-content select="[description]"></ng-content>
    <div class="dialog-actions" *ngIf="action && buttonType">
      <tc-button
        type="tertiary"
        size="medium"
        class="button decline-button"
        (click)="declineAction()"
      >
        {{cancelText | translate}}
      </tc-button>
      <tc-button
        [type]="buttonType"
        size="medium"
        class="button approve-button"
        (click)="approveAction()"
      >
        <mat-icon [svgIcon]="icon" *ngIf="icon"></mat-icon>
        {{action | translate}}
      </tc-button>
    </div>
  </div>
</tpt-dialog>
