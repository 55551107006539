import { TextMessageModel } from '../../employer/models/task-comments/text-message.model';

export const contractsStates = [
  'state.job.freelancer_invited', // Исполнитель был приглашен на проект
  'state.job.freelancer_applied_for_a_project', // Исполнитель подал заявку на проект
  'state.job.freelancer_hired', // Приглашение принято
  'state.job.freelancer_joined', // Заявка одобрена
  'state.job.contract_preparing', // подготовка договора
  'state.job.contract_ready', // контракт готов
  'state.job.contract_signed_by_employer', // клиент подписал договор
  'state.job.contract_changed_by_freelancer', // фрил внес изменения
  'state.job.contract_changed_by_employer', // заказчик внес изменения
  'state.job.contract_signed_by_freelancer', // подписали оба
  'state.job.contract_signing_expires', // истекает срок подписания
  'state.job.contract_signing_by_freelancer_expires', // истекает срок подписания договора исполнителем
  'state.job.contract_signing_by_employer_expires', // истекает срок подписания договора заказчиком
  'choose_payment_method', // выберите способ оплаты
  'state.job.invoice_is_being_prepared', // счет готовится
  'state.job.make_advance_prepayment', // счет на оплату
  'state.job.await_prepayment', // дождитесь оплаты
  'state.job.waiting_for_bank_response', // проверка постуления
  'state.job.advance_payment_made', // оплата получена
  'state.job.finished', // клиент подписал акт
  'state.job.freelancer_signed_work_completion_certificate', // оба подписали акт
  'state.job.finished_automatically', // Вакансия завершена автоматически
  'state.job.freelancer_only_signed_work_completion_certificate', // Вакансия завершена автоматически и подписана исполнителем
  'state.job.check_ready', // чек готов
  'state.job.payment_deadline_expires_7_days', // Истекает срок внесения предоплаты 7
  'state.job.payment_deadline_expires_24_hours', // Истекает срок внесения предоплаты 24
  'state.job.reward_paid', // Вознаграждение выплачено
  'state.job.freelancer_wcc_one_day_left_to_sign', // На подписание акта остался 1 день
  'state.job.freelancer_did_not_sign_wcc', // Срок подписания акта истек
  'state.job.supplementary_agreement_preparing', // доп соглашение готовится
  'state.job.supplementary_agreement_ready', // доп соглашение готово
  'state.job.supplementary_agreement_signed_by_employer', // клиент подписал доп соглашение
  'state.job.supplementary_agreement_signed_by_freelancer', // фрил подписал  доп соглашение
  'state.job.document_changed_by_freelancer', // фрил внес измененеия в доп соглашение   // @TODO
  'state.job.document_changed_by_employer', // клиент внес измененеия в доп соглашение   // @TODO
  'state.receipt_error_taxpayer_unregistered', // самозанятый снят с учета
  'state.job.receipt_error_taxpayer_unbound', // самозанятый отвязался
  'state.job.payment_error_taxpayer_unbound', // самозанятый отвязался
  'state.job.refund_to_employer', // возврат средств
  'state.payment_error_taxpayer_unregistered', // самозанятый снят с учета

  'state.job.order_preparing', // подготовка заказа
  'state.job.order_ready', // заказ готов
  'state.job.order_signed_by_employer', // клиент подписал заказ
  'state.job.order_signed_by_freelancer', // исполнитель подписал заказ
  'state.job.order_signing_by_employer_expires', // истекает срок подписания заказа заказчиком
  'state.job.order_signing_by_freelancer_expires', // истекает срок подписания заказа исполнителем
  'state.receipt_canceled', // чек аннулирован

  'state.job.deleted', // проект удален
  'state.job.another_freelancer_hired', // нанят другой исполнитель
  'state.job.withdrawn', // проект снят с публикации

  'state.job.insufficient.funds', // недостаточно средств для списания по проекту
  'state.job.advance_payment_made', // успешно захолдировали деньги по проекту

  'state.job.mvd.new_job', // заказчик выбрал Исполнителя-нерезидента для выполнения проекта
  'state.job.mvd.end_date', // наступает дата завершения проекта c нерезидентом
  'state.job.mvd.finished_earlier', // заказчик завершает проект c нерезидентом до даты завершения проекта

  'state.job_payment_limit_exceeded', // достигнут лимит на НПД
  'state.job_chargeback_receipt_canceled', // аннулирование чека при идентификации возвращенного платежа
  'state.job_chargeback', // возврат платежа
];

export const payrollStates = [
  'state.payroll_canceled_after_sign', // отмена разовой выплаты в статусе ожидает подписи исполнителя/ внесения предопл
  'state.payroll_canceled', // отмена разовой выплаты в статусе создана
  'state.payroll.contract_changed_by_employer', // редактирование договора
  'state.payroll.order_changed_by_employer', // редактирование заказа
];

export const greenMessages = [
  'state.job.advance_payment_made',
  'state.job.contract_signed_by_freelancer',
  'state.job.order_signed_by_freelancer',
  'state.job.freelancer_signed_work_completion_certificate',
];

export const redMessages = [
  'state.receipt_canceled',
  'state.job_chargeback_receipt_canceled',
  'state.job_chargeback',
];

export class SendMessageRequestModel {

  constructor(
    public chatId: string,
    public type: string,
    public content: TextMessageModel,
  ) { }

}
