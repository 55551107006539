import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '../classes/enums';
import { Constants } from '@/classes/constants';
import { AuthV2Service } from '@/services/auth/authV2.service';

@Injectable()
export class RoleGuard  {

  constructor(private authService: AuthV2Service,
              private router: Router) { }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const hasValidToken = this.authService.hasValidToken();
    const rolesFromClaimsObject = this.authService.identityClaims?.groups || [];

    const expectedRoles = route.data.expectedRoles as Role[];
    const validRole = rolesFromClaimsObject?.some((role: Role) => expectedRoles.includes(role));
    const service = route.data.service;

    if (hasValidToken && validRole) {
      // authorized under the required role so return true
      return Promise.resolve(true);
    }

    if (service === Constants.TC && !rolesFromClaimsObject.includes(Role.EMPLOYER) &&
      !rolesFromClaimsObject.includes(Role.FREELANCER) && rolesFromClaimsObject.includes(Role.CREATIVE_OBJECTS_USER)) {
      this.router.navigate(['/accept']);
      return Promise.resolve(false);
    }

    if (service === Constants.CO && !rolesFromClaimsObject.includes(Role.CREATIVE_OBJECTS_USER)
      && rolesFromClaimsObject.includes(Role.FREELANCER)) {
      this.router.navigate(['/creative-objects/app/accept']);
      return Promise.resolve(false);
    }

    // unauthorized under the required role so redirect to home page with the return url
    this.authService.login();
    return Promise.resolve(false);
  }
}
