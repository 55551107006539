<div class="message"  *ngIf="isTextMessage">
  <tpt-avatar class="mr-8" size="24" [textSizeRatio]="2.5" [user]="message?.sender"></tpt-avatar>

  <div class="message-content">
    <div class="message-content-header">
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      </span>
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ sendDate }}
      </span>
    </div>
    <div class="message-body">
      <div [innerHTML]="message?.content?.body | tptDetectUrl | safeHtml"></div>
      <div
        *ngFor="let file of message.content?.files"
        class="message-body-files"
      >
        <tpt-uploaded-file [file]="file"></tpt-uploaded-file>
      </div>
    </div>
  </div>
</div>

<div class="d-flex mb-20" *ngIf="isChangeMessage">
  <tpt-avatar class="mr-8" size="24" [textSizeRatio]="2.5" [user]="message?.sender"></tpt-avatar>
  <div class="status-change">
    <div class="status-change-header">
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      </span>
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ sendDate }}
      </span>
    </div>
    <div class="status-change-body"  *ngIf="message?.sender?.id">
      {{'TRACKER.CHANGED_STATUS' | translate}}
      <span class="status">
        {{('TRACKER.' + message?.content?.status) | translate}}</span>
    </div>
    <div class="status-change-body" *ngIf="!message?.sender?.id">
      {{'TRACKER.STATUS_AUTO_CHANGED' | translate}}
    </div>
  </div>
</div>

<div class="d-flex" *ngIf="isDeclineMessage">
  <tpt-avatar class="mr-8" size="24" [textSizeRatio]="2.5" [user]="message?.sender"></tpt-avatar>
  <div class="message-content">
    <div class="status-change">
      <div class="status-change-header">
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      </span>
        <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ sendDate }}
      </span>
      </div>
      <div class="fs-14 lh-20 text-black fw-600">
        {{'TRACKER.DECLINED_TASK' | translate}}
      </div>
    </div>
    <div class="message" *ngIf="message.content.body">
      <div class="message-content">
        <div class="message-content-header mt-4">
          <span class="fs-12 lh-16 text-gray-600 fw-500">{{'TRACKER.REASON' | translate}}:</span>
        </div>
        <div class="message-body">
          {{ message?.content?.body }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex mb-20" *ngIf="isOpenDisputeMessage">
  <tpt-avatar class="mr-8" size="24" [textSizeRatio]="2.5" [user]="message?.sender"></tpt-avatar>
  <div class="message-content">
    <div class="status-change">
      <div class="status-change-header">
        <span class="fs-12 lh-16 text-gray-600 fw-500">
          {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
        </span>
        <span class="fs-12 lh-16 text-gray-600 fw-500">
          {{ sendDate }}
        </span>
      </div>
      <div class="fs-14 lh-20 text-black fw-500">
        {{'TRACKER.OPENED_DISPUTE' | translate}}
      </div>
    </div>
    <div class="mt-4" *ngIf="message.content.body as message">
      <div class="fs-12 lh-16 text-gray-600 fw-500 mb-4">{{'TRACKER.REASON' | translate}}:</div>
      <div class="message-body">{{ message }}</div>
    </div>
  </div>
</div>

<div class="d-flex mb-20" *ngIf="isAcceptMessage">
  <tpt-avatar class="mr-8" size="24" [textSizeRatio]="2.5" [user]="message?.sender"></tpt-avatar>
  <div class="status-change">
    <div class="status-change-header">
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ message?.sender?.firstName}} {{ message?.sender?.lastName }}
      </span>
      <span class="fs-12 lh-16 text-gray-600 fw-500">
        {{ sendDate }}
      </span>
    </div>
    <div class="status-change-body">
      <span class="text-black fw-600 fs-14 lh-20">{{'TRACKER.ACCEPTED_TASK' | translate}}</span>
    </div>
  </div>
</div>
