import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { SimpleProjectResponseModel } from '../../employer/models/simple-project-response.model';
import { ProjectBoardService } from '../../employer/services/project-board.service';
import { SpinnerService } from '../../tpt-ui/services/spinner.service';
import { ProfileService } from '../../../services/profile.service';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'tpt-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit, OnDestroy {

  public project: SimpleProjectResponseModel;

  public svgIconsEnum = SvgIconsEnum;

  private projectId: string;

  private readonly destroy$ = new Subject<void>();

  get isEmployer(): boolean {
    return this.profile.currentProfile.isEmployer();
  }

  constructor(
    private projectBoardService: ProjectBoardService,
    private activatedRoute: ActivatedRoute,
    private spinner: SpinnerService,
    public profile: ProfileService,
    public routeHelper: RouteHelper,
  ) { }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.parent.params.pipe(
      map((params: Params) => params.id || null),
      tap((id: string) => this.projectId = id),
      tap((id: string) => this.projectBoardService.currentProjectId = id),
      takeUntil(this.destroy$),
    ).subscribe(this.getProjectInfo);

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getProjectInfo = (id: string): void => {
    this.spinner.startSpinner();
    this.projectBoardService.getProjectInfo(id).subscribe((project) => {
      this.project = project;
      this.spinner.stopSpinner();
    }, this.spinner.stopSpinner);
  };

}
