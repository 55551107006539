import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-binding-error-dialog',
  templateUrl: './binding-error-dialog.component.html',
  styleUrls: ['./binding-error-dialog.component.scss']
})
export class BindingErrorDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public error: 'FNS.TAXPAYER_UNREGISTERED' | 'FNS.PARTNER_DENY' | 'FNS.TAXPAYER_ALREADY_BOUND';

  private readonly className = 'tpt-binding-error-dialog';

  private readonly config: MatDialogConfig = {
    width: '460px',
    minHeight: '316px'
  };

  public open(type): void {
    this.error = type;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }
}
