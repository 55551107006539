import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';

@Component({
  selector: 'tpt-no-data-dialog',
  templateUrl: './no-data-dialog.component.html',
  styleUrls: ['./no-data-dialog.component.scss']
})
export class NoDataDialogComponent  {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() closeModal = new EventEmitter();

  public svgIconsEnum = SvgIconsEnum;

  public noDataType: 'TAXPAYER_UNREGISTERED' | 'REQUEST_VALIDATION_ERROR' | 'TAXPAYER_UNBOUND' | 'SOMETHING_WENT_WRONG' = 'REQUEST_VALIDATION_ERROR';

  public year: number;

  public message: string;

  private readonly className = 'tpt-no-data-dialog';

  private readonly config: MatDialogConfig = {
    width: '460px',
    minHeight: '316px',
    maxWidth: 'calc(100vw - 32px)',
  };

  public open(year, type, message: string): void {
    this.year = year;
    this.noDataType = type;
    this.message = message;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public closeModals = () => {
    this.closeModal.emit();
    this.close();
  }

  public openLink(): void {
    window.open('https://lknpd.nalog.ru/', '_blank');
  }
}
