<tpt-dialog>
  <div class="fs-20 fw-700 text-black mb-16">
    {{'SEARCH.LETS_ACQUAINTED' | translate}}
  </div>

  <div class="text-gray-600 fw-500 fs-14 mb-28">
    {{'SEARCH.LETS_ACQUAINTED_TEXT' | translate}}
  </div>

  <div class="actions-row gg-12">
    <tc-button type="tertiary" size="large" class="cancel" (onClick)="close()">
      {{'BUTTON.CLOSE' | translate}}
    </tc-button>
    <tc-button type="primary" size="large" (onClick)="fillDetails()">
      {{'SEARCH.FILL_DETAILS' | translate}}
    </tc-button>
  </div>
</tpt-dialog>
