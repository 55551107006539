import { LogLevel } from '../app/classes/enums';

export const environment = {
  production: false,
  hmr: false,
  contactMail: '',
  currentFlow: 'staging',
  MAIN_HOST: 'stage-site.ru',
  TRACKING_CODE: 'UA-141210688-1',
  STOMP_PORT: 15678,
  WEB_SOCKET_URL: 'wss://api.stage-site.ru',
  G_VERIFICATION1: '',
  G_VERIFICATION2: '',
  FB_VERIFICATION: '',
  FB_PIXEL_ID: '',
  GTM_CODE: '',
  YMETRIKA: '',
  LOG_SETTINGS: {
    LOG_LEVEL: LogLevel.INFO,
    LOG_SERVER_LEVEL: LogLevel.WARN,
    GLOBAL: true,
  },
  timeStamp: '',
  google_client_id: '48421332865-mb5p0sjsdofn76lg4dtpp2jom6h9cjdc.apps.googleusercontent.com',
  google_app_name: 'stage',
  facebook_client_id: '',
  YA_WEBMASTER: '3b728c0c715ae43d',
  authConfig: {
    issuer: 'http://stage-site.ru/keycloak/realms/tc',
    allowedUrls: ['https://api.stage-site.ru', 'http://api.stage-site.ru', 'http://stage-site.ru'],
  }
};
