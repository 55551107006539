<tpt-dialog>
  <div class="dialog-wrapper">
    <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-0.000976562" y="0.5" width="47" height="47" rx="7.5" fill="#53BDF9" fill-opacity="0.1"/>
      <path d="M15.001 18.5L23.2295 24.5L31.0009 18.5M32.001 20.7V28.3C32.001 29.4201 32.0012 29.9802 31.7832 30.408C31.5915 30.7844 31.2851 31.0902 30.9088 31.282C30.4809 31.5 29.9213 31.5 28.8012 31.5H17.2012C16.0811 31.5 15.5206 31.5 15.0928 31.282C14.7164 31.0902 14.4107 30.7844 14.219 30.408C14.001 29.9802 14.001 29.4201 14.001 28.3V20.7C14.001 19.5798 14.001 19.0199 14.219 18.592C14.4107 18.2157 14.7164 17.9097 15.0928 17.718C15.5206 17.5 16.0811 17.5 17.2012 17.5H28.8012C29.9213 17.5 30.4809 17.5 30.9088 17.718C31.2851 17.9097 31.5915 18.2157 31.7832 18.592C32.0012 19.0199 32.001 19.5798 32.001 20.7Z" stroke="#52B8F2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    <div class="mt-16 mb-12 headline">
      На почту {{email}} было отправлено письмо
    </div>

    <div class="regular-14-20 text-gray-600 mb-48">
      Мы отправили письмо на почту, привязанную к данному профилю. Перейдите по ссылке из письма, чтобы восстановить доступ к аккаунту.
    </div>

    <tc-button type="tertiary" size="large" class="mb-24"
                (onClick)="goToSupport()">У меня нет доступа к этой почте</tc-button>
  </div>
</tpt-dialog>
