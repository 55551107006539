<tpt-dialog>
  <div class="dialog-root" [ngClass]="flow">
    <div class="heading mb-32">{{title}}</div>

    <div class="dialog-content mb-32">
      <input #input type="file" style="opacity: 0; width: 0; height: 0"
             accept="image/png, image/jpeg"
             (change)="fileChangeEvent($event)"/>
      <tc-button type="secondary" (onClick)="input.click()" size="large" class="mb-8" *ngIf="showUploadButton">Выбрать фото
        <mat-icon [svgIcon]="svgIconsEnum.Search"></mat-icon>
      </tc-button>

      <image-cropper [imageChangedEvent]="imageChangedEvent"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="aspectRatio"
                     [cropperMinWidth]="cropperMinWidth"
                     [cropperMinHeight]="cropperMinHeight"
                     format="png"
                     [autoCrop]="false"
                     (imageCropped)="imageCropped($event)"
                     (loadImageFailed)="loadImageFailed() ">
      </image-cropper>
    </div>

    <div class="actions-row">
      <tc-button type="tertiary" size="large" (onClick)="cancel()">Отмена</tc-button>
      <tc-button type="primary" size="large" (onClick)="cropImage()">Обрезать</tc-button>
    </div>
  </div>
</tpt-dialog>
