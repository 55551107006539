import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MaterialModule } from '../material/material.module';
import { DialogModule } from '../dialog/dialog.module';
import { UploadedFileComponent } from './components/uploaded-file/uploaded-file.component';
import { TooltipButtonComponent } from './components/tooltip-button/tooltip-button.component';
import { LanguageLevelsPipe } from './pipes/language-levels.pipe';
import { ExpertLevelsPipe } from './pipes/expert-levels.pipe';
import { AvatarPipe } from './pipes/avatar.pipe';
import { ChipComponent } from './components/chip/chip.component';
import { DragNDropComponent } from './components/drag-n-drop/drag-n-drop.component';
import { CommonShareModule } from '../common-share.module';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarModule } from 'ngx-avatars';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TptFormsModule } from '../tpt-forms/tpt-forms.module';
import { TabsComponent } from './components/tabs/tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DndDirective } from '@/modules/common-directives/directives/dnd.directive';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DialogModule,
    CommonShareModule,
    AvatarModule,
    MatAutocompleteModule,
    TptFormsModule,
    MatTabsModule,
    DndDirective,
  ],
  declarations: [
    SpinnerComponent,
    UploadedFileComponent,
    TooltipButtonComponent,
    LanguageLevelsPipe,
    ExpertLevelsPipe,
    AvatarPipe,
    ChipComponent,
    DragNDropComponent,
    LoadingBarComponent,
    AvatarComponent,
    TabsComponent,
  ],
  exports: [
    DialogModule,
    MaterialModule,
    SpinnerComponent,
    UploadedFileComponent,
    TooltipButtonComponent,
    LanguageLevelsPipe,
    ExpertLevelsPipe,
    AvatarPipe,
    ChipComponent,
    DragNDropComponent,
    LoadingBarComponent,
    AvatarComponent,
    TabsComponent,
  ]
})
export class TptUiModule { }
