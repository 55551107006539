import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public icon = SvgIconsEnum.WarnIcon;

  public access: number;

  public title = '';
  public text = '';
  public buttonText = 'Понятно';
  public showInstruction = false;

  private readonly className = 'app-warning-dialog';

  private readonly config: MatDialogConfig = {
    width: '600px',
    minHeight: '250px',
  };

  public open(title: string, text: string,
              buttonText: string = 'Понятно',
              icon: SvgIconsEnum = SvgIconsEnum.WarnIcon,
              showInstruction?: boolean): void {
    this.title = title;
    this.text = text;
    this.buttonText = buttonText;
    this.icon = icon;
    if (showInstruction) {
      this.showInstruction = showInstruction;
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public openInstruction(): void {
    window.open('https://npd.nalog.ru/#start', '_blank');
  }
}
