import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { deserializationJacksonKey } from '../classes/jacksonUtils';
import { ProfilesApiService } from './profiles.api.service';
import { ProfileModel } from '../models/profile.model';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root',
})

export class ProfileService {

  public startOnBoardingSubject = new Subject<void>();

  public switchToChats = new Subject<void>();

  public openInfo = new Subject<void>();

  public onBoardingInfo = {
    isIncoming: false,
    isOutgoing: false,
    chatId: null,
    launched: false
  };

  get currentProfile(): ProfileModel {
    return this._currentProfile;
  }

  set currentProfile(value: ProfileModel) {
    this._currentProfile = value;
    this.currentProfile$.next(value);
  }

  public newProfileObject = new BehaviorSubject<ProfileModel>(null);

  public currentProfile$: BehaviorSubject<ProfileModel> = new BehaviorSubject<ProfileModel>(this.currentProfile);

  public _currentProfile: ProfileModel;

  constructor(public profileApiService: ProfilesApiService,
              private translate: TranslateService) {
    const user: ProfileModel = JSON.parse(localStorage.getItem(USER_KEY));
    this.currentProfile = deserializationJacksonKey(ProfileModel, user);
  }

  async getUserInfo(): Promise<void> {
    try {
      const user = await lastValueFrom(this.profileApiService.getUserProfile());
      this.currentProfile = deserializationJacksonKey(ProfileModel, user);
      localStorage.setItem(USER_KEY, JSON.stringify(user));
      this.newProfileObject.next(user);
      this.translate.use('ru');
    } catch (err) {
      console.log(err);
    }
  }
}
