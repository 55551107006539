<div class="container"
     tptDnd (fileDropped)="onFileDropped($event)"
     [ngStyle]="{'display': invisible ? 'none' : 'flex'}"
     *ngIf="!(filesLimit === 1 && files.length === 1)"
     [class.error]="required && !files?.length"
     [class.small]="size === 'small'">
  <input type="file" #fileDropRef id="fileDropRef"
         [accept]="accept"
         multiple (change)="fileBrowseHandler($event.target?.files)" />
  <div class="right-block">
  <svg class="upload-icon" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7"/>
    <g clip-path="url(#clip0_1455_13069)">
      <path
        d="M26.3333 26.3334L23 23M23 23L19.6667 26.3334M23 23V30.5M29.9917 28.325C30.8044 27.8819 31.4465 27.1808 31.8166 26.3322C32.1866 25.4837 32.2635 24.5361 32.0352 23.6389C31.8068 22.7418 31.2862 21.9463 30.5556 21.3779C29.8249 20.8095 28.9257 20.5006 28 20.5H26.95C26.6978 19.5244 26.2276 18.6186 25.575 17.8509C24.9223 17.0831 24.104 16.4732 23.1817 16.0672C22.2595 15.6612 21.2571 15.4695 20.2501 15.5066C19.2431 15.5437 18.2576 15.8086 17.3676 16.2814C16.4777 16.7542 15.7066 17.4226 15.1122 18.2363C14.5178 19.0501 14.1156 19.988 13.9358 20.9795C13.7561 21.9711 13.8034 22.9905 14.0743 23.9611C14.3453 24.9317 14.8327 25.8282 15.5 26.5834"
        stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" stroke-width="6"/>
    <defs>
      <clipPath id="clip0_1455_13069">
        <rect width="20" height="20" fill="white" transform="translate(13 13)"/>
      </clipPath>
    </defs>
  </svg>
    <div class="upload-message mb-4" [innerHTML]="message"></div>
    <div class="bottom-message">
      {{tip | translate}}
    </div>
  </div>
</div>
<div class="files-list" [ngStyle]="{'display': invisible ? 'none' : 'flex'}">
  <div class="single-file" *ngFor="let file of files; let i = index" (click)="fileClick(file)">
    <div class="info">
      <div class="name">
        <mat-icon [svgIcon]="svgIconsEnum.UploadSuccess" class="staple-icon"></mat-icon>
        <div class="ellipsis">
          <span>{{ file?.name }}</span>
          <div class="size">{{ formatBytes(file?.size) }}</div>
        </div>
      </div>
      <div class="close" (click)="$event.stopPropagation()">
        <mat-icon [svgIcon]="svgIconsEnum.Trash" class="delete" (click)="deleteFile(i, file)"></mat-icon>
      </div>
    </div>
    <div class="" *ngIf="file.error">{{file.error}}</div>
    <div class="progress-cont" *ngIf="file?.progress < 100">
      <div class="progress" [style.width]="file?.progress + '%'">
      </div>
    </div>
  </div>
</div>
