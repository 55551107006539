import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RouteHelper } from '../../../../helpers/route.helper';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'tpt-chats-dialog',
  templateUrl: './chats-dialog.component.html',
  styleUrls: ['./chats-dialog.component.scss']
})
export class ChatsDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output() public openHireDialogEvent = new EventEmitter<any>();

  public chats: {projectName: string, jobName: string, currentUserJobChatId: number}[] = [];

  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    width: '730px',
    maxHeight: '100vh'
  };

  private className = 'tpt-chats-dialog';

  constructor(private router: Router,
              private routeHelper: RouteHelper) { }

  public open(chats): void {
    this.dialog.config = this.config;
    this.chats = chats;
    this.dialog.open(this.className);
  }

  public openHireDialog() {
    this.openHireDialogEvent.emit();
    this.close();
  }

  public close(): void {
    this.dialog.close();
  }

  public goToChat(chat): void {
    if (chat?.jobState === 'INIT') {
      this.router.navigate(this.routeHelper.contractsProposalsUrl(chat.currentUserJobChatId));
    } else {
      this.router.navigate(this.routeHelper.contractsChatsUrl(chat.currentUserJobChatId));
    }
    this.close();
  }

}
