import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, share } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BaseService } from './base.service';
import { CategoryV2Model } from '../models/category-v2.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService  {
  categories: CategoryV2Model[];
  categoriesSubject = new BehaviorSubject<CategoryV2Model[]>([]);
  get baseUrl() {
    return this.baseService.baseUrl;
  };

  constructor(private baseService: BaseService) {}

  public getCategoriesV2(): Observable<CategoryV2Model[]> {
    if (this.categories) {
      return of(this.categories);
    }
    return this.baseService.executeGet<CategoryV2Model[]>('v2/categories')
      .pipe(tap((res) => {
        this.categories = res;
        this.categoriesSubject.next(res);
      }), share());
  }
}
