<div class="progress">
  <mat-progress-bar
    mode="determinate"
    [value]="progress"
    [ngClass]="getClass()"
  ></mat-progress-bar>
  <div class="progress-percentage">
    <div>{{ getText() }}</div>
    <div>{{ progress }}%</div>
  </div>
</div>
