import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'tpt-job-card-progress',
  templateUrl: './job-card-progress.component.html',
  styleUrls: [ './job-card-progress.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobCardProgressComponent implements OnInit {

  @Input()
  public total: number;

  @Input()
  public completed: number;

  public get progress(): number {
    if (!this.total || !this.completed) {
      return 0;
    }

    return Math.floor(this.completed / this.total * 100);
  }

  getClass(): string {
    switch (true) {
      case this.progress <= 30: {
        return 'orange'
      }
      case this.progress > 30 && this.progress < 100: {
        return 'blue'
      }
      case this.progress === 100: {
        return 'green'
      }
    }
  }

  getText(): string {
    switch (true) {
      case this.progress === 0: {
        return 'Еще не начался'
      }
      case this.progress > 0 && this.progress < 100: {
        return 'В процессе'
      }
      case this.progress === 100: {
        return 'Завершен'
      }
    }
  }

  constructor(
  ) { }

  public ngOnInit(): void {
  }
}
