<tpt-dialog>
  <div class="dialog-root">
    <div class="text-black title fw-700">У вас есть договор с исполнителем</div>

    <mat-radio-group [(ngModel)]="contractType">
      <mat-radio-button value="FRAMEWORK" color="primary" class="mb-8">Работать по действующему договору</mat-radio-button><br/>
      <mat-radio-button value="REGULAR" color="primary">Сформировать новый договор</mat-radio-button>
    </mat-radio-group>

    <div class="actions-row">
      <tc-button type="tertiary" size="x-large" (onClick)="close()">Отменить</tc-button>
      <tc-button type="primary" size="x-large" (onClick)="submit()">Продолжить</tc-button>
    </div>
  </div>
</tpt-dialog>
