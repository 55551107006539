import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CoSvgIconsEnum } from '../models/co-icons.enum';

@Injectable({
  providedIn: 'root'
})
export class CoIconsService {

  static svgIcons = [
    // Creative objects icons
    CoSvgIconsEnum.Image,
    CoSvgIconsEnum.Books,
    CoSvgIconsEnum.Quotes,
    CoSvgIconsEnum.Headphones,
    CoSvgIconsEnum.Photo,
    CoSvgIconsEnum.Planet,
    CoSvgIconsEnum.Puzzle,
    CoSvgIconsEnum.Crop,
    CoSvgIconsEnum.Text,
    CoSvgIconsEnum.Play,
    CoSvgIconsEnum.Code,
    CoSvgIconsEnum.Comics,
    CoSvgIconsEnum.Seller,
    CoSvgIconsEnum.Buyer,
    CoSvgIconsEnum.FullLogo,
    CoSvgIconsEnum.SmallLogo,
    CoSvgIconsEnum.SidebarWallet,
    CoSvgIconsEnum.SidebarProducts,
    CoSvgIconsEnum.SidebarHome,
    CoSvgIconsEnum.SidebarMessages,
    CoSvgIconsEnum.SidebarSettings,
    CoSvgIconsEnum.Folder,
    CoSvgIconsEnum.Study,
    CoSvgIconsEnum.PO,
    CoSvgIconsEnum.Design,
    CoSvgIconsEnum.Book,
    CoSvgIconsEnum.Audio,
    CoSvgIconsEnum.Music,
    CoSvgIconsEnum.Buy,
    CoSvgIconsEnum.CoSwitcher,
    CoSvgIconsEnum.TcSwitcher,
    CoSvgIconsEnum.Cart,
    CoSvgIconsEnum.TopUp,
    CoSvgIconsEnum.PriceTag,
    CoSvgIconsEnum.Withdraw,
    CoSvgIconsEnum.Exit,
    /////
    CoSvgIconsEnum.ConfirmSuccess,
    CoSvgIconsEnum.Grid,
    CoSvgIconsEnum.InfoIcon,
    CoSvgIconsEnum.SmallArrowDown,
    CoSvgIconsEnum.Search,
    CoSvgIconsEnum.CheckSuccess,
    CoSvgIconsEnum.InfoWarn,
    CoSvgIconsEnum.MoreHoriz,
    CoSvgIconsEnum.WarnIcon,
    CoSvgIconsEnum.CopyLink,
    CoSvgIconsEnum.Download,
    CoSvgIconsEnum.ExpandMore,
    CoSvgIconsEnum.UploadSuccess,
    CoSvgIconsEnum.Trash,
    CoSvgIconsEnum.SidebarFns,
    CoSvgIconsEnum.CoInfo,
    CoSvgIconsEnum.Info,
    CoSvgIconsEnum.Cross,
    CoSvgIconsEnum.Staple,
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  public registerIcons(): void {
    CoIconsService.svgIcons.forEach(this.addSvgIcon);
  }

  private addSvgIcon = (image: string): void => {
    this.matIconRegistry.addSvgIcon(
      image,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${image}.svg`),
    );
  }

}
