<div
  class="tpt-uploaded-file-root"
  (click)="getFile(file)"
>
  <mat-icon
    class="file"
    [svgIcon]="svgIconsEnum.File"
  ></mat-icon>

  <span class="filename" [matTooltip]="file?.name" matTooltipShowDelay="500"
        [matTooltipDisabled]="file?.name?.length < 24"
        matTooltipPosition="right">
    {{ file?.name }}<span *ngIf="file?.extension">.{{ file?.extension }}</span></span>
</div>
