<tpt-dialog>
  <div class="dialog-root">
    <div class="feedback-step" *ngIf="docs && project">
      <div class="fs-20 text-black mb-8 fw-700">
        Завершение {{project.type === 'PAYROLL' ? 'выплаты' : 'проекта'}}
      </div>

      <div>
        <div class="text-gray-600 fs-14 fw-500 mb-24">
          Ознакомьтесь с документами и подпишите
        </div>

        <div class="docs" *ngIf="docs.workCompletionCertificate; else PendingAct">
          <div class="contract-doc">
            <mat-icon [svgIcon]="svgIconsEnum.File" class="mr-4"></mat-icon>
            <span (click)="openDoc(docs.workCompletionCertificate?.id)">{{docs.workCompletionCertificate?.name}}</span>
          </div>
          <div class="contract-doc mt-12" *ngIf="docs.statement">
            <mat-icon [svgIcon]="svgIconsEnum.File" class="mr-4"></mat-icon>
            <span (click)="openDoc(docs.statement?.id)">{{docs.statement?.name}}</span>
          </div>
        </div>

        <tc-checkbox [(ngModel)]="acceptTerms.confirmResults" [disabled]="!docs.workCompletionCertificate">
          <span class="mb-8 text-black fw-600 fs-14">Принимаю результаты работы и согласен завершить Услугу</span>
        </tc-checkbox>
        <br/>
        <tc-checkbox [(ngModel)]="acceptTerms.confirmPayment" [disabled]="!docs.workCompletionCertificate">
          <span class="mb-8 text-black fw-600 fs-14">Подтверждаю перевод {{ project?.budget | number}}₽ на счёт исполнителя</span>
        </tc-checkbox>
        <br/>
        <tc-checkbox *ngIf="project.commissionValue" [(ngModel)]="acceptTerms.confirmCommission"
                     [disabled]="!docs.workCompletionCertificate || !docs.statement"
        >
          <span class="mb-8 text-black fw-600 fs-14">Подтверждаю перевод {{ project?.commissionValue | number}}₽ на счёт Talent Cloud</span>
        </tc-checkbox>
        <br/>

        <ng-template #PendingAct>
          <div class="info-message act">
            Завершающие документы ещё не сформированы, а без их подписания завершить оказание услуги невозможно.
            Мы уже работаем над формированием документов
          </div>
        </ng-template>
      </div>

      <div class="finish-actions">
        <tc-button type="tertiary" size="x-large" (onClick)="close()">
          Отмена
        </tc-button>
        <tc-button
          type="primary"
          size="x-large"
          [disabled]="checkBoxesNotValid()"
          (onClick)="finishNoFeedback()"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3125 1.625C13.0938 0.84375 14.375 0.84375 15.1562 1.625L16.375 2.84375C17.1562 3.625 17.1562 4.90625 16.375 5.6875L14.875 7.1875L10.8125 3.125L12.3125 1.625ZM10.0938 3.84375L14.1562 7.90625L6.875 15.1875C6.5625 15.5 6.15625 15.75 5.71875 15.875L1.9375 17C1.6875 17.0625 1.40625 17 1.21875 16.7812C1 16.5938 0.9375 16.3125 1.03125 16.0625L2.125 12.2812C2.25 11.8438 2.5 11.4375 2.8125 11.125L10.0938 3.84375Z" fill="white"/>
          </svg>
          Подписать документы
        </tc-button>
      </div>
    </div>
  </div>
</tpt-dialog>
