import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ToolTipRendererDirective } from '@/modules/common-directives/directives/tooltip.directive';
import { CustomTooltipComponent } from '@/modules/common-directives/custom-tooltip/custom-tooltip.component';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    ToolTipRendererDirective,
    CustomTooltipComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClickOutsideDirective,
    ToolTipRendererDirective,
    CustomTooltipComponent,
  ]
})
export class CommonDirectivesModule {
}
