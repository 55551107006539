import { Injectable } from '@angular/core';
import { Observable, of, share } from 'rxjs';
import { map } from 'rxjs/operators';
import { Language } from '../models/language';
import { BaseService } from './base.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages: Language[];

  constructor(private baseService: BaseService) {}

  public getLanguagesV2(): Observable<Language[]> {
    if (this.languages) {
      return of(cloneDeep(this.languages));
    }
    return this.baseService.executeGet<Language[]>('v2/languages')
      .pipe(map((res) => {
        this.languages = res;
        return cloneDeep(res);
      }), share());
  }

}
