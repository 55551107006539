<nav mat-tab-nav-bar color="primary" [tabPanel]="tabPanel">
  <div *ngFor="let tab of tabs">
    <a mat-tab-link
       *ngIf="tab.show"
       [routerLink]="tab.path"
       routerLinkActive="header-menu-item-active"
       #rla="routerLinkActive"
       [routerLinkActiveOptions]="{ exact: true }"
       [active]="rla.isActive"
       class="header-menu-item"
    >{{tab.label}} </a>
  </div>
</nav>
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>

