import { Component, Input, OnInit } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'app-self-employed-status',
  templateUrl: './self-employed-status.component.html',
  styleUrls: ['./self-employed-status.component.scss']
})
export class SelfEmployedStatusComponent implements OnInit {

  @Input() status: 'NEW' | 'ACTIVE' | 'REMOVED_FROM_REGISTER' | 'DETACHED' | 'NOT_VERIFIED';
  @Input() bankDetailsValidated: boolean;
  @Input() legalInfoValidated: boolean;
  @Input() limitExceeded: boolean;
  @Input() size: 'small' | 'medium' = 'medium';

  svgIconsEnum = SvgIconsEnum;
  constructor() { }

  ngOnInit(): void {
  }

}
