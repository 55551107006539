import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '../../../../types/svg-icons.enum';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { SpinnerService } from '@/modules/tpt-ui/services/spinner.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { FinanceServiceV2 } from '@/services/finance.service';

@Component({
  selector: 'tpt-cancel-receipt-dialog',
  templateUrl: './cancel-receipt-dialog.component.html',
  styleUrls: ['./cancel-receipt-dialog.component.scss']
})
export class CancelReceiptDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  public cancelReceiptEvent: EventEmitter<void> = new EventEmitter();

  public receiptId: string;

  public reasons: {code: string, description: string}[] = [];

  public svgIconsEnum = SvgIconsEnum;

  public reason = new UntypedFormControl('REFUND');

  public receiptCanceled = false;

  private config: MatDialogConfig = {
    width: '520px',
  };

  private className = 'tpt-cancel-receipt-dialog';

  constructor(private spinner: SpinnerService,
              private snack: SnackbarNotificationsService,
              private financeService: FinanceServiceV2) {
  }

  public open(reasons: any[], receiptId: string): void {
    this.reasons = reasons;
    this.receiptId = receiptId;
    this.receiptCanceled = false;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public cancelReceipt(reason: string): void {
    if (!reason) { return; }

    this.spinner.startSpinner();
    this.financeService.cancelReceipt(this.receiptId, reason).subscribe({
      next: () => {
        this.spinner.stopSpinner();
        this.receiptCanceled = true;
        this.cancelReceiptEvent.emit();
      }, error: () => {
        this.snack.showSmthWentWrong();
        this.spinner.stopSpinner();
      }
    });
  }
}
