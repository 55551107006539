<div>
  <div class="label d-flex">{{'CONTRACTS.PHONE' | translate}}<span class="red-asterisk">*</span></div>
  <ngx-mat-intl-tel-input
    class="phone-input"
    [class.error]="errorState"
    [preferredCountries]="preferredCountries"
    [enablePlaceholder]="true"
    [enableSearch]="true"
    name="phone"
    searchPlaceholder="Поиск"
    [formControl]="control"></ngx-mat-intl-tel-input>
  <mat-error *ngIf="errorState">
    {{ error | translate }}
  </mat-error>
</div>
