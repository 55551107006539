<tpt-dialog>
  <div class="heading mb-20">Сформировать справку</div>

  <tc-dropdown [control]="docForm.controls.document"
               [valueField]="'id'"
               [icon]="svgIconsEnum.File"
               [options]="availableDocuments">
    <ng-container label>Тип справки</ng-container>
  </tc-dropdown>

  <tc-dropdown [control]="docForm.controls.year"
               class="year-select"
               [icon]="svgIconsEnum.DatepickerCalendar"
               [options]="years">
    <ng-container label>Год</ng-container>
  </tc-dropdown>

  <tc-button type="primary" size="large" class="full-width" (onClick)="download()">Скачать справку</tc-button>
</tpt-dialog>

<tpt-no-data-dialog (closeModal)="close()"></tpt-no-data-dialog>
