import { Component, Input, OnInit } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';

@Component({
  selector: 'tc-tel-input',
  templateUrl: './tc-tel-input.component.html',
  styleUrls: ['./tc-tel-input.component.scss']
})
export class TcTelInputComponent extends TptControlDirective {

  @Input() preferredCountries = [];
}
