<div class="sidebar-root">
  <div class="fs-20 text-white fw-700 mb-24">Проекты</div>

  <tc-button *ngIf="isEmployer" size="small" type="ghost" class="full-width mb-24" (onClick)="goToCreateProject.emit()">
    <mat-icon class="plus-icon" [svgIcon]="svgIconsEnum.Plus"></mat-icon>
    Создать новый
  </tc-button>

  <div class="tree">
    <div class="list-item">
      <div class="list-item-title">
        <mat-icon [svgIcon]="menuItems.active.icon" class="mr-8"></mat-icon>
        <span class="fs-14 fw-600 text-white" (click)="scrollTo.emit('active')">{{menuItems.active.label}}</span>
        <mat-icon [svgIcon]="svgIconsEnum.Expand" class="ml-a"
                  *ngIf="activeJobs?.length"
                  (click)="menuItems.active.expanded = !menuItems.active.expanded"
                  [class.icon-expanded]="menuItems.active.expanded"></mat-icon>
      </div>
      <div class="projects-list" *ngIf="menuItems.active.expanded">
        <div class="project-name" *ngFor="let project of activeJobs"
             [routerLinkActiveOptions]="{ exact: true }"
             routerLinkActive="active-item"
             [routerLink]="routeHelper.employerProjectTaskTracker(project.id)">
          {{project.projectName || project.jobName}}
        </div>
      </div>
    </div>
    <div class="list-item">
      <div class="list-item-title">
        <mat-icon [svgIcon]="menuItems.ended.icon" class="mr-8"></mat-icon>
        <span class="fs-14 fw-600 text-white" (click)="scrollTo.emit('ended')">{{menuItems.ended.label}}</span>
        <mat-icon [svgIcon]="svgIconsEnum.Expand" class="ml-a"
                  *ngIf="completedJobs?.length"
                  (click)="menuItems.ended.expanded = !menuItems.ended.expanded"
                  [class.icon-expanded]="menuItems.ended.expanded"></mat-icon>
      </div>
      <div class="projects-list" *ngIf="menuItems.ended.expanded">
        <div class="project-name" *ngFor="let project of completedJobs"
             [routerLinkActiveOptions]="{ exact: true }"
             routerLinkActive="active-item"
             [routerLink]="routeHelper.employerProjectTaskTracker(project.id)">
          {{project.projectName || project.jobName}}
        </div>
      </div>
    </div>
    <div class="list-item" *ngIf="isEmployer">
      <div class="list-item-title">
        <mat-icon [svgIcon]="menuItems.draft.icon" class="mr-8"></mat-icon>
        <span class="fs-14 fw-600 text-white" (click)="scrollTo.emit('draft')">{{menuItems.draft.label}}</span>
        <mat-icon [svgIcon]="svgIconsEnum.Expand" class="ml-a"
                  *ngIf="draftJobs.length"
                  (click)="menuItems.draft.expanded = !menuItems.draft.expanded"
                  [class.icon-expanded]="menuItems.draft.expanded"></mat-icon>
      </div>
      <div class="projects-list" *ngIf="menuItems.draft.expanded">
        <div class="project-name" *ngFor="let project of draftJobs"
             [routerLink]="routeHelper.projectEditor(project.id)">
          {{project.projectName || project.jobName || 'Нет названия'}}
        </div>
      </div>
    </div>
  </div>
</div>
