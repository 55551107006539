import { ComponentType } from '@angular/cdk/portal';
import { Component, ComponentRef, Injector, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { ProfileService } from '../../../services/profile.service';
import { PopupComponent } from '../../../components/common-dialogs/popup/popup.component';
import { NotificationContentComponent } from './notification-content.component';
import { SvgIconsEnum } from '../../../types/svg-icons.enum';
import { NotificationService } from '../../../services/notification.service';
import { NewWsService } from '../../../services/new-ws.service';
import {
  NotificationType,
  showErrorIcon,
  showInfoIcon,
  showSuccessIcon,
  showWarningIcon
} from '../../../classes/enums';
import { CurrencySymbol } from '../../../models/currency.enum';

@Component({
  selector: 'tpt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends PopupComponent<NotificationContentComponent> implements OnInit, OnDestroy {

  public oldMessages = [];
  svgIconsEnum = SvgIconsEnum;

  public count = 0;

  public config = {
    newestOnTop: true,
    tapToDismiss: false,
    enableHtml: true,
    timeOut: 6000
  };

  private readonly destroy = new Subject<void>();

  constructor(
    public profileService: ProfileService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private toast: ToastrService,
    private ws: NewWsService,
    injector: Injector,
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.notificationService.getNotifications().pipe(takeUntil(this.destroy)).subscribe(res => {
      this.oldMessages = res;

      this.fillComponent(this._component);
    });

    this.notificationService.getCountNotifications().pipe(takeUntil(this.destroy)).subscribe(res => {
      this.count = res;
    });

    this.subscribeToNewMessages();
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  public getComponent(): ComponentType<NotificationContentComponent> {
    return NotificationContentComponent;
  }

  public fillComponent(component: ComponentRef<NotificationContentComponent>): void {
    if (component) {
      component.instance.notifications = this.oldMessages;
      component.instance.clear.pipe(
        takeUntil(this.destroy),
      ).subscribe(() => {});

      component.instance.ack.pipe(
        takeUntil(this.destroy),
      ).subscribe((notification) => {
        this.count -= 1;
      });

      component.instance.delete.pipe(
        takeUntil(this.destroy),
      ).subscribe((notification) => {
        this.oldMessages = this.oldMessages.filter(item => item.id !== notification.id);
        component.instance.notifications = this.oldMessages;
      });

      component.instance.close.pipe(
        takeUntil(this.destroy),
      ).subscribe((notification) => {
        this.close();
      });

      component.instance.markAllAsReadEvent.pipe(takeUntil(this.destroy))
        .subscribe(() => this.markAllAsRead());
    }
  }

  public show(ev): void {
    this.open(ev);
  }

  private markAllAsRead() {
    this.notificationService.markAllAsRead().subscribe(() => {
      this.count = 0;
    }, () => {});
  }

  private subscribeToNewMessages(): void {
    this.ws.newNotification.pipe(takeUntil(this.destroy)).subscribe(msg => {
      if (!msg) { return; }
      if (this.oldMessages.some(item => item.id === msg.id)) {
        return;
      }

      this.showToast(msg).then();
      this.oldMessages.unshift(msg);
      this.count += 1;
      this.fillComponent(this._component);
    });
  }

  private showToast = async (msg) => {
    const toastType = showErrorIcon.includes(msg?.code) ? 'error' :
                showInfoIcon.includes(msg?.code) ? 'info' :
                showSuccessIcon.includes(msg?.code) ? 'success' :
                showWarningIcon.includes(msg?.code) ? 'warning' : 'info';

    const data = msg.data;
    const type = data?.type;

    let text = await this.translate.get(msg.code, {
      emp: data?.employer && `${data.employer.firstName} ${data.employer.lastName}` || '',
      fr: data?.freelancer && `${data.freelancer.firstName} ${data.freelancer.lastName}` || '',
      project: type === 'project' ? data?.name || data?.jobName :
        type === 'compose' ? this.getProjectNameFromComposeType(data) :
        data?.project?.name || data?.project?.projectName || data?.project?.jobName ||
        data?.task?.project?.name || data?.task?.job?.name || data?.job?.name || data?.jobName || '',
      job: data?.jobName || '',
      task: data && data.name || '',
      deadline: data && typeof data.reviewDaysLeft === 'number' ? data.reviewDaysLeft : '',
      empSum: data?.employerRefund || '',
      frSum: data?.freelancerRefund || '',
      currency: CurrencySymbol[data?.job?.currency?.code] || CurrencySymbol[data?.task?.job?.currency?.code] || '',
      amount: type === 'taxPayment' ? `${data?.amount}₽` || '' : '',
      currMonth: data?.currMonth ? this.formatMonth(data.currMonth, false) : '',
      prevMonth: data?.prevMonth ? this.formatMonth(data.prevMonth, true) : '',
      company: data?.employerLegalInfo?.companyName || '',
      contract: data?.contractNumber || '',

    }).toPromise();

    if (msg.code === NotificationType.FNS) {
      text = data.message;
    }

    this.toast.show(text, '', this.config, toastType);
  }

  private formatMonth(date: number[], standalone = true): string {
    const standaloneMonths = ['', 'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    const formatMonths = ['', 'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    const month = date[1];
    return standalone ? `${standaloneMonths[month]}` : `${formatMonths[month]}`;
  }

  private getProjectNameFromComposeType(data) {
    return data?.data?.find(item => item?.type === 'project')?.name;
  }
}
