<tpt-dialog>
  <div class="dialog-wrapper">
    <mat-icon [svgIcon]="accountBlocked ? svgIconsEnum.ErrIcon : svgIconsEnum.WarnIcon"></mat-icon>

    <div class="title" *ngIf="title">{{title | translate}}</div>

    <div class="message" *ngIf="message">{{message | translate}}</div>

    <tc-button *ngIf="!showSendEmailBtn" type="tertiary" size="x-large" class="full-width" (onClick)="close()">
      {{'BUTTON.CLOSE' | translate}}
    </tc-button>
    <tc-button *ngIf="showSendEmailBtn" type="primary" size="x-large" class="full-width" (onClick)="sendEmail()">
      {{'BUTTON.SEND_CONFIRM' | translate}}
    </tc-button>
  </div>
</tpt-dialog>
