import { Component, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'tpt-get-acquainted-dialog',
  templateUrl: './get-acquainted-dialog.component.html',
  styleUrls: ['./get-acquainted-dialog.component.scss']
})
export class GetAcquaintedDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  job;

  private className = 'tpt-generate-invoice-dialog';

  private config: MatDialogConfig = {
    width: '520px',
  };

  constructor(private router: Router, private routeHelper: RouteHelper) { }

  public open(job?): void {
    this.job = job;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public fillDetails(): void {
    if (this.job) {
      this.router.navigate(this.routeHelper.settingsGeneral, { queryParams: { jobId: this.job.id } });
    } else {
      this.router.navigate(this.routeHelper.settingsGeneral);
    }

    this.close();
  }

}
