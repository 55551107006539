<div
  class="input-field"
  [class.error-state]="errorState"
  [class.has-icon]="!!icon"
  [class.has-value]="!!control.value"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
  <input
    #input
    [formControl]="searchControl"
    [type]="type"
    (keyup)="keyup.emit()"
    (blur)="onBlur.emit()"
    [class.uppercase]=uppercase
    [matAutocomplete]="auto"
  >
  <div *ngIf="showLabel" class="label" (click)="input.focus()">
    <ng-content select="[label]"></ng-content>
  </div>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="setValue($event)">
    <mat-option *ngFor="let option of options" [value]="option">
      {{option?.[nameField]}}
    </mat-option>
  </mat-autocomplete>
</div>
