<div
  *ngIf="profileService.currentProfile"
  class="sidebar"
  [class.-extended]="extendedSidebar"
>
  <div
    class="sidebar-main"
    [class.-expanded]="expanded"
  >
    <div class="logo-and-menu">
      <mat-icon
        class="logo"
        [class.-hidden]="!expanded"
        [svgIcon]="svgIconsEnum.FooterLogo"
        [routerLink]="'/landing'"
      ></mat-icon>
      <mat-icon
        class="logo"
        [class.-hidden]="expanded"
        [svgIcon]="svgIconsEnum.HeaderLogo"
        [routerLink]="'/landing'"
      ></mat-icon>

      <div class="divider"></div>

      <div class="menu">
        <div class="top-menu">
          <a [routerLink]="routeHelper.finances">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon
                [svgIcon]="svgIconsEnum.SidebarFinances"
              ></mat-icon>
              <span class="item-text">
            <span class="tab-name">{{'SIDEBAR.FINANCE' | translate}}</span>
          </span>
            </div>
          </a>
          <a [routerLink]="routeHelper.executorsPage" *ngIf="isEmployer">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon
                [svgIcon]="svgIconsEnum.SidebarTeam"
              ></mat-icon>
              <span class="item-text">
                <span class="tab-name wrap">Мои исполнители</span>
              </span>
            </div>
          </a>
          <a [routerLink]="routeHelper.massPayments" *ngIf="isEmployer">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon [svgIcon]="svgIconsEnum.SidebarPayments"
                        matBadge="{{unreadPaymentsCount > 99 ? '99+' : unreadPaymentsCount}}"
                        [matBadgeHidden]="!unreadPaymentsCount" matBadgeColor="primary"
              ></mat-icon>
              <span class="item-text">
            <span class="tab-name wrap">Выплаты</span>
          </span>
            </div>
          </a>

          <a [routerLink]="routeHelper.clientsPage" *ngIf="!isEmployer">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon [svgIcon]="svgIconsEnum.SidebarTeam"></mat-icon>
              <span class="item-text">
              <span class="tab-name">Мои заказчики</span>
            </span>
            </div>
          </a>

          <a [routerLink]="routeHelper.payments" *ngIf="!isEmployer">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon [svgIcon]="svgIconsEnum.SidebarPayments"></mat-icon>
              <span class="item-text">
              <span class="tab-name">Выплаты</span>
            </span>
            </div>
          </a>
          <a [routerLink]="routeHelper.contracts">
            <div
              class="menu-item message"
              routerLinkActive="active-link"
            >
              <mat-icon matBadge="{{unreadCount > 99 ? '99+' : unreadCount}}"
                        [matBadgeHidden]="expanded || !unreadCount" matBadgeColor="primary"
                        [svgIcon]="svgIconsEnum.SidebarMessages"
              ></mat-icon>
              <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.MESSAGES' | translate}}</span>
              <div *ngIf="unreadCount && expanded"
                   class="unread-messages">{{unreadCount > 99 ? '99+' : unreadCount}}</div>
            </span>
            </div>
          </a>
        </div>

        <div class="bottom-menu">
          <div class="divider mb-24"></div>

          <a [routerLink]="isEmployer ? routeHelper.freelancerSearch : routeHelper.projectSearch">
            <div
              class="menu-item"
              [class.active-link]="searchIsActive"
            >
              <mat-icon
                [svgIcon]="svgIconsEnum.SidebarSearch"
                routerLinkActive="active-link"
              ></mat-icon>
              <span class="item-text">
            <span class="tab-name">{{isEmployer ? 'Поиск исполнителей' : 'Поиск проектов'}}</span>
          </span>
            </div>
          </a>
          <a [routerLink]="projectsRoute">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon
                [svgIcon]="svgIconsEnum.SidebarProjects"
              ></mat-icon>
              <span class="item-text">
            <span class="tab-name">Мои проекты</span>
          </span>
            </div>
          </a>
          <a [routerLink]="routeHelper.settings">
            <div
              class="menu-item"
              routerLinkActive="active-link"
            >
              <mat-icon
                [svgIcon]="svgIconsEnum.SidebarSettings"
              ></mat-icon>
              <span class="item-text">
              <span class="tab-name">{{'SIDEBAR.SETTINGS' | translate}}</span>
            </span>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div
      *ngIf="!extendedSidebar"
      class="toggle-sidebar-button"
      (click)="toggleSidebar()"
    >
      <mat-icon [svgIcon]="svgIconsEnum.SidebarArrow"></mat-icon>
    </div>
  </div>

  <div *ngIf="extendedSidebar" class="sidebar-extended">
    <ng-template [cdkPortalOutlet]="sidebarPortal"></ng-template>
  </div>
</div>
