<tpt-dialog>
  <div class="wrapper">
    <mat-icon [svgIcon]="icon" class="mb-24"></mat-icon>
    <div class="fs-20 fw-700 text-black mb-8 text-center" [innerHTML]="title"></div>
    <div class="fs-14 fw-500 text-gray-600 text-center" [innerHTML]="text"></div>

    <div class="d-flex gg-12 w-100">
      <tc-button type="tertiary" size="x-large" class="full-width mt-32" (onClick)="close()">
        {{buttonText}}
      </tc-button>
      <tc-button type="secondary" size="x-large" class="full-width mt-32"
                 *ngIf="showInstruction"
                 (onClick)="openInstruction()">
        Инструкция о постановке на учет
      </tc-button>
    </div>
  </div>
</tpt-dialog>
