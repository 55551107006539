<div [ngClass]="size" *ngIf="status">
  <div class="legal-status active" *ngIf="status === 'ACTIVE' && bankDetailsValidated && legalInfoValidated && !limitExceeded">
    <mat-icon [svgIcon]="svgIconsEnum.WavyCheck"></mat-icon>
    Самозанятый
  </div>

  <div class="legal-status restricted" *ngIf="['NEW', 'NOT_VERIFIED'].includes(status) ||
              (status === 'ACTIVE' && (!bankDetailsValidated || !legalInfoValidated || limitExceeded))">
    <mat-icon [svgIcon]="svgIconsEnum.InfoWarn"></mat-icon>
      Ограниченный доступ
  </div>

  <div class="legal-status restricted" *ngIf="status === 'DETACHED'">
    <mat-icon [svgIcon]="svgIconsEnum.InfoWarn"></mat-icon>
    Отвязан от платформы
  </div>

  <div class="legal-status restricted" *ngIf="status === 'REMOVED_FROM_REGISTER'">
    <mat-icon [svgIcon]="svgIconsEnum.InfoWarn"></mat-icon>
    Снят с учёта
  </div>
</div>
