import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as SockJS from 'sockjs-client';
import { RxStompConfig } from '@stomp/rx-stomp';

import { RxStompService } from './rx-stomp.service';
import { AuthV2Service } from '@/services/auth/authV2.service';
import { BaseService } from '@/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class NewWsService {
  public newNotification: BehaviorSubject<any> = new BehaviorSubject(null);

  public chatOnBoardingWs: BehaviorSubject<any> = new BehaviorSubject(null);

  public newMessage: Subject<any> = new Subject();

  public updateUnreadCount = new Subject<string | void>();

  public operationSuccess: Subject<any> = new Subject<any>();

  public balanceUpdate: Subject<any> = new Subject<any>();

  public taskSubject = new Subject<any>();

  private serverUrl = `${window.location.protocol}//${environment.MAIN_HOST}/ws/connect`;

  constructor(private authV2Service: AuthV2Service,
              private baseService: BaseService,
              public rxStompService: RxStompService) {}

  connect(): void {
    this.serverUrl = this.baseService.baseUrl.replace('/api/','/ws/connect');
    const myRxStompConfig: RxStompConfig = {
      webSocketFactory: () => new SockJS(`${this.serverUrl}?access_token=${this.authV2Service.accessToken}`),
      reconnectDelay: 5000,
      // @TODO: turn it on if need to debug
      // debug: (msg: string): void => {
      //   console.log(new Date(), msg);
      // },
      beforeConnect: this.beforeConnectCb
    };

    this.rxStompService.configure(myRxStompConfig);
    this.rxStompService.activate();

    this.subscribeToStreams();

    this.rxStompService.stompClient.onStompError = (err) => {
        console.log(err, '<<<<<onStompError');
    };

    this.rxStompService.stompClient.onWebSocketClose = (err) => {
        console.log(err, new Date(), '<<<<<onWebSocketClose');
    };

    this.authV2Service.disconnect.subscribe(() => {
      this.disconnect().then();
    });
  }

  beforeConnectCb = async (): Promise<void> => {
    if (!this.authV2Service.accessToken) {
      await this.disconnect();
    }
  }

  subscribeToStreams(): void {
    this.rxStompService.watch('/user/queue/notifications').subscribe((greeting) => {
      const message = JSON.parse(greeting.body);
      this.newNotification.next(message);
    });

    this.rxStompService.watch('/user/queue/system').subscribe((message) => {
      if (message && message.body) {
        const value = JSON.parse(message.body);
        this.chatOnBoardingWs.next(value);

        if (value.cash) {
          this.balanceUpdate.next(value);
        }
      }
    });

    this.rxStompService.watch('/user/queue/finance').subscribe((message) => {
      if (message && message.body) {
        this.operationSuccess.next(JSON.parse(message.body));
      }
    });

    this.rxStompService.watch('/user/queue/chats').subscribe((message) => {
      if (message && message.body) {
        const value = JSON.parse(message.body);
        this.newMessage.next(value);

        this.updateUnreadCount.next(value?.sender?.id);
      }
    });

    this.rxStompService.watch('/user/queue/tasks').subscribe((message) => {
      if (message && message.body) {
        this.taskSubject.next(JSON.parse(message.body));
      }
    });
  }

  async disconnect(): Promise<void> {
    await this.rxStompService?.stompClient.deactivate();
  }

  connected(): boolean {
    return this.rxStompService?.stompClient.connected;
  }
}
