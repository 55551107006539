import { lastValueFrom, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export abstract class BaseService {
  private _baseUrl = `${window.location.protocol}//${environment.MAIN_HOST}/api/`;

  public get baseUrl(): string {
    return this._baseUrl;
  }

  set baseUrl(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  protected constructor(protected http: HttpClient) {
  }

  public updateBaseUrl(newUrl: string) {
    if (newUrl) {
      this.baseUrl = `${window.location.protocol}//${newUrl}/api/`;
    }
  }

  public executeGet<T>(url: string, options?: {}, baseUrl = this.baseUrl): Observable<T> {
    return this.http.get<T>(baseUrl + url, options).pipe(
      catchError(this.errorHandlerObs)
    );
  }

  public executePost<T>(url: string, data: any, options?: {}, baseUrl = this.baseUrl): Promise<T> {
    return lastValueFrom(this.http.post<T>(baseUrl + url, data, options)
      .pipe(take(1))).catch(this.errorHandler);
  }

  public executePut<T>(url: string, data: any, options?: {}, baseUrl = this.baseUrl): Observable<T> {
    return this.http.put<T>(baseUrl + url, data, options).pipe(
      catchError(this.errorHandler)
    );
  }

  public executeDelete<T>(url: string, options?: {}, baseUrl = this.baseUrl): Promise<T> {
    return lastValueFrom(this.http.delete<T>(baseUrl + url, options)
      .pipe(take(1))).catch(this.errorHandler);
  }

  protected errorHandler(error: HttpErrorResponse): Promise<any> {
    if (Number(error.status) === 401) {
      // LogoutService.logout();
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return Promise.reject(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(`Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
      return Promise.reject(error.error);
    }
  }

  protected errorHandlerObs(error: HttpErrorResponse): Observable<any> {
    // костыль чтоб не выкидывало при просмотре страницы проекта
    if (Number(error.status) === 401 && !error?.error?.details?.hasOwnProperty('visibility')) {
      // LogoutService.logout();
    }
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(`Backend returned code ${error.status}, ` +
      //   `body was: ${error.error}`);
    }
    return throwError(error.error);
  }

}

