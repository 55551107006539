<div
  [class.error-state]="errorState"
  class="input-field"
  [class.has-icon]="!!icon"
  [class.has-value]="hasValue || prefix"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon"></mat-icon>
  <input
    #input
    [mask]="mask"
    [prefix]="prefix"
    [formControl]="control"
    [placeholder]="placeholder"
    [type]="type"
    (keyup)="onKeyup.emit()"
    (blur)="onBlur.emit()"
    (focus)="onFocus.emit()"
    [class.uppercase]=uppercase
    thousandSeparator=" "
  >
  <div *ngIf="showLabel" class="label" (click)="input.focus()">
    <ng-content select="[label]"></ng-content>
  </div>
  <ng-content select="[icon]"></ng-content>
</div>

<mat-hint [align]="hintAlign" *ngIf="!errorState">
  <ng-content select="[hint]"></ng-content>
</mat-hint>

<mat-error [class.-invisible]="!errorState">
  {{ error | translate}}
</mat-error>
