import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { TimeAgoPipe } from './pipes/time-ago-pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DetectUrlPipe } from './pipes/detect-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ShortCompanyNamePipe } from '@/modules/common-pipes/pipes/short-company-name.pipe';

@NgModule({
  declarations: [
    LocalDatePipe,
    TimeAgoPipe,
    TruncatePipe,
    DetectUrlPipe,
    SafeHtmlPipe,
    ShortCompanyNamePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LocalDatePipe,
    TimeAgoPipe,
    TruncatePipe,
    DetectUrlPipe,
    SafeHtmlPipe,
    ShortCompanyNamePipe,
  ]
})
export class CommonPipesModule {
}
