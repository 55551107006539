import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class DadataService {
  constructor(private baseService: BaseService) {}

  public suggestAddress(body): Observable<any> {
    return fromPromise(this.baseService.executePost<any>('v2/dadata/suggestAddress', body));
  }

}
