<div
  [class.error-state]="errorState"
  class="input-field"
  [class.has-icon]="!!icon"
  [class.has-value]="!!addressFormControl.value"
  (clickOutside)="closePanel()"
  (click)="openPanel()"
  #autocomplete
  cdkOverlayOrigin
  #dropdown="cdkOverlayOrigin"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon" class="input-icon"></mat-icon>
  <input type="text" class="input" #input
         [class.-error-state]="errorState"
         (input)="checkPanel()"
         [formControl]="addressFormControl"/>

  <mat-icon [svgIcon]="svgIconsEnum.Expand" class="expand-icon"></mat-icon>
  <div *ngIf="showLabel" class="label">
    <ng-content select="[label]"></ng-content>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dropdown"
  [cdkConnectedOverlayOpen]="panelOpened"
  [cdkConnectedOverlayWidth]="overlayWidth"
>
  <div #panel class="tc-dropdown-options-panel" *ngIf="panelOpened && data?.length" [ngClass]="position">
    <div *ngFor="let option of data"
         (click)="$event.stopPropagation(); setOption(option)"
         [class.selected]="option.value === addressFormControl.value"
         class="option">
      {{option.value}}
    </div>
  </div>
</ng-template>

<mat-error [class.invisible]="!errorState">
  {{ error | translate}}
</mat-error>
