import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationErrorService } from '@/modules/tpt-forms/services/validation-error.service';
import { DadataService } from '@/services/dadata.service';
import { debounceTime } from 'rxjs/operators';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { Subject, Subscription } from 'rxjs';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

@Component({
  selector: 'tc-address-autocomplete',
  templateUrl: './tc-address-autocomplete.component.html',
  styleUrls: ['./tc-address-autocomplete.component.scss'],
})
export class TcAddressAutocompleteComponent implements OnChanges, OnDestroy {
  public get error(): string {
    return this.validationErrorService.getValidationErrorMessage(this.addressFormControl);
  }

  public get errorState(): boolean {
    return this.addressFormControl.invalid && (this.addressFormControl.dirty || this.addressFormControl.touched);
  }
  @ViewChild('panel')panel: ElementRef;
  @ViewChild('dropdown')dropdown: CdkOverlayOrigin;

  @ViewChild('input')input: ElementRef;
  @ViewChild('autocomplete')autocomplete: ElementRef;
  @Input() placeholder = '';

  @Input() addressFormControl = new FormControl();

  @Input() config = {
    locations: [
      {country: '*'}
    ]
  };
  @Input() public icon = null;

  @Input() public showLabel = true;

  @Input()
  public disabled = false;

  public data: any = [];
  public svgIconsEnum = SvgIconsEnum;
  public panelOpened = false;
  public position: 'below' | 'above';
  public overlayWidth = 180;
  private subscription: Subscription;

  private destroy$ = new Subject<void>();

  constructor(private validationErrorService: ValidationErrorService,
              private dadataService: DadataService,
  ) { }

  subscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.addressFormControl.valueChanges
      .pipe(debounceTime(500)).subscribe(val => {
      if (!val || val?.trim().length < 3) {
        return;
      }
      this.dadataService.suggestAddress({ query: val, ...this.config}).subscribe(res => {
        this.data = res.suggestions;
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.addressFormControl) {
      this.subscribe();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setOption(option: any) {
    this.addressFormControl.setValue(option.value);
    this.panelOpened = false;
    this.input.nativeElement.blur();
  }

  closePanel(): void {
    if (this.panelOpened) {
      this.panelOpened = false;
    }
  }

  openPanel(): void {
    if (this.disabled) {
      return;
    }
    this.panelOpened = true;
    this.calculateWidth();
    this.input.nativeElement.focus();
  }

  checkPanel(): void {
    if (this.disabled) {
      return;
    }
    if (!this.panelOpened) {
      this.calculateWidth();
      this.panelOpened = true;
    }
  }

  calculateWidth(): void {
    this.overlayWidth = this.dropdown?.elementRef?.nativeElement?.getBoundingClientRect()?.width || 180;
  }
}
