import { Injectable } from '@angular/core';
import { FinancesApiService } from './finances.api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FinanceHistoryListFilterModel } from '@/modules/finance/models/finance-history-list-filter.model';
import { FinanceHistoryListItemModel } from '@/modules/finance/models/finance-history-list-item.model';
import moment from 'moment';
import { FutureExpenseModel } from '@/modules/finance/models/future-expense.model';
import { TaxModel } from '@/modules/finance/models/tax.model';
import { InvoiceModel } from '@/models/financev2/invoice.model';
import { tap } from 'rxjs/operators';
import { AccountModel } from '@/models/financev2/account.model';

@Injectable({
  providedIn: 'root'
})
export class FinanceServiceV2 {
  public accountBalance = new BehaviorSubject<AccountModel>(null);

  constructor(
    private financesApiService: FinancesApiService,
  ) {
  }

  public getHistoryOperations = (filter?: FinanceHistoryListFilterModel): Observable<FinanceHistoryListItemModel[]> => {
    if (filter && filter.from) {
      filter.from = moment(filter.from).format('YYYY-MM-DD');
    }

    if (filter && filter.to) {
      filter.to = moment(filter.to).format('YYYY-MM-DD');
    }
    return this.financesApiService.getHistoryOperations(filter);
  }

  public getBalance = () => {
    return this.financesApiService.getBalance().pipe(tap((res) => {
      this.accountBalance.next(res);
    }));
  }

  public createInvoice = (amount: number): Promise<InvoiceModel> => {
    return this.financesApiService.createInvoice(amount);
  }

  public getUnpaidInvoices = (): Observable<InvoiceModel[]> => {
    return this.financesApiService.getUnpaidInvoices();
  }

  public deleteInvoice = (id: string): Promise<any> => {
    return this.financesApiService.deleteInvoice(id);
  }

  public withdraw = (amount: number): Promise<any> => {
    return this.financesApiService.withdraw(amount);
  }

  public getAwaitingPayments = (): Observable<FutureExpenseModel[]> => {
    return this.financesApiService.getAwaitingPayments();
  }

  public getTax = (): Observable<TaxModel> => {
    return this.financesApiService.getTax();
  }

  public sbpBanks = (): Observable<any> => {
    return this.financesApiService.sbpBanks();
  }

  public getIncomeInfoForThisYear = (): Observable<any> => {
    return this.financesApiService.getIncomeInfoForThisYear();
  }

  public cancelReceiptReasons = (): Observable<any> => {
    return this.financesApiService.cancelReceiptReasons();
  }

  public cancelReceipt = (receiptId: string, reason: string): Observable<any> => {
    return this.financesApiService.cancelReceipt(receiptId, reason);
  }
}
