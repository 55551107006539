<tpt-dialog>
  <div [ngSwitch]="error">
    <div *ngSwitchCase="'FNS.TAXPAYER_UNREGISTERED'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">Вы не самозанятый</div>

      <div class="text-gray-600 fs-14 mb-28 fw-500">
        Нам не удалось подтвердить ваш статус самозанятого.<br/>
        Убедитесь, что ИНН/номер телефона указан верно или получите статус самозанятого, следуя <a href="https://npd.nalog.ru/#start" target="_blank">инструкции</a>.
      </div>

      <div class="actions-row mt-28">
        <tc-button type="tertiary" size="x-large" class="full-width" (onClick)="close()">
          Закрыть
        </tc-button>
      </div>
    </div>

    <div *ngSwitchCase="'FNS.TAXPAYER_ALREADY_BOUND'" class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">
          Пользователь с таким ИНН уже привязан к платформе
      </div>

      <div class="text-gray-600 fs-14 mb-28 fw-500">
        Убедитесь, что ИНН/номер телефона указан верно.
      </div>
      <div class="actions-row mt-28">
        <tc-button type="tertiary" size="x-large" (onClick)="close()">
          Закрыть
        </tc-button>
      </div>
    </div>

    <div *ngSwitchDefault class="wrapper">
      <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

      <div class="fs-20 fw-700 text-black mb-24">
        Повторите попытку позже
      </div>

      <div class="text-gray-600 fs-14 mb-28 fw-500">
        Запрос на привязку к Talent Cloud не был отправлен. Пожалуйста, повторите попытку позже.
      </div>

      <div class="actions-row mt-28">
        <tc-button type="tertiary" size="x-large" class="full-width" (onClick)="close()">
          Закрыть
        </tc-button>
      </div>
    </div>
  </div>
</tpt-dialog>
