import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogConfig } from '@angular/material/dialog';

import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { FinanceServiceV2 } from '@/services/finance.service';
import { ResourcesService } from '@/services/resources.service';
import { RouteHelper } from '@/helpers/route.helper';
import { ChatApiService } from '@/services/chat.api.service';

enum TopUpStateEnum {
  Initial,
  Loading,
  Success,
  Error,
}

@Component({
  selector: 'app-balance-top-up-dialog',
  templateUrl: './balance-top-up-dialog.component.html',
  styleUrls: ['./balance-top-up-dialog.component.scss']
})
export class BalanceTopUpDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  updateDataTable: EventEmitter<any> = new EventEmitter<any>();

  public step = TopUpStateEnum.Initial;

  public topUpStateEnum = TopUpStateEnum;

  public svgIconsEnum = SvgIconsEnum;

  public formControl = new UntypedFormControl('',
    [Validators.required, Validators.min(100), Validators.max(20000000)]);

  public invoice;

  private config: MatDialogConfig = {
    width: '520px',
    minHeight: '305px',
  };

  private className = 'app-balance-top-up-dialog';

  constructor(private financeService: FinanceServiceV2,
              public routeHelper: RouteHelper,
              private router: Router,
              private chatService: ChatApiService,
              private resource: ResourcesService) { }

  public open(amount?: number): void {
    this.step = TopUpStateEnum.Initial;
    if (amount) {
      this.formControl.setValue(amount);
    } else {
      this.formControl.setValue('');
    }
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public generateInvoice(): void {
    this.formControl.markAsTouched();
    if (this.formControl.invalid) {
      return;
    }
    const amount = Number(this.formControl.value);

    this.step = TopUpStateEnum.Loading;
    this.financeService.createInvoice(amount)
      .then((invoice) => {
        this.invoice = invoice?.invoice;
        this.step = TopUpStateEnum.Success;
        this.updateDataTable.emit();
      })
      .catch(() => {
        this.step = TopUpStateEnum.Error;
      });
  }

  public close(): void {
    this.dialog.close();
  }

  public downloadInvoice(file): void {
    if (!file?.id) {
      return;
    }
    this.resource.getFile(file.id);
  }

  public createChatWithSupport(): void {
    this.chatService.createSupportChat().subscribe({
      next: (chat) => {
        this.router.navigate(this.routeHelper.contractsChatsUrl(chat.id));
        this.close();
      }, error: () => {
      }
    });
  }
}
