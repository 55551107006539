<mat-card class="job-card">
  <section>
    <div class="name">{{project?.projectName || project?.jobName || 'Нет названия'}}</div>
    <div class="description">{{project?.description}}</div>

    <div class="docs-wrap" *ngIf="project?.files?.length">
      <div class="doc" *ngFor="let doc of project.files" (click)="downloadFile(doc)">
        <mat-icon [svgIcon]="svgIconsEnum.Doc"></mat-icon>
        {{doc.name}}
      </div>
    </div>
  </section>

  <section *ngIf="project.type !== 'PAYROLL'">
    <div class="candidate-card">
      <div class="label">Исполнитель</div>

      <div class="d-flex align-items-center mb-12 candidate-info" *ngIf="project?.country">
        <mat-icon [svgIcon]="svgIconsEnum.Location" class="w-14 text-gray-600"></mat-icon>
        {{project?.country?.nameRu}}
      </div>

      <div class="d-flex align-items-center mb-12 candidate-info">
        <div class="d-flex align-items-center" *ngIf="project?.parentCategoryId">
          <mat-icon [svgIcon]="svgIconsEnum.CategorySmall" class="mr-8 text-gray-600 w-14"></mat-icon>
          {{(getProjectCategory(project) || 'Не указано') + '/' + (getProjectSubCategory(project) || 'Не указано')}}
        </div>

        <div class="d-flex align-items-center" *ngIf="project?.expertLevels?.length">
          <mat-icon [svgIcon]="svgIconsEnum.Expert" class="mr-8 text-gray-600"></mat-icon>
          {{project?.expertLevels | tptExpertLevels}}
        </div>
      </div>

      <div class="d-flex align-items-center mb-12 candidate-info" *ngIf="project?.languages?.length">
        <mat-icon [svgIcon]="svgIconsEnum.Language" class="text-gray-600"></mat-icon>
        <div *ngFor="let lang of project?.languages; let last = last">
          <ng-container *ngIf="lang?.code && lang?.level; else empty">
            {{getLangByCode(lang)}} - {{'CREATE_PROJECT.STEP3.' + lang.level | translate}} <span class="px-4" *ngIf="!last">•</span>
          </ng-container>
        </div>
        <ng-template #empty>Не указано</ng-template>
      </div>

      <div class="d-flex align-items-center candidate-info skills-info" *ngIf="specSkills(project).length">
        <tpt-chip class="chip" type="gray" size="small" *ngFor="let skill of specSkills(project)">
          <span>{{skill?.nameRu || skill}}</span>
        </tpt-chip>
      </div>
    </div>
  </section>

  <div class="project-info">
    <div class="d-flex align-items-center">
      <tpt-avatar class="mr-12" [user]="project?.employer" [textSizeRatio]="2.5" size="32"></tpt-avatar>
      <div class="">
        <div class="label">Заказчик</div>
        <div class="value">{{project.employer?.firstName}} {{project.employer?.lastName}}
          <span class="text-gray-600" *ngIf="project.companyName">({{project.companyName}})</span>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="mr-12">
        <tpt-avatar *ngIf="getUserIsExecutor(); else talent"
                    [user]="profileService.currentProfile"
                    size="32"
                    [textSizeRatio]="2.5"></tpt-avatar>
        <ng-template #talent>
          <mat-icon [svgIcon]="svgIconsEnum.Talent"
                    *ngIf="[projectStateEnum.Accepted, projectStateEnum.Ended].includes(project.state); else dashed"></mat-icon>
          <ng-template #dashed>
            <div class="no-user-avatar"></div>
          </ng-template>
        </ng-template>
      </div>
      <div class="">
        <div class="label">Исполнитель</div>
        <div class="value">{{getTalentStatus()}}</div>
      </div>
    </div>
  </div>
</mat-card>
