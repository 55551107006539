import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { ResourcesService } from '@/services/resources.service';
import { CancelReceiptDialogComponent } from '../cancel-receipt-dialog/cancel-receipt-dialog.component';
import { FinanceServiceV2 } from '@/services/finance.service';
import { SpinnerService } from '../../../tpt-ui/services/spinner.service';
import { SnackbarNotificationsService } from '@/services/snackbar-notifications.service';
import { ProfileService } from '@/services/profile.service';
import { FileUpload } from '@/models/fileUpload';

interface ReceiptInfo {
  cancelReasonCode: string;
  canceled: boolean;
  canceledReceipt: FileUpload;
  id: string;
  receipt: FileUpload;
}

@Component({
  selector: 'tpt-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.scss']
})
export class ReceiptDialogComponent {
  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(CancelReceiptDialogComponent, {static: false}) cancelReceiptDialogComponent: CancelReceiptDialogComponent;

  public fileId: string;

  public tempUrl: string;

  public receiptId: string;

  public receiptCanceled = false;

  public receiptName: string;

  @Output()
  updateHistory: EventEmitter<any> = new EventEmitter<any>();

  private config: MatDialogConfig = {
    maxWidth: '100%',
    width: '515px',
    maxHeight: 'var(--app-height)',
    minHeight: '200px',
  };

  private className = 'tpt-receipt-dialog';

  constructor(private resource: ResourcesService,
              private spinner: SpinnerService,
              public profile: ProfileService,
              private snack: SnackbarNotificationsService,
              private financeService: FinanceServiceV2) {
  }

  public open(receiptInfo: ReceiptInfo): void {
    this.receiptCanceled = receiptInfo.canceled;
    if (receiptInfo?.canceled) {
      this.fileId = receiptInfo.canceledReceipt?.id;
      this.tempUrl = receiptInfo.canceledReceipt?.tempUrl;
      this.receiptName = receiptInfo.canceledReceipt?.name?.split('.')?.[0];
    } else {
      this.fileId = receiptInfo.receipt?.id;
      this.tempUrl = receiptInfo.receipt?.tempUrl;
      this.receiptName = receiptInfo.receipt?.name?.split('.')?.[0];
    }

    this.receiptId = receiptInfo.id;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public getUrl(link: string): string {
    return this.resource.getTempUrl(link);
  }

  public cancelReceipt(): void {
    this.close();
    this.updateHistory.emit();
  }

  public download() {
    if (!this.fileId) {
      return;
    }

    this.resource.getFileByUUID(this.fileId).subscribe(this.handleFile);
  }

  public openCheckCancelDialog(): void {
    this.spinner.startSpinner();
    this.financeService.cancelReceiptReasons().subscribe({
      next: reasons => {
        this.spinner.stopSpinner();
        this.cancelReceiptDialogComponent.open(reasons, this.receiptId);
      },
      error: this.spinner.stopSpinner
    });
  }

  private handleFile = (file: File): void => {
    const objectURL = this.resource.createObjectURL(file);
    const link = document.createElement('a');
    link.download = decodeURIComponent(file.name);
    link.href = objectURL;
    link.click();
    URL.revokeObjectURL(objectURL);
  }
}
