import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authConfig = (authConfig): AuthConfig => {
  return {
    issuer: authConfig?.issuer ? authConfig.issuer : environment.authConfig.issuer,
    clientId: 'tpt_client', // The "Auth Code + PKCE" client
    requireHttps: false,
    dummyClientSecret: 'kejIGDgaHKrWQr4gBREhyTaKtEISJgmQ',
    responseType: 'code',
    redirectUri: window.location.origin + '/jobs',
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    useSilentRefresh: true,
    silentRefreshTimeout: 60000,
    timeoutFactor: 0.8,
    logoutUrl: window.location.origin,
    scope: 'openid microprofile-jwt offline_access',
    showDebugInformation: true,
  };
};
