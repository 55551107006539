<div
  [class.error-state]="errorStateCtrl(start) || errorStateCtrl(end)"
  class="input-field"
  [class.has-icon]="!!icon"
  [class.has-value]="!!start.value || !!end.value"
  (click)="picker.open()"
  [class.focused]="focused"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon" class="input-icon"></mat-icon>

  <mat-date-range-input [rangePicker]="picker" [min]="min" [max]="max"
                        [comparisonEnd]="comparisonEnd"
                        [comparisonStart]="comparisonStart"
                        [class.focused]="focused">
    <input matStartDate [formControl]="start" #startDate (dateChange)="dateChanged(startDate)">
    <input matEndDate [formControl]="end" #endDate (dateChange)="dateChanged(endDate)">
  </mat-date-range-input>
  <mat-date-range-picker #picker (opened)="focused=true" (closed)="focused=false"></mat-date-range-picker>

  <div *ngIf="showLabel" class="label">
    <ng-content select="[label]"></ng-content>
  </div>
</div>

<mat-hint [align]="hintAlign" *ngIf="!(errorStateCtrl(start) || errorStateCtrl(end))">
  <ng-content select="[hint]"></ng-content>
</mat-hint>

<mat-error [class.invisible]="!(errorStateCtrl(start) || errorStateCtrl(end)) && !formErrorState()">
  <span *ngIf="ctrlError(start) || ctrlError(end)">{{ (ctrlError(start) || ctrlError(end)) | translate}}</span>
  <span *ngIf="!(errorStateCtrl(start) || errorStateCtrl(end)) && formErrorState()">Дата начала должна быть раньше даты окончания</span>
</mat-error>
