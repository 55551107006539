import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MainPageService {

  constructor(private baseService: BaseService) {}

  public contactUs(form): Observable<any> {
    return fromPromise(this.baseService.executePost<any>(`v2/feedback`, form));
  }

  public bookDemo(form): Observable<any> {
    return fromPromise(this.baseService.executePost<any>(`v2/demo`, form));
  }
}
