<tpt-dialog>
  <div class="dialog-root">
    <div class="headline">
      {{receiptName || 'Чек'}}
    </div>

    <img *ngIf="tempUrl" [src]="getUrl(tempUrl)" class="check">

    <div class="actions-row">
      <tc-button type="primary" size="medium" (onClick)="download()">
        <svg width="11" height="13" viewBox="0 0 11 13" class="mr-8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.84375 11.5625C10.1992 11.5625 10.5 11.8633 10.5 12.2188C10.5 12.6016 10.1992 12.875 9.84375 12.875H0.65625C0.273438 12.875 0 12.6016 0 12.2188C0 11.8359 0.273438 11.5625 0.65625 11.5625H9.84375ZM8.25781 5.4375C8.50391 5.16406 8.91406 5.16406 9.1875 5.41016C9.46094 5.65625 9.46094 6.06641 9.21484 6.33984L5.71484 10.0586C5.46875 10.332 5.00391 10.332 4.75781 10.0586L1.25781 6.33984C1.01172 6.06641 1.01172 5.65625 1.28516 5.41016C1.42188 5.30078 1.58594 5.24609 1.75 5.24609C1.91406 5.24609 2.07812 5.30078 2.21484 5.4375L4.59375 7.95312V1.30859C4.59375 0.925781 4.86719 0.625 5.25 0.625C5.60547 0.625 5.90625 0.925781 5.90625 1.30859V7.95312L8.25781 5.4375Z"
            fill="white"/>
        </svg>

        Скачать
      </tc-button>
      <tc-button type="error" size="medium" class="ml-12"
                 *ngIf="!profile.currentProfile.isEmployer() && !receiptCanceled"
                 (onClick)="openCheckCancelDialog()">
        Аннулировать чек
      </tc-button>
    </div>
  </div>

</tpt-dialog>

<tpt-cancel-receipt-dialog (cancelReceiptEvent)="cancelReceipt()"></tpt-cancel-receipt-dialog>
