import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';
import { MainPageGuard } from './guards/main-page.guard';
import { PortalService } from './services/portal.service';
import { SnackbarNotificationsComponent } from './components/snackbar-notifications/snackbar-notifications.component';
import { LoadingBarService } from './services/loading-bar.service';
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './components/toast/custom-toast.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@/modules/auth/core.module';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TcCheckboxComponent } from '@/modules/tpt-forms/tc-components/tc-checkbox/tc-checkbox.component';
import { HeaderComponent } from '@/components/head/header.component';
import { SidebarComponent } from '@/components/sidebar/sidebar.component';
import { TptUiModule } from '@/modules/tpt-ui/tpt-ui.module';
import { CommonComponentsModule } from '@/modules/common-components/common-components.module';

export function createHomeTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(
        http,
        `./assets/translations/`,
        '.json?' + environment.timeStamp,
    );
}

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [BrowserAnimationsModule,
    AppRoutingModule,
    MatMomentDateModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createHomeTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    TptUiModule,
    CommonComponentsModule,
    ToastrModule.forRoot({
      toastComponent: CustomToastComponent,
      preventDuplicates: true
    }),
    CoreModule.forRoot(),
    MatRippleModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxMaskDirective,
    NgxMaskPipe,
    TcCheckboxComponent,
    HeaderComponent,
    SidebarComponent,
    SnackbarNotificationsComponent,
  ],
  providers: [
    PortalService,
    DatePipe,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD.MM.YYYY',
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'YYYY',
        },
      },
    },
    { provide: LOCALE_ID, useValue: 'ru' },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true },
    },
    AuthGuard,
    RoleGuard,
    MainPageGuard,
    LoadingBarService,
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}
