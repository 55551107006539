import { Component, forwardRef, Input } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'tc-checkbox',
  standalone: true,
  imports: [
    MatCheckbox,
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TcCheckboxComponent),
      multi: true
    }
  ],
  templateUrl: './tc-checkbox.component.html',
  styleUrl: './tc-checkbox.component.scss'
})
export class TcCheckboxComponent implements ControlValueAccessor {

  @Input() indeterminate: boolean;
  @Input() disabled: boolean;
  checked = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onModelChange(e: boolean) {
    this.checked = e;
    this.onChange(e);
  }
}
