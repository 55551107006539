import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
}) export class StatesService {

  public sidebarExpanded = new BehaviorSubject<boolean>(false);

}
