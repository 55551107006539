import { Component, Input } from '@angular/core';

@Component({
  selector: 'tpt-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {

  @Input() type: 'outline' | 'fill' | 'disabled' | 'gray' = 'outline';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
