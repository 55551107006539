const mediaQueryMatches = (mediaQuery: string): boolean => {
  return window.matchMedia(mediaQuery).matches;
};

export const isMobileDevice = (): boolean => {
  return mediaQueryMatches('only screen and (max-width: 1023px)');
};

export const isSmallMobileDevice = (): boolean => {
  return mediaQueryMatches('only screen and (max-width: 767px)');
};
