<tpt-dialog>
  <div class="tpt-withdraw-funds-dialog-root">
    <ng-container *ngIf="withdrawState === withdrawStateEnum.Initial">
      <div class="fs-20 fw-700 mb-24 text-black">{{'FINANCE.WITHDRAW' | translate}}</div>
      <tc-input [icon]="svgIconsEnum.Digits" [control]="formControl">
        <ng-container label>Укажите сумму, которую хотите вывести<span class="red-asterisk">*</span></ng-container>
      </tc-input>

      <div class="mt-12 fs-14 fw-500 lh-20 text-gray-600 mb-48">
        Средства будут возвращены на реквизиты, указанные
        в данных отправителя при получении платежа
      </div>

      <div class="actions-row mt-a">
        <tc-button type="tertiary" size="x-large" (onClick)="dialog.close()">
          {{'BUTTON.CANCEL' | translate}}
        </tc-button>
        <tc-button type="primary" size="x-large" class="full-width" (onClick)="withdrawFunds()">
          Вывести средства
          <mat-icon [svgIcon]="svgIconsEnum.ArrowForward"></mat-icon>
        </tc-button>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Loading">
      <div class="loading-wrapper">
        <mat-spinner [diameter]="32" color="primary" [strokeWidth]="4"></mat-spinner>
        <div class="fs-20 fw-700 text-black mt-24 mb-4">Обрабатываем ваш запрос</div>
        <div class="text-gray-600 fw-500">Пожалуйста, подождите</div>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Success">
      <div class="success-wrapper pt-12">
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="56" rx="28" fill="#F4F9F7"/>
          <path d="M39.0195 20.4805C39.4766 20.9883 39.4766 21.75 39.0195 22.207L25.6133 35.6133C25.1055 36.1211 24.3438 36.1211 23.8867 35.6133L16.9805 28.707C16.4727 28.25 16.4727 27.4883 16.9805 27.0312C17.4375 26.5234 18.1992 26.5234 18.6562 27.0312L24.6992 33.0742L37.293 20.4805C37.75 20.0234 38.5117 20.0234 38.9688 20.4805H39.0195Z" fill="#458D70"/>
        </svg>
        <div class="fs-20 fw-700 text-black mt-24 mb-32">Средства были отправлены</div>
        <tc-button type="tertiary" size="x-large" class="full-width mt-a" (onClick)="close()">
          Понятно
        </tc-button>
      </div>
    </ng-container>

    <ng-container *ngIf="withdrawState === withdrawStateEnum.Error">
      <div class="success-wrapper pt-12">

        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="56" height="56" rx="28" fill="#FEF3F2"/>
          <path d="M28 17.6875C24.1914 17.6875 20.7383 19.7188 18.8086 22.9688C16.9297 26.2695 16.9297 30.2812 18.8086 33.5312C20.7383 36.832 24.1914 38.8125 28 38.8125C31.7578 38.8125 35.2109 36.832 37.1406 33.5312C39.0195 30.2812 39.0195 26.2695 37.1406 22.9688C35.2109 19.7188 31.7578 17.6875 28 17.6875ZM28 41.25C23.3281 41.25 19.0625 38.8125 16.7266 34.75C14.3906 30.7383 14.3906 25.8125 16.7266 21.75C19.0625 17.7383 23.3281 15.25 28 15.25C32.6211 15.25 36.8867 17.7383 39.2227 21.75C41.5586 25.8125 41.5586 30.7383 39.2227 34.75C36.8867 38.8125 32.6211 41.25 28 41.25ZM28 21.75C28.6602 21.75 29.2188 22.3086 29.2188 22.9688V28.6562C29.2188 29.3672 28.6602 29.875 28 29.875C27.2891 29.875 26.7812 29.3672 26.7812 28.6562V22.9688C26.7812 22.3086 27.2891 21.75 28 21.75ZM29.625 33.125C29.625 34.0391 28.8633 34.75 28 34.75C27.0859 34.75 26.375 34.0391 26.375 33.125C26.375 32.2617 27.0859 31.5 28 31.5C28.8633 31.5 29.625 32.2617 29.625 33.125Z" fill="#DB495C"/>
        </svg>

        <div class="fs-20 fw-700 text-black mt-24 mb-32">Что-то пошло не так. Повторите попытку позже</div>
        <tc-button type="tertiary" size="x-large" class="full-width mt-a" (onClick)="close()">
          Понятно
        </tc-button>
      </div>
    </ng-container>
  </div>
</tpt-dialog>


