<tpt-dialog>
  <div class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" class="mb-28"></mat-icon>

    <div class="fs-20 fw-700 text-black mb-24">Вы уверенны, что хотите отвязаться?</div>

    <div class="text-gray-600 fs-14 mb-28 fw-500">
      После отвязки от Talent Cloud вы потеряете полный доступ к функционалу платформы, а&nbsp;все активные сделки по проектам будут отменены.
    </div>

    <div class="actions-row mt-36">
      <tc-button type="tertiary" size="large" class="full-width" (onClick)="close()">
        Отмена
      </tc-button>
      <tc-button type="primary" size="large" class="full-width" (onClick)="unbind()">
        Отвязаться
      </tc-button>
    </div>
  </div>
</tpt-dialog>
