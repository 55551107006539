<tpt-dialog>
  <div class="dialog-root">
    <pdf-viewer [src]="src"
                [render-text]="true"
                [original-size]="true"
                [fit-to-page]="true"
    ></pdf-viewer>

    <div class="actions-row mt-28" *ngIf="showActions">
      <tc-button type="tertiary" size="medium" *ngIf="docType==='contract'" (onClick)="changeContractOpen()">Изменить условия</tc-button>
      <tc-button type="tertiary" size="medium" *ngIf="showSupportButton"
                  (onClick)="openSupportChat()">В чат поддержки</tc-button>
      <tc-button type="primary" size="medium" (onClick)="signDocument()">Подписать</tc-button>
    </div>

    <div class="actions-row mt-28" *ngIf="!showActions">
      <tc-button type="tertiary" (onClick)="close()">Отмена</tc-button>
    </div>
  </div>
</tpt-dialog>

<tpt-confirm-destruction-dialog (actionApprove)="openSupportChat()"></tpt-confirm-destruction-dialog>
