import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';
import { Moment } from 'moment';

@Component({
  selector: 'tc-datepicker',
  templateUrl: './tc-datepicker.component.html',
  styleUrls: ['./tc-datepicker.component.scss']
})
export class TcDatepickerComponent extends TptControlDirective {

  @Input() label: string;

  @ViewChild('input')input: ElementRef;

  @Input()
  public icon = null;

  @Input()
  public showLabel = true;

  @Input()
  public min: Moment;

  @Input()
  public max: Moment;

  public focused = false;

  checkValue(): void{
    const regex: RegExp = new RegExp('^(0?[1-9]|[12][0-9]|3[01])[.](0?[1-9]|1[012])[.]\\d{4}$');
    const value: string = this.input.nativeElement.value;
    const valid = regex.test(value);
    if (!valid) {
      this.input.nativeElement.value = '';
    }
  }
}
