import { FormContext } from '../../tpt-forms/base/form.context';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SimpleProjectResponseModel } from '../models/simple-project-response.model';
import { SimpleProjectTaskModel } from '@/modules/employer/models/simple-project-task.model';

export class SimplePaymentForm extends FormContext<SimpleProjectResponseModel> {

  static createForm(project: SimpleProjectResponseModel): SimplePaymentForm {
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(project.id),
      jobName: new UntypedFormControl(project?.jobName || '',
        [Validators.required, Validators.maxLength(256)]),
      startDate: new UntypedFormControl(project?.startDate || ''),
      deadline: new UntypedFormControl(project?.deadline || '', [Validators.required]),
      hasStartDate: new UntypedFormControl(!!project?.startDate),
      tasks: new UntypedFormArray(SimplePaymentForm.createTasksArray(project?.tasks || []), Validators.required),
      budget: new UntypedFormControl(project?.budget || '', [Validators.required, Validators.min(1000), Validators.max(599999)]),
      frameworkContractId: new UntypedFormControl(null),
    });

    return new SimplePaymentForm(form, project);
  }

  static createTasksArray(tasksArray: Array<SimpleProjectTaskModel>): Array<UntypedFormGroup> {

    return tasksArray.map((_: SimpleProjectTaskModel) => new UntypedFormGroup(
      {
        id: new UntypedFormControl(_.id),
        taskTitle: new UntypedFormControl(_.taskTitle, [Validators.required, Validators.minLength(5), Validators.maxLength(64)]),
        description: new UntypedFormControl(_.description, [Validators.maxLength(782)]),
        deadline: new UntypedFormControl(_.deadline, [Validators.required]),
        startDate: new UntypedFormControl(_?.startDate || ''),
        hasStartDate: new UntypedFormControl(!!_?.startDate),
      }
    ));
  }
  static createTask(task?: SimpleProjectTaskModel): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(task.id || null),
      taskTitle: new UntypedFormControl(task.taskTitle || null, [Validators.required, Validators.minLength(5), Validators.maxLength(64)]),
      description: new UntypedFormControl(task.description || null, [Validators.maxLength(782)]),
      deadline: new UntypedFormControl(task?.deadline || null, [Validators.required]),
      startDate: new UntypedFormControl(task?.startDate || null),
      hasStartDate: new UntypedFormControl(!!task?.startDate),
    });
  }

  public get id(): AbstractControl { return this.getControl('id'); }
  public get jobName(): AbstractControl { return this.getControl('jobName'); }
  public get description(): AbstractControl { return this.getControl('description'); }
  public get startDate(): AbstractControl { return this.getControl('startDate'); }
  public get deadline(): AbstractControl { return this.getControl('deadline'); }
  public get state(): AbstractControl { return this.getControl('state'); }
  public get tasks(): UntypedFormArray { return this.getControl('tasks') as UntypedFormArray; }
  public get hasStartDate(): AbstractControl { return this.getControl('hasStartDate'); }
  public get budget(): AbstractControl { return this.getControl('budget'); }
  public get frameworkContractId(): AbstractControl { return this.getControl('frameworkContractId'); }

  constructor(
    public form: UntypedFormGroup,
    public data: any,
  ) {
    super(form, data);
  }

  public getFormData(): SimpleProjectResponseModel {
    return this.form.value;
  }

}
