import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { ProfileService } from './services/profile.service';
import { IconsService } from './services/icons.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import moment from 'moment';
import { MobileSidebarComponent } from '@/modules/common-components/mobile-sidebar/mobile-sidebar.component';
import { StateHelperService } from '@/services/state-helper.service';
import { CategoryService } from '@/services/category.service';
import { NewWsService } from '@/services/new-ws.service';
import { AuthV2Service } from '@/services/auth/authV2.service';
import { ConfigLoaderService } from '@/services/load-config.service';
import { CoIconsService } from '@/creative-objects/services/co-icons.service';
import { EditProfileService } from '@/modules/edit-profile/services/edit-profile.service';
import { lastValueFrom } from 'rxjs';

const USER_KEY = 'auth-user';

@Component({
  selector: 'tc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public isEmployer = false;

  public loaded = false;

  public hideHeader = true;

  public hideHeaderOnMainPage = true;

  public hideSidebar = true;

  public showNewHeader: boolean;

  public isMobile = false;

  @ViewChild(SidebarComponent) sidebar: SidebarComponent;
  @ViewChild(MobileSidebarComponent) mobileSidebar: MobileSidebarComponent;

  constructor(
    public profileService: ProfileService,
    private router: Router,
    private translate: TranslateService,
    private iconsService: IconsService,
    private coIconsService: CoIconsService,
    private stateHelper: StateHelperService,
    private auth2Service: AuthV2Service,
    private categoryService: CategoryService,
    private wsService: NewWsService,
    private loadConfigService: ConfigLoaderService,
    private editProfileService: EditProfileService,
  ) {
    (window as any).pdfWorkerSrc = `./scripts/pdf.worker.min.js`;
    iconsService.registerIcons();
    coIconsService.registerIcons();
    translate.addLangs(['ru']);
    translate.use('ru');

    this.loaded = true;

    stateHelper.sidebarOpened.subscribe(() => {
      this.toggleMobileSidebar();
    });

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd || val instanceof NavigationStart) {
        this.hideHeader = val.url === '/' ||
          val.url.startsWith('/login') ||
          val.url.startsWith('/forgotPassword') ||
          val.url.includes('/book-demo') ||
          val.url.includes('/confirm-success') ||
          val.url.includes('/creative-objects') ||
          val.url.includes('/payyourteam') ||
          (val.url.includes('/profiles') && !this.profileService?.currentProfile) ||
          (val.url.includes('/projects') && !this.profileService?.currentProfile) ||
          val.url.includes('/register');

        this.hideHeaderOnMainPage = val.url.includes('/creative-objects') || val.url.includes('/payyourteam') || val.url === '/';

        this.hideSidebar = val.url.includes('/creative-objects') || val.url.includes('/payyourteam');

        this.showNewHeader = !val.url.includes('/creative-objects');
      }

      if (val instanceof NavigationEnd && val.urlAfterRedirects === '/') {
        this.hideHeader = true;
      }
    });

    profileService.newProfileObject.subscribe({
      next: async (value) => {
        await this.wsService.disconnect();
        this.wsService.connect();
      }
    });

    window.addEventListener('resize', this.appHeight);
    this.appHeight();
  }

  appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  async ngOnInit(): Promise<void> {
    await this.loadConfigService.loadConfig();
    if (this.auth2Service.hasValidToken()) {
      await lastValueFrom(this.editProfileService.getUserProfile());
      await lastValueFrom(this.editProfileService.getUserLegal());
    }
    if (!this.auth2Service.hasValidToken() && localStorage.getItem(USER_KEY)) {
      localStorage.removeItem(USER_KEY);
    }
    this.categoryService.getCategoriesV2().subscribe();
    console.log(`Application version is: ${environment.timeStamp || moment().format('DD.MM HH:mm')}`);
  }

  public toggleMobileSidebar(): void {
    this.mobileSidebar.toggleSidebar();
  }
}
