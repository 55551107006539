import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TptControlDirective } from './directives/tpt-control.directive';
import { TranslateModule } from '@ngx-translate/core';
import { TcInputComponent } from './tc-components/tc-input/tc-input.component';
import { TcDropdownComponent } from './tc-components/tc-dropdown/tc-dropdown.component';
import { CommonDirectivesModule } from '@/modules/common-directives/common-directives.module';
import { TcTextareaComponent } from './tc-components/tc-textarea/tc-textarea.component';
import { TcTelInputComponent } from './tc-components/tc-tel-input/tc-tel-input.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { TcButtonComponent } from './tc-components/tc-button/tc-button.component';
import { TcDatepickerComponent } from './tc-components/tc-datepicker/tc-datepicker.component';
import {
  TcAddressAutocompleteComponent
} from './tc-components/tc-address-autocomplete/tc-address-autocomplete.component';
import {
  TcDateRangePickerComponent
} from '@/modules/tpt-forms/tc-components/tc-date-range-picker/tc-date-range-picker.component';
import {
  ExecutorsAutocompleteComponent
} from './tc-components/executors-autocomplete/executors-autocomplete.component';
import { TcSlideToggleComponent } from './tc-components/tc-slide-toggle/tc-slide-toggle.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { LocationDropdownComponent } from './tc-components/location-dropdown/location-dropdown.component';
import {
  ProjectDropdownComponent
} from '@/modules/tpt-forms/tc-components/project-dropdown/project-dropdown.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MaterialModule } from '@/modules/material/material.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonDirectivesModule,
    NgxMatIntlTelInputComponent,
    OverlayModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    TptControlDirective,
    TcInputComponent,
    TcDropdownComponent,
    TcTextareaComponent,
    TcTelInputComponent,
    TcButtonComponent,
    TcDatepickerComponent,
    TcAddressAutocompleteComponent,
    TcDateRangePickerComponent,
    ExecutorsAutocompleteComponent,
    TcSlideToggleComponent,
    LocationDropdownComponent,
    ProjectDropdownComponent,
  ],
  exports: [
    TcInputComponent,
    TcDropdownComponent,
    TcTextareaComponent,
    TcButtonComponent,
    TcDatepickerComponent,
    TcAddressAutocompleteComponent,
    TcDateRangePickerComponent,
    ExecutorsAutocompleteComponent,
    TcSlideToggleComponent,
    LocationDropdownComponent,
    ProjectDropdownComponent,
  ],
  providers: [
    provideNgxMask(),
  ]
})
export class TptFormsModule {
}
