import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  selector: 'job-apply-error-dialog',
  templateUrl: './job-apply-error-dialog.component.html',
  styleUrls: ['./job-apply-error-dialog.component.scss']
})
export class JobApplyErrorDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  public svgIconsEnum = SvgIconsEnum;

  public selfEmployedStatus: string;

  public limitExceeded: boolean;

  private readonly className = 'job-apply-error-dialog';

  private readonly config: MatDialogConfig = {
    width: '460px',
    minHeight: '316px'
  };

  constructor(private profileService: ProfileService,
              public routeHelper: RouteHelper) {
  }

  public open(): void {
    this.selfEmployedStatus = this.profileService.currentProfile.selfEmployedStatus;
    this.limitExceeded = this.profileService.currentProfile.permissions.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED');
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }
}
