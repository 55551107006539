<div class="d-flex menu-root" [ngClass]="color">
  <tpt-notification class="mr-12" *ngIf="profileService.currentProfile"></tpt-notification>

  <div class="menu-button profile-button" matRipple matRippleColor="rgba(0, 0, 0, 0.03)"
       [class.opened]="profileMenuOpened"
       [matMenuTriggerFor]="profileMenu" (menuOpened)="menuSwitch(true, 'PROFILE')"
       (menuClosed)="menuSwitch(false, 'PROFILE')">
    <mat-icon [svgIcon]="svgIconsEnum.User"></mat-icon>
    <tc-status-badge [showIcon]="false"></tc-status-badge>
  </div>
  <mat-menu #profileMenu="matMenu" class="header-profile-menu" xPosition="before">
    <div class="profile-info" *ngIf="(profileService.newProfileObject | async) as profile">
      <tpt-avatar class="mr-8" size="24" [textSizeRatio]="2.5" [user]="profile"></tpt-avatar>
      <div>
        <div class="profile-name">
          {{profile?.firstName}} {{profile?.lastName ? profile?.lastName?.slice(0, 1) + '.' : ''}}
        </div>
      </div>
    </div>
    <button mat-menu-item [routerLink]="['/settings']">
      <mat-icon svgIcon="settings"></mat-icon>
      Настройки
    </button>
    <button mat-menu-item (click)="logout()" class="red-item">
      <mat-icon svgIcon="exit"></mat-icon>
      Выйти из аккаунта
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="createChatWithSupport()">
      <mat-icon [svgIcon]="svgIconsEnum.Support"></mat-icon>
      Поддержка
    </button>
    <a href="https://talent-cloud.ru/legal"
       target="_blank">
      <button mat-menu-item>
        <mat-icon [svgIcon]="svgIconsEnum.LegalInfo"></mat-icon>
        Юридическая информация
      </button>
    </a>
    <a href="https://npd.nalog.ru/faq/" target="_blank">
      <button mat-menu-item>
        <mat-icon [svgIcon]="svgIconsEnum.Link"></mat-icon>
        База знаний самозанятого
      </button>
    </a>
  </mat-menu>

  <div class="menu-button ml-12" matRipple matRippleColor="rgba(0, 0, 0, 0.03)"
       *ngIf="!profileService.currentProfile?.isEmployer()"
       [class.opened]="serviceMenuOpened"
       [matMenuTriggerFor]="serviceMenu" (menuOpened)="menuSwitch(true, 'SERVICE')"
       (menuClosed)="menuSwitch(false, 'SERVICE')"
  >
    <mat-icon [svgIcon]="svgIconsEnum.SwitchService"></mat-icon>
  </div>
  <mat-menu #serviceMenu="matMenu" class=" creative-objects">
    <button disableRipple mat-menu-item [disabled]="currentFlow === 'TC'">
          <span class="button-item">
            <mat-icon [svgIcon]="coSvgIconsEnum.TcSwitcher"></mat-icon>
            <span class="name-state">
              <span class="flow-name">Talent Cloud</span>
              <span class="flow-state">{{currentFlow === 'TC' ? 'Вы сейчас здесь' : 'Перейти'}}</span>
            </span>
          </span>
    </button>
    <button disableRipple mat-menu-item [disabled]="currentFlow === 'CO'" (click)="goToCo()">
          <span class="button-item">
            <mat-icon [svgIcon]="coSvgIconsEnum.CoSwitcher"></mat-icon>
            <span class="name-state">
              <span class="flow-name">Creative Objects</span>
              <span class="flow-state">{{currentFlow === 'CO' ? 'Вы сейчас здесь' : 'Перейти'}}</span>
            </span>
          </span>
    </button>
  </mat-menu>
</div>
