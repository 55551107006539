import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';

@Component({
  selector: 'tc-textarea',
  templateUrl: './tc-textarea.component.html',
  styleUrls: ['./tc-textarea.component.scss']
})
export class TcTextareaComponent extends TptControlDirective {

  @Output()
  public keyup = new EventEmitter();

  @Output()
  public onBlur = new EventEmitter();

  @Input()
  public showLabel = true;

}
