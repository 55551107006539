<ng-container *ngIf="profile.currentProfile.isEmployer && loaded">
  <div class="badge restricted" *ngIf="clientDataInvalid">
    <span class="desktop">Ограниченный доступ</span>
    <span class="mobile">Огр. доступ</span>
  </div>
  <div class="badge client-active" *ngIf="profile.currentProfile.legalStatus === 'INDIVIDUAL_ENTREPRENEUR' && !clientDataInvalid">
    <mat-icon [svgIcon]="svgIconsEnum.IE" *ngIf="showIcon"></mat-icon>
    ИП
  </div>
  <div class="badge client-active" *ngIf="profile.currentProfile.legalStatus === 'LEGAL_ENTITY' && !clientDataInvalid">
    <mat-icon [svgIcon]="svgIconsEnum.LE" *ngIf="showIcon"></mat-icon>
    Юр. лицо
  </div>
</ng-container>

<ng-container *ngIf="profile.currentProfile.isFreelancer">
  <div class="badge active" *ngIf="profile.currentProfile?.selfEmployedStatus === 'ACTIVE' &&
              profile.currentProfile.permissions?.includes('BANK_DETAILS_VALIDATED') &&
              profile.currentProfile.permissions?.includes('LEGAL_INFO_VALIDATED') &&
              !profile.currentProfile.permissions?.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED')"
  >
    <mat-icon [svgIcon]="svgIconsEnum.Active" *ngIf="showIcon"></mat-icon>
    Самозанятый
  </div>

  <div class="badge restricted" *ngIf="['NEW', 'NOT_VERIFIED'].includes(profile.currentProfile?.selfEmployedStatus) ||
              (profile.currentProfile?.selfEmployedStatus === 'ACTIVE' && (!profile.currentProfile.permissions?.includes('BANK_DETAILS_VALIDATED') ||
              !profile.currentProfile.permissions?.includes('LEGAL_INFO_VALIDATED') || profile.currentProfile.permissions?.includes('ANNUAL_PAYMENTS_LIMIT_EXCEEDED')))">
    <span class="desktop">Ограниченный доступ</span>
    <span class="mobile">Огр. доступ</span>
  </div>

  <div class="badge detached" *ngIf="profile.currentProfile?.selfEmployedStatus === 'DETACHED'">
    <mat-icon [svgIcon]="svgIconsEnum.Detached" *ngIf="showIcon"></mat-icon>
    Отвязан от платформы
  </div>
  <div class="badge deregistered" *ngIf="profile.currentProfile?.selfEmployedStatus === 'REMOVED_FROM_REGISTER'">
    <mat-icon [svgIcon]="svgIconsEnum.DeregisteredBadge" *ngIf="showIcon"></mat-icon>
    Снят с учёта
  </div>
</ng-container>
