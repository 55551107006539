import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';

@Component({
  selector: 'tc-input',
  templateUrl: './tc-input.component.html',
  styleUrls: ['./tc-input.component.scss']
})
export class TcInputComponent extends TptControlDirective {

  get hasValue(): boolean {
    return !!this.control.value || this.control.value === 0;
  }

  @Output()
  public onKeyup = new EventEmitter();

  @Output()
  public onBlur = new EventEmitter();

  @Output()
  public onFocus = new EventEmitter();

  @Input()
  public uppercase = false;

  @Input()
  public showLabel = true;

  @Input()
  public mask = '';

  @Input()
  public prefix = '';

  @Input()
  public disabled = false;

  @Input()
  public icon = null;

  @Input()
  public type: 'text' | 'number' | 'range' | 'password' | string;
}
