import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tc-button',
  templateUrl: './tc-button.component.html',
  styleUrls: ['./tc-button.component.scss']
})
export class TcButtonComponent {

  @Input() size: 'small' | 'medium' | 'large' | 'x-large' = 'small';

  @Input() type: 'primary' | 'error' | 'secondary' | 'tertiary' | 'alternative' | 'ghost' = 'primary';

  @Input() disabled: boolean;

  @Output() onClick = new EventEmitter<any>();

  onClickButton(event) {
    this.onClick.emit(event);
  }

}
