import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TptControlDirective } from '@/modules/tpt-forms/directives/tpt-control.directive';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'tc-slide-toggle',
  templateUrl: './tc-slide-toggle.component.html',
  styleUrls: ['./tc-slide-toggle.component.scss']
})
export class TcSlideToggleComponent extends TptControlDirective {

  @Input() color: ThemePalette = 'primary';

  @Input() labelPosition: 'before' | 'after' = 'after';

  @Output() change = new EventEmitter<any>();

}
