import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'tc-executors-autocomplete',
  templateUrl: './executors-autocomplete.component.html',
  styleUrls: ['./executors-autocomplete.component.scss']
})
export class ExecutorsAutocompleteComponent implements OnInit {

  @Output()
  searchOptions: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public keyup = new EventEmitter();

  @Output()
  public onBlur = new EventEmitter();

  @Input()
  public uppercase = false;

  @Input()
  public showLabel = true;

  @Input()
  public disabled = false;

  @Input()
  public icon = null;

  @Input()
  public options: any = [];

  @Input()
  control = new UntypedFormControl();

  public type = 'text';

  public get errorState(): boolean {
    return (this.control.dirty || this.control.touched) && !this.control?.value?.id;
  }

  ngOnInit() {
    this.control.valueChanges.pipe(debounceTime(200)).subscribe((val) => {
      if (!val.trim) {
        return;
      }
      this.searchOptions.emit(val);
    });
  }

  displayFn(item): string {
    if (!item) {
      return '';
    }
    return `${item?.firstName} ${item?.lastName}`;
  }
}
