import { Country } from '../../../models/country';
import { ExpertLevel } from '../../../classes/enums';
import { LanguageLevelModel } from '../../../models/language-level.model';
import { SkillV2Model } from '../../../models/skill-v2.model';
import { PaymentStatesEnum, SimpleProjectFixedPaymentModel } from './simple-project-fixed-payment.model';
import { SimpleProjectHourlyPaymentModel } from './simple-project-hourly-payment.model';
import { SimpleProjectTaskModel } from './simple-project-task.model';
import { ProfileModel } from '../../../models/profile.model';
import { ProjectStateEnum } from '../types/project-state.enum';
import { CurrencyModel } from '../../../models/financev2/currency.model';

export class SimpleProjectResponseModel {

  public country: Country;

  public currency: CurrencyModel;

  public description: string;

  public tasks: Array<SimpleProjectTaskModel>;

  public details: ((SimpleProjectFixedPaymentModel & { budget: number }) | (SimpleProjectHourlyPaymentModel)) & { paymentState: string };

  public budget: number;

  public budgetWithCommission: number;

  public commissionValue: number;

  public commission: number;

  public deadline: string;

  public reviewPeriod: number;

  public expertLevels: Array<ExpertLevel>;

  public files: Array<any>;

  public id: string;

  public languages: Array<LanguageLevelModel>;

  public name: string;

  public jobName: string;

  public owner: ProfileModel;

  public employer: ProfileModel;

  public parentCategoryId: string;

  public projectTimeZone: string;

  public skills: Array<SkillV2Model>;

  public customSkills: Array<string>;

  public subCategoryId: string;

  public visibility: 'PRIVATE' | 'INTERNALLY' | 'PUBLIC';

  public startDate: string;

  public publishDate: string;

  public freelancer: ProfileModel;

  public state: ProjectStateEnum;

  public jobs: any[];

  public type: 'FREELANCE' | 'PAYROLL';

  public currentUserState: string;

  public projectInfo: {
    id: string,
    description: string,
    name: string,
    type: string,
    visibility: 'PRIVATE' | 'INTERNALLY' | 'PUBLIC',
    files: []
  };

  public manualPayments: boolean;

  public projectId: number;

  public arbitragePaid: boolean;

  public paymentState: PaymentStatesEnum;

  public projectName: string;

  public projectVisibility: 'PRIVATE' | 'INTERNALLY' | 'PUBLIC';

  // fields for skills display
  public moreSkillsCount: number;

  public showAllSkills: boolean;

  public jobState: string;

  public projectState: string;

  public jobConversations: {
    chatId?: string,
    employerProfileId?: string,
    freelancerProfileId?: string,
    jobConversationId?: string,
    participationState?: string,
  }[];
}
