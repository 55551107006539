import { Injectable } from '@angular/core';
import { ProjectApiService } from '../../../services/project.api.service';
import { Observable, of, Subject } from 'rxjs';
import { TasksResponseModel } from '../models/tasks-response.model';
import { AddTaskForm } from '../forms/add-task.form';
import { AddTaskFormModel } from '../models/add-task-form.model';
import { SimpleProjectResponseModel } from '../models/simple-project-response.model';
import { tap } from 'rxjs/operators';
import { map, set } from 'lodash';
import * as moment from 'moment';
import { ProjectDetailedTaskModel } from '../models/project-detailed-task.model';
import { CommentsApiService } from './api/comments-api.service';
import { TaskCommentsResponseModel } from '../models/task-comments/task-comments-response.model';
import { TaskCommentsRequestModel } from '../models/task-comments/task-comments-request.model';
import { SendTaskCommentsRequestModel } from '../models/task-comments/send-task-comments-request.model';
import { SendTaskCommentsResponseModel } from '../models/task-comments/send-task-comments-response.model';
import { FileUpload } from '../../../models/fileUpload';
import { TextMessageModel } from '../models/task-comments/text-message.model';
import { ProjectStatusEnum } from '../types/project-status.enum';
import { PriorityTypeEnum } from '../types/priority-type.enum';

@Injectable()
export class ProjectBoardService {

  public currentProjectId: string;

  public projectUpdated: Subject<any> = new Subject<any>();

  private projectInfo: SimpleProjectResponseModel;

  constructor(
    public projectApiService: ProjectApiService,
    private commentsApiService: CommentsApiService,
  ) { }

  public getClassByStatus(status: ProjectStatusEnum): string {
    switch (status) {
      case ProjectStatusEnum.ToDo:
        return 'to-do';
      case ProjectStatusEnum.InProgress:
        return 'in-progress';
      case ProjectStatusEnum.Review:
        return 'review';
      case ProjectStatusEnum.Done:
        return 'done';
      case ProjectStatusEnum.Hold:
        return 'hold';
    }
  }

  public createAddTaskForm(task: ProjectDetailedTaskModel): AddTaskForm {
    return AddTaskForm.createForm(task ? task : new ProjectDetailedTaskModel());
  }

  public getProjectInfo = (id: string): Observable<SimpleProjectResponseModel> => {
    return this.projectApiService.getProjectById(id).pipe(
      tap((project: SimpleProjectResponseModel) => this.projectInfo = project)
    );
  }

  public getProjectTasks = (id: string | null, freelancerId?: string | null, group?: string, taskName?: string, allJobs: boolean = true): Observable<TasksResponseModel> => {
    if (!id) {
      return of(null);
    }

    return this.projectApiService.getProjectTasks(id, freelancerId, group, taskName, allJobs);
  }

  public editBoardTask = (model: AddTaskFormModel): Observable<any> => {
    set(model, 'deadline', model.deadline ? moment(model.deadline).format('YYYY-MM-DD') : null);
    set(model, 'startDate', model.startDate ? moment(model.startDate).format('YYYY-MM-DD') : null);
    return this.projectApiService.editProjectTask(model);
  }

  public getTaskDetails = (taskId: string): Observable<ProjectDetailedTaskModel> => {
    return this.projectApiService.getTaskDetails(taskId);
  }

  public getTaskComments = (taskId: string): Observable<TaskCommentsResponseModel> => {
    const model = new TaskCommentsRequestModel(taskId);
    return this.commentsApiService.getTaskComments(model);
  }

  public sendTaskTextComment = (
    taskId: string,
    text: string,
    files: Array<FileUpload> = [],
  ): Observable<SendTaskCommentsResponseModel> => {
    const messageModel = new TextMessageModel(text, map(files, (_: any) => _.id));
    const model = new SendTaskCommentsRequestModel(taskId, messageModel);

    return this.commentsApiService.sendTaskComments(model);
  }

  public changeTaskStatus = (
    id: string,
    status: ProjectStatusEnum
  ): Observable<{ id: string, status: ProjectStatusEnum }> => {
    const model = { id, status };
    return this.projectApiService.changeTaskStatus(model);
  }

  public deleteTask = (taskId: string): Observable<void> => {
    return this.projectApiService.deleteTask(taskId);
  }

  public changeTaskPriority = (model: { id: string, priority: PriorityTypeEnum }): Observable<void> => {
    return this.projectApiService.changePriority(model);
  }
}
