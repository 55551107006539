import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Constants } from '@/classes/constants';
import { AuthV2Service } from '@/services/auth/authV2.service';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router,
              private authService: AuthV2Service, ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.hasValidToken()) {
      // logged in so return true
      return true;
    }

    const routerStateSnapshotUrl: string = state.url;
    if (routerStateSnapshotUrl.includes('/creative-objects/app/product/')) {
      const idArray = routerStateSnapshotUrl.split('/');
      const id = idArray[idArray.length - 1];
      this.router.navigate([`/creative-objects/catalog/${id}`]);
      return false;
    }

    const service = route.data.service;
    if (service === Constants.CO) {
      this.router.navigate(['/creative-objects']);
      return false;
    }

    if (service === Constants.PYT) {
      this.router.navigate(['/payyourteam/login']);
      return false;
    }

    // not logged in so redirect to login page with the return url
    this.authService.login();
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
