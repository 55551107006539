<tpt-dialog>
  <div *ngIf="selfEmployedStatus === 'NEW' || selfEmployedStatus === null" class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

    <div class="fs-20 fw-700 text-black mb-24">Ограниченный доступ</div>

    <div class="text-gray-600 fw-500 fs-14 mb-28">
      Свяжите свой профиль самозанятого с профилем Talent Cloud и заполните личный кабинет для отправки заявки на проект.
    </div>

    <div class="actions-row mt-48 gg-12">
      <tc-button type="tertiary" size="large" class="full-width" (onClick)="close()">
        Закрыть
      </tc-button>
      <tc-button type="primary" size="large" class="full-width"
                 (onClick)="close()"
                 [routerLink]="routeHelper.fnsAttachPage">
        К привязке к ФНС
      </tc-button>
    </div>
  </div>

  <div *ngIf="selfEmployedStatus === 'ACTIVE' && !limitExceeded" class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

    <div class="fs-20 fw-700 text-black mb-24">
      Ограниченный доступ
    </div>

    <div class="text-gray-600 fw-500 fs-14 mb-28">
      Заполните разделы “Юридические данные” и “Банковские реквизиты” для отправки заявки на проект.
    </div>
    <div class="actions-row mt-48 gg-12">
      <tc-button type="tertiary" size="large" class="full-width"
                 (onClick)="close()">
        Закрыть
      </tc-button>
      <tc-button type="primary" size="large" class="full-width"
                 (onClick)="close()"
                 [routerLink]="routeHelper.settingsLegalData">
        Заполнить
      </tc-button>
    </div>
  </div>

  <div *ngIf="selfEmployedStatus === 'ACTIVE' && limitExceeded" class="wrapper">
    <mat-icon [svgIcon]="svgIconsEnum.ErrIcon" class="mb-28"></mat-icon>

    <div class="fs-20 fw-700 text-black mb-24">
      Ограниченный доступ
    </div>

    <div class="text-gray-600 fw-500 fs-14 mb-28">
      Достигнут годовой лимит выручки.<br/>
      Вы сможете отправлять заявки на проекты с&nbsp;1&nbsp;января следующего года.
    </div>
    <div class="actions-row mt-48 gg-12">
      <tc-button type="tertiary" class="full-width" size="large" (onClick)="close()">
        Закрыть
      </tc-button>
    </div>
  </div>
</tpt-dialog>
