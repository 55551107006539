import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'tpt-bind-instruction-dialog',
  templateUrl: './bind-instruction-dialog.component.html',
  styleUrls: ['./bind-instruction-dialog.component.scss']
})
export class BindInstructionDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  private readonly className = 'tpt-bind-instruction-dialog';

  private readonly config: MatDialogConfig = {
    width: '560px',
    maxWidth: 'calc(100vw - 32px)',
  };

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
