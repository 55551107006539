import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '../../../dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ResourcesService } from '../../../../services/resources.service';
import { ChatApiService } from '../../../../services/chat.api.service';
import { Router } from '@angular/router';
import { RouteHelper } from '../../../../helpers/route.helper';
import { ConfirmDestructionDialogComponent } from '../confirm-destruction-dialog/confirm-destruction-dialog.component';
import { PDFSource } from 'ng2-pdf-viewer';
import { isSmallMobileDevice } from '@/helpers/get-mobile.helper';
import { AuthV2Service } from '@/services/auth/authV2.service';

@Component({
  selector: 'tpt-contract-sign-dialog',
  templateUrl: './contract-sign-dialog.component.html',
  styleUrls: ['./contract-sign-dialog.component.scss']
})
export class ContractSignDialogComponent implements OnInit {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @ViewChild(ConfirmDestructionDialogComponent)
  public changeContract: ConfirmDestructionDialogComponent;

  @Output() signDocumentEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() showSupportButton = false;

  public src: PDFSource;

  public docType: 'contract' | 'wcc' | 'statement' = 'contract';

  public showActions = false;

  public changeContractTitle = 'Изменение условий договора';

  public changeContractText = 'Для изменения договора обратитесь в службу поддержки';

  public isMobileDevice = isSmallMobileDevice();

  private config: MatDialogConfig;

  private className = 'tpt-contract-sign-dialog';

  constructor(private resource: ResourcesService,
              private chatService: ChatApiService,
              private auth2Service: AuthV2Service,
              private routeHelper: RouteHelper,
              private router: Router) { }

  ngOnInit(): void {
    this.isMobileDevice = isSmallMobileDevice();
    this.config = {
      width: '720px',
      height: this.isMobileDevice ? '600px' : '100vh',
    };
  }

  public async open(fileId: string, showActions: boolean, docType: 'contract' | 'wcc' | 'statement'): Promise<void> {
    const token = this.auth2Service.accessToken;
    this.src = {
      url: this.resource.getUrl(fileId),
      httpHeaders: {Authorization: `Bearer ${token}`},
      withCredentials: true,
    };

    this.docType = docType;
    this.showActions = showActions;
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

  public signDocument(): void {
    this.signDocumentEvent.emit(this.docType);
    this.close();
  }

  public changeContractOpen(): void {
    this.changeContract.open('CHANGE_CONTRACT',
      this.changeContractTitle, this.changeContractText, 'primary', 'В чат поддержки');
  }

  public openSupportChat(): void {
    this.chatService.createSupportChat().subscribe({
      next: (chat) => {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(this.routeHelper.contractsChatsUrl(chat.id))
        );
        window.open(url, '_blank');
      }, error: () => {
      }
    });
  }

}
