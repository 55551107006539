import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthV2Service } from '@/services/auth/authV2.service';
import { RouteHelper } from '@/helpers/route.helper';

@Component({
  template: '',
  standalone: true,
})
export class RedirectComponent implements OnInit {

  constructor(private auth2Service: AuthV2Service,
              private router: Router,
              private routeHelper: RouteHelper) {
  }

  ngOnInit() {
    if (this.auth2Service.hasValidToken()) {
      this.router.navigate(this.routeHelper.employerProjectsDashboard);
      return true;
    } else {
      this.auth2Service.login();
      return false;
    }
  }
}
