import { Component, Input } from '@angular/core';

@Component({
  selector: 'tc-password-strength-tooltip',
  templateUrl: './password-strength-tooltip.component.html',
  styleUrls: ['./password-strength-tooltip.component.scss']
})
export class PasswordStrengthTooltipComponent {

  @Input() showTooltip: boolean;

  @Input() passwordComponent;

}
