import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Country } from '@/models/country';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'tc-location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss']
})
export class LocationDropdownComponent implements OnInit {

  @Output()
  searchOptions: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public keyup = new EventEmitter();

  @Output()
  public onBlur = new EventEmitter();

  @Input()
  public uppercase = false;

  @Input()
  public showLabel = true;

  @Input()
  public disabled = false;

  @Input()
  public icon = null;

  @Input()
  public options: any = [];

  @Input()
  public nameField = 'label';

  @Input()
  public valueField = 'value';

  @Input()
  control = new FormControl<Country | null>(null);

  searchControl = new FormControl<string>('');

  public type = 'text';

  public get errorState(): boolean {
    return (this.control.dirty || this.control.touched) && !this.control?.value?.id;
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(debounceTime(200)).subscribe((val) => {
      if (!val?.trim) {
        return;
      }
      this.searchOptions.emit(val);
    });
  }

  displayFn = (item): string => {
    return item?.[this.nameField] || '';
  }

  setValue(event: MatAutocompleteSelectedEvent): void {
    this.control.setValue(event.option.value?.[this.valueField])
  }
}
