<tpt-dialog>
  <div class="dialog-wrapper text-center pt-8">
    <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" ></mat-icon>
    <div class="fs-20 fw-700 text-black mb-20 text-center">Email не найден</div>
  </div>

  <div class="text-gray-600 fw-500 fs-14 mb-32 text-center">
    Мы не нашли профиль с указанным вами email. Пожалуйста, попробуйте ввести email снова
  </div>

  <div class="actions-row">
    <tc-button type="tertiary" size="x-large" class="full-width"
                (onClick)="goToSupport()">Поддержка</tc-button>
    <tc-button type="primary" size="x-large" class="full-width"
               (onClick)="close()">Изменить email</tc-button>
  </div>
</tpt-dialog>
