import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@/modules/dialog/components/dialog/dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { SvgIconsEnum } from '@/types/svg-icons.enum';

@Component({
  selector: 'tc-cancel-binding-dialog',
  templateUrl: './cancel-binding-dialog.component.html',
  styleUrls: ['./cancel-binding-dialog.component.scss']
})
export class CancelBindingDialogComponent {

  @ViewChild(DialogComponent)
  public dialog: DialogComponent;

  @Output()
  public approveAction: EventEmitter<void> = new EventEmitter();

  public svgIconsEnum = SvgIconsEnum;

  private config: MatDialogConfig = {
    width: '600px',
  };
  private className = 'tc-cancel-binding-dialog';

  public open(): void {
    this.dialog.config = this.config;
    this.dialog.open(this.className);
  }

  public close(): void {
    this.dialog.close();
  }

}
