import { Pipe, PipeTransform } from '@angular/core';
import { EmployerLegalStatusEnum } from '@/models/legalStatuses.enum';

@Pipe({
  name: 'shortCompanyName'
})
export class ShortCompanyNamePipe implements PipeTransform {
  transform(employer: any, args?: any): any {
    if (employer?.legalStatus === EmployerLegalStatusEnum.INDIVIDUAL_ENTREPRENEUR &&
      employer.companyName.includes('Индивидуальный предприниматель')) {
      return employer.companyName.replace('Индивидуальный предприниматель', 'ИП');
    } else {
      return employer.companyName;
    }
  }
}
