import { Component } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { RouteHelper } from '@/helpers/route.helper';
import { Router, RouterLink } from '@angular/router';
import { ProfileService } from '@/services/profile.service';
import { ProfilesApiService } from '@/services/profiles.api.service';
import { lastValueFrom } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { MatRipple } from '@angular/material/core';
import { TcCheckboxComponent } from '@/modules/tpt-forms/tc-components/tc-checkbox/tc-checkbox.component';
import { FormsModule } from '@angular/forms';
import { TptFormsModule } from '@/modules/tpt-forms/tpt-forms.module';

@Component({
  selector: 'app-accept-tc',
  templateUrl: './accept-tc.component.html',
  styleUrls: ['./accept-tc.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatRipple,
    TcCheckboxComponent,
    FormsModule,
    TptFormsModule,
    RouterLink
  ]
})
export class AcceptTcComponent {

  public svgIconsEnum = SvgIconsEnum;

  public radioButton = 'SETTINGS';

  public form = {
    userAgreement: false,
    privacyPolicy: false,
    transfer: false,
  };

  constructor(private router: Router,
              private profile: ProfileService,
              private profileApiService: ProfilesApiService,
              private routeHelper: RouteHelper) { }

  public start(): void {
    if (!this.form.privacyPolicy || !this.form.transfer || !this.form.userAgreement) {
      return;
    }

    lastValueFrom(this.profileApiService.acceptTalentCloud())
      .then(() => this.profile.getUserInfo())
      .then(() => {
        this.router.navigate(this.radioButton === 'SETTINGS' ? this.routeHelper.settings : this.routeHelper.search);
      });

  }

}
