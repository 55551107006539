import { Component, Input, OnInit } from '@angular/core';
import { SvgIconsEnum } from '@/types/svg-icons.enum';
import { ProfileService } from '@/services/profile.service';
import { ProjectStateEnum } from '@/modules/employer/types/project-state.enum';
import { ResourcesService } from '@/services/resources.service';
import { lastValueFrom } from 'rxjs';
import { CategoryV2Model } from '@/models/category-v2.model';
import { CategoryService } from '@/services/category.service';
import { Language } from '@/models/language';
import { LanguageService } from '@/services/language.service';

@Component({
  selector: 'tc-job-info-card',
  templateUrl: './job-info-card.component.html',
  styleUrls: ['./job-info-card.component.scss']
})
export class JobInfoCardComponent implements OnInit {

  @Input() project;

  public svgIconsEnum = SvgIconsEnum;

  public projectStateEnum = ProjectStateEnum;

  public categories: CategoryV2Model[] = [];

  public languagesArray: Language[] = [];

  constructor(public profileService: ProfileService,
              private resource: ResourcesService,
              private categoryService: CategoryService,
              private languageService: LanguageService,

  ) { }

  ngOnInit(): void {
    this.getCategoriesAndLanguages();
  }

  public getUserIsExecutor(): boolean {
    return this.project.jobConversations.some(item =>
      item.participationState === 'PARTICIPANT' && item.freelancerProfileId === this.profileService.currentProfile.id
    );
  }

  public downloadFile(doc) {
    this.resource.getFile(doc?.id);
  }

  public getProjectCategory(job) {
    const category = this.categories.find(c => c.id === job.parentCategoryId);
    job.category = category;
    return category?.nameRu;
  }

  public getProjectSubCategory(job) {
    const category = this.categories.find(c => c.id === job.parentCategoryId);
    const subCategory = category?.children.find(c => c.id === job.subCategoryId);
    job.subCategory = subCategory;
    return subCategory?.nameRu;
  }

  public getLangByCode(lang): string {
    const language = this.languagesArray?.find(item => item.code === lang.code);
    return language?.nameRu;
  }

  public specSkills(job): any[] {
    const skills = job?.skills || [];
    const customSkills = job?.customSkills || [];

    return [...skills, ...customSkills];
  }

  public getTalentStatus(): string {
    if (this.getUserIsExecutor()) {
      return `${this.profileService.currentProfile.firstName} ${this.profileService.currentProfile.lastName}`;
    }

    switch (this.project?.state) {
      case ProjectStateEnum.Init: return 'Еще не найден';
      case ProjectStateEnum.Accepted: return 'Исполнитель уже найден';
      case ProjectStateEnum.Started: return 'Исполнитель уже найден';
      case ProjectStateEnum.Withdrawn: return 'Еще не найден';
      case ProjectStateEnum.Ended: return 'Исполнитель уже найден';
      default: return 'Еще не найден';
    }
  }

  private async getCategoriesAndLanguages() {
    this.languagesArray = await lastValueFrom(this.languageService.getLanguagesV2());
    this.categories = await lastValueFrom(this.categoryService.getCategoriesV2());
  }

}
