<tpt-dialog>
  <div class="dialog-wrapper">
    <ng-container *ngIf="!receiptCanceled">
      <div class="headline mb-16">
        Аннулировать чек?
      </div>
      <div class="regular-14-20">
        Аннулирование чека приведете к перерасчету налога.<br/>
        Если вы уверенны, что хотите аннулировать чек, выберите причину:
      </div>

      <div class="mt-16 regular-14-20">
        <mat-radio-group [formControl]="reason">
          <div *ngFor="let reason of reasons">
            <mat-radio-button class="mb-12" color="primary" [value]="reason.code">{{reason?.description}}</mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
      <div class="actions-row mt-40">
        <tc-button type="tertiary" size="medium" (onClick)="close()">
          Отмена
        </tc-button>
        <tc-button type="error" size="medium" class="cancel-receipt ml-12" (onClick)="cancelReceipt(reason.value)">
          <svg width="14" height="16" class="mr-8" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.21875 0.5625C4.375 0.21875 4.71875 0 5.09375 0H8.875C9.25 0 9.59375 0.21875 9.75 0.5625L10 1H13C13.5312 1 14 1.46875 14 2C14 2.5625 13.5312 3 13 3H1C0.4375 3 0 2.5625 0 2C0 1.46875 0.4375 1 1 1H4L4.21875 0.5625ZM12.3125 14.5938C12.2812 15.4062 11.625 16 10.8125 16H3.15625C2.34375 16 1.6875 15.4062 1.65625 14.5938L0.96875 4H13L12.3125 14.5938Z" fill="#DB495C"/>
          </svg>
          Аннулировать
        </tc-button>
      </div>
    </ng-container>
    <div class="canceled" *ngIf="receiptCanceled">
      <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="28" fill="#F4F9F7"/>
        <path d="M28 17.6875C24.1914 17.6875 20.7383 19.7188 18.8086 22.9688C16.9297 26.2695 16.9297 30.2812 18.8086 33.5312C20.7383 36.832 24.1914 38.8125 28 38.8125C31.7578 38.8125 35.2109 36.832 37.1406 33.5312C39.0195 30.2812 39.0195 26.2695 37.1406 22.9688C35.2109 19.7188 31.7578 17.6875 28 17.6875ZM28 41.25C23.3281 41.25 19.0625 38.8125 16.7266 34.75C14.3906 30.7383 14.3906 25.8125 16.7266 21.75C19.0625 17.7383 23.3281 15.25 28 15.25C32.6211 15.25 36.8867 17.7383 39.2227 21.75C41.5586 25.8125 41.5586 30.7383 39.2227 34.75C36.8867 38.8125 32.6211 41.25 28 41.25ZM25.9688 32.3125H27.1875V29.0625H25.9688C25.2578 29.0625 24.75 28.5547 24.75 27.8438C24.75 27.1836 25.2578 26.625 25.9688 26.625H28.4062C29.0664 26.625 29.625 27.1836 29.625 27.8438V32.3125H30.0312C30.6914 32.3125 31.25 32.8711 31.25 33.5312C31.25 34.2422 30.6914 34.75 30.0312 34.75H25.9688C25.2578 34.75 24.75 34.2422 24.75 33.5312C24.75 32.8711 25.2578 32.3125 25.9688 32.3125ZM28 25C27.0859 25 26.375 24.2891 26.375 23.375C26.375 22.5117 27.0859 21.75 28 21.75C28.8633 21.75 29.625 22.5117 29.625 23.375C29.625 24.2891 28.8633 25 28 25Z" fill="#458D70"/>
      </svg>

      <div class="mb-8 fs-20 fw-700 text-black mt-24">Аннулирование чека</div>

      <div class="mb-32 text-gray-600">
        Чек был успешно аннулирован
      </div>

      <tc-button type="tertiary" size="large" class="full-width mt-32" (onClick)="close()">Понятно</tc-button>
    </div>
  </div>
</tpt-dialog>
