<tpt-dialog>
  <div class="dialog-wrapper text-center pt-8">
    <mat-icon [svgIcon]="svgIconsEnum.WarnIcon" ></mat-icon>
    <div class="fs-20 fw-700 text-black mb-20 text-center">Ошибка регистрации</div>
  </div>

  <div class="text-gray-600 fw-500 fs-14 mb-32 text-center">
    Данный email уже используется. Вы можете восстановить пароль
    к данной учетной записи или ввести другой email
  </div>

  <div class="actions-row">
    <tc-button type="tertiary" size="x-large" class="full-width"
                (onClick)="goToRestorePassword()">Восстановить доступ</tc-button>
    <tc-button type="primary" size="x-large" class="full-width"
               (onClick)="useAnotherEmail()">Ввести другой email</tc-button>
  </div>
</tpt-dialog>
