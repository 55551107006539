import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteHelper {

  static EMPTY_PATH = '';

  static PROJECTS_DASHBOARD_PATH = 'jobs';

  static PROJECTS_DASHBOARD_COMPLETED_PATH = 'completed';

  static CONTRACTS_PATH = 'contracts';

  static FRAMEWORK_PATH = 'framework-agreements';

  static NOTIFICATIONS = 'notifications';

  static SETTINGS_PATH = 'settings';

  static SETTINGS_GENERAL_PATH = 'general';

  static SETTINGS_CONTACTS_PATH = 'contacts';

  static SETTINGS_SPECIALIZATION_PATH = 'specialization';

  static SETTINGS_LEGAL_STATUS_PATH = 'legal-status';

  static SETTINGS_LEGAL_DATA_PATH = 'legal-data';

  static SETTINGS_BANK_DETAILS_PATH = 'bank-details';

  static SETTINGS_SECURITY_PATH = 'security';

  static SETTINGS_FEEDBACK_PATH = 'feedback';

  static CONTRACTS_PROPOSALS_PATH = 'proposal';

  static CONTRACTS_PROPOSALS_URL = 'proposal/:id';

  static CONTRACTS_CHATS_PATH  = 'chat';

  static CONTRACTS_CHATS_URL = 'chat/:id';

  static CONTRACTS_EMPLOYER = 'employer';

  static CONTRACTS_FREELANCER = 'freelancer';

  static PROJECTS_DASHBOARD_DRAFTS_PATH = 'drafts';

  static PROJECT_URL = ':id';

  static TRACKER = 'task-tracker';

  static DOCUMENTS = 'documents';

  static TEAM = 'team';

  static INFO = 'info';

  static ACCESS = 'access';

  static USERS = 'users';

  static ROLES = 'roles';

  static EDIT_SIMPLE_PROJECT_PATH = 'project-editor';

  static EDIT_SIMPLE_PROJECT_URL = 'project-editor/:id';

  static FINANCE_PATH = 'finance';

  static FINANCE_COMMON_PATH = 'common';

  static FINANCE_PAYMENT_DETAILS_PATH = 'payment-details';

  static MAKE_DEPOSIT_PATH = 'accountDeposit';

  static SEARCH_PATH = 'search';

  static PROJECTS = 'projects';

  static FREELANCERS = 'freelancers';

  static CREATE_JOB = 'new-job';

  static USER_PAGE = 'profiles';

  static PROJECTS_PAGE = 'projects';

  static FNS_PAGE = 'fns';

  static CREATIVE_OBJECTS = 'creative-objects';

  static APP = 'app';

  static ACCEPT = 'accept';

  static EXECUTORS = 'executors';

  static CLIENTS = 'clients';

  static ACTIVE = 'active';

  static ARCHIVE = 'archive';

  static REGISTER = 'register';

  static LOGIN = 'login';

  static MASS_PAYMENTS = 'mass-payments';

  static PAYMENTS = 'payments';

  static NEW = 'new';

  static PAYYOURTEAM = 'payyourteam';

  public get home(): Array<any> {
    return [RouteHelper.EMPTY_PATH];
  }

  public get register(): Array<any> {
    return [RouteHelper.EMPTY_PATH, RouteHelper.REGISTER];
  }

  public get login(): Array<any> {
    return [RouteHelper.EMPTY_PATH, RouteHelper.LOGIN];
  }

  public get finances(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
    ];
  }

  public get financesCommon(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
      RouteHelper.FINANCE_COMMON_PATH,
    ];
  }

  public get financesPaymentDetails(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FINANCE_PATH,
      RouteHelper.FINANCE_PAYMENT_DETAILS_PATH,
    ];
  }

  public get employerProjectsDashboard(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
    ];
  }

  public projectEditor(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      RouteHelper.EDIT_SIMPLE_PROJECT_PATH,
      id,
    ];
  }

  public get newProjectEditor(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      RouteHelper.EDIT_SIMPLE_PROJECT_PATH,
    ];
  }

  public employerProjectPage(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
    ];
  }

  public newJobPage(id: number): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TEAM,
      RouteHelper.CREATE_JOB
    ];
  }

  public employerProjectPageTeam(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TEAM
    ];
  }

  public employerProjectInfoPage(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.INFO
    ];
  }

  public employerProjectTaskTracker(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.TRACKER
    ];
  }

  public projectDocuments(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.DOCUMENTS
    ];
  }

  public employerProjectPageAccess(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS
    ];
  }

  public employerAccessPageUsers(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS,
      RouteHelper.USERS
    ];
  }

  public employerAccessPageRoles(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_DASHBOARD_PATH,
      id,
      RouteHelper.ACCESS,
      RouteHelper.ROLES
    ];
  }

  // contracts routes for employers
  public get contracts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
    ];
  }

  public get contractsProposals(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_PROPOSALS_PATH,
    ];
  }

  public contractsProposalsUrl(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_PROPOSALS_PATH,
      id,
    ];
  }

  public get contractsChats(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_CHATS_PATH,
    ];
  }

  public contractsChatsUrl(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CONTRACTS_PATH,
      RouteHelper.CONTRACTS_CHATS_PATH,
      id,
    ];
  }

  public get frameworkContracts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FRAMEWORK_PATH,
    ];
  }

  public get settings(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
    ];
  }

  public get settingsGeneral(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_GENERAL_PATH,
    ];
  }

  public get settingsSpecialization(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_SPECIALIZATION_PATH,
    ];
  }

  public get settingsLegalStatus(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_LEGAL_STATUS_PATH,
    ];
  }

  public get settingsLegalData(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_LEGAL_DATA_PATH,
    ];
  }

  public get settingsBankDetails(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_BANK_DETAILS_PATH,
    ];
  }

  public get settingsContacts(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_CONTACTS_PATH,
    ];
  }

  public get settingsSecurity(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_SECURITY_PATH,
    ];
  }

  public get settingsFeedback(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SETTINGS_PATH,
      RouteHelper.SETTINGS_FEEDBACK_PATH,
    ];
  }

  public get search(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SEARCH_PATH,
    ];
  }

  public get projectSearch(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SEARCH_PATH,
      RouteHelper.PROJECTS,
    ];
  }

  public get freelancerSearch(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.SEARCH_PATH,
      RouteHelper.FREELANCERS,
    ];
  }

  public get notifications(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.NOTIFICATIONS
    ];
  }

  public userPage(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.USER_PAGE,
      id
    ];
  }

  public projectPage(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PROJECTS_PAGE,
      id
    ];
  }

  public get fnsAttachPage(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.FNS_PAGE,
    ];
  }

  public get creativeObjects(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CREATIVE_OBJECTS,
      RouteHelper.APP,
    ];
  }

  public get acceptPage(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.ACCEPT,
    ];
  }

  public get executorsPage(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.EXECUTORS,
    ];
  }
  public get activeExecutorsPage(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.EXECUTORS,
      RouteHelper.ACTIVE,
    ];
  }
  public get archiveExecutorsPage(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.EXECUTORS,
      RouteHelper.ARCHIVE,
    ];
  }

  public get clientsPage(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.CLIENTS,
    ];
  }

  public get payments(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.PAYMENTS,
    ];
  }

  public get massPayments(): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.MASS_PAYMENTS,
    ];
  }

  public massPayment(id: string): Array<any> {
    return [
      RouteHelper.EMPTY_PATH,
      RouteHelper.MASS_PAYMENTS,
      id
    ];
  }
}
