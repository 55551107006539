import { Injectable } from '@angular/core';
import { HttpParams as NativeHttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from '@/services/base.service';
import { TasksResponseModel } from '../modules/employer/models/tasks-response.model';
import { AddTaskFormModel } from '../modules/employer/models/add-task-form.model';
import { ProjectDetailedTaskModel } from '../modules/employer/models/project-detailed-task.model';
import { ProjectStatusEnum } from '../modules/employer/types/project-status.enum';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { PriorityTypeEnum } from '../modules/employer/types/priority-type.enum';
import { EditMultiProjectModel } from '../modules/employer/models/edit-multi-project.model';
import { NegotiationResponse } from '../modules/contracts/components/parts/contracts-project-card/contracts-project-card.component';
import { SimpleProjectResponseModel } from '@/modules/employer/models/simple-project-response.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectApiService {

  public activeProjectsCount = 0;

  public draftProjectsCount = 0;

  public completedProjectsCount = 0;

  public currentProject: SimpleProjectResponseModel = null;

  constructor(private baseService: BaseService) {}

  public getAllJobsList = (params?): Observable<any> => {
    return this.baseService.executeGet<any>('v2/projects/list', {params}).pipe(
      tap(this.countProjectsByType),
      map(res => res.projects),
    );
  }

  public getProjectById = (id: string): Observable<SimpleProjectResponseModel> => {
    if (!id) {
      return of(null);
    }

    return this.baseService.executeGet('v2/projects', { params: { id }});
  }

  public getContractById = (id: string): Observable<NegotiationResponse> => {
    if (!id) {
      return of(null);
    }

    return this.baseService.executeGet('v2/jobConversation', { params: { id }});
  }

  public changeProjectVisibility = (model: { id: string, visibility: string }): Observable<void> => {
    return this.baseService.executePut('v2/projects/visibility', model);
  }

  public saveProjectToDraftsV2 = (model: EditMultiProjectModel): Observable<any> => {
    return this.baseService.executePut('v2/projects', model);
  }

  public publishProject = (id): Observable<void> => {
    return this.baseService.executePut(`v2/projects/${id}/publish`, null);
  }

  public moveProjectToDrafts = (projectId: string): Observable<void> => {
    return this.baseService.executePut(`v2/projects/${projectId}/draft`, null);
  }

  public withdrawProject = (projectId: string): Observable<void> => {
    return this.baseService.executePut(`v2/projects/${projectId}/withdraw`, null);
  }

  public deleteProjectV2 = (id: string): Observable<void> => {
    return fromPromise(this.baseService.executeDelete('v2/projects', { params: { id } }));
  }

  public completeProject = (projectId: string): Observable<void> => {
    return this.baseService.executePut(`v2/projects/${projectId}/stop`, null);
  }

  public completeJob = (jobId: string): Observable<void> => {
    return this.baseService.executePut(`v2/jobs/${jobId}/stop`, null);
  }

  public getProjectTasks = (id: string, freelancerId?: string, group?: string, taskName?: string, allJobs?: boolean): Observable<TasksResponseModel> => {
    const params = {
      id, freelancerId, allJobs, criterion: group, taskName
    };

    if (!freelancerId) { delete params.freelancerId; }
    if (!group) { delete params.criterion; }
    if (!taskName) { delete params.taskName; }
    if (!allJobs) {delete params.allJobs; }

    return this.baseService.executeGet('v2/projects/tasks', { params });
  }

  public editProjectTask = (model: AddTaskFormModel): Observable<any> => {
    return this.baseService.executePut('v2/tasks', model);
  }

  public getTaskDetails = (id: string): Observable<ProjectDetailedTaskModel> => {
    return this.baseService.executeGet('v2/tasks', { params: { id }});
  }

  public changeTaskStatus = (
    model: { id: string, status: ProjectStatusEnum }
  ): Observable<{ id: string, status: ProjectStatusEnum }> => {
    return this.baseService.executePut('v2/tasks/status', model);
  }

  public deleteTask = (id: string): Observable<void> => {
    const params = { params: { id }};
    return fromPromise(this.baseService.executeDelete('v2/tasks', params));
  }

  public getVacancies = (projectId: string, stateType?: string): Observable<any> => {
    const params = {
      projectId,
      stateType
    };
    if (!stateType) { delete params.stateType; }

    return this.baseService.executeGet('v2/jobs/vacancies', {params});
  }

  public changePriority = (model: { id: string, priority: PriorityTypeEnum }): Observable<void> => {
    return this.baseService.executePut('v2/tasks/priority', model);
  }

  public acceptTask(id, body = null): Observable<any> {
    if (body) {
      Object.keys(body).forEach(key => {
        if (key !== 'text' && key !== 'projectId') {
          body[key] = body[key] / 100;
        }
      });
    }

    return this.baseService.executePut('v2/tasks/accept', body, { params : {id}});
  }

  public rejectTask(id, reason) {
    return this.baseService.executePut('v2/tasks/decline', {taskId: id, reason});
  }

  public openDispute(id, reason) {
    return this.baseService.executePut('v2/tasks/dispute', { taskId: id, reason });
  }

  public saveJob(job): Observable<any> {
    return this.baseService.executePut('v2/jobs', job );
  }

  public getListOfProjects(params: any): Observable<any> {
    const queryString = this.transformParams(params);
    return this.baseService.executeGet(`v2/projects/find?${queryString}`);
  }

  public getInitiatedJobs(): Observable<any> {
    return this.baseService.executeGet(`v2/projects/initedJobs`)
      .pipe(map((res: any[]) => res.filter(job => job?.category?.id)));
  }

  public getMyProjectsList(name: string = '', state: string[] = []): Observable<any> {
    return this.baseService.executeGet('v2/projects/myProjects', {params: { name, state }});
  }

  public getMyProjectsWithProposal(name: string = '', state: string[] = []): Observable<any> {
    return this.baseService.executeGet('v2/projects/myProjectsWithProposal', {params: { name, state }});
  }

  public getFreelancers(id: string): Observable<any> {
    return this.baseService.executeGet('v2/projects/freelancers', {params: {id}});
  }

  public getDocs = (projectId: string): Observable<any> => {
    return this.baseService.executeGet(`v2/projects/payments/${projectId}/docs`);
  }

  public signContract = (projectId: string | number): Observable<any> => {
    return this.baseService.executePut(`v2/projects/payments/signContract`, null, {params: { projectId }});
  }

  public signWCC = (projectId: string | number): Observable<any> => {
    return this.baseService.executePut(`v2/projects/payments/signWorkCompletionCertificate`, null, {params: { projectId }});
  }

  public startProject = (id: string): Observable<any> => {
    return this.baseService.executePut(`v2/projects/start`, null, {params: { id }});
  }

  public generateClosingDocs = (projectId: string): Promise<any> => {
    return this.baseService.executePost('v2/projects/payments/generateClosingDocs', null, {params: { projectId }});
  }

  public finishJob = (projectId: string, body = null): Observable<any> => {
    if (body) {
      Object.keys(body).forEach(key => {
        if (key !== 'text' && key !== 'projectId') {
          body[key] = body[key] / 100;
        }
      });
    }

    return this.baseService.executePut('v2/projects/finish', body, {params: { id: projectId }});
  }

  public uploadProjectFile = (file: File, projectId: string): Promise<any>  => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projectId', projectId);

    return this.baseService.executePost('v2/projects/projectFile', formData);
  }

  public deleteProjectFile = (fileId: string, projectId: string): Promise<any>  => {
    return this.baseService.executeDelete('v2/projects/projectFile', { params: { fileId, projectId } });
  }

  public uploadTaskFile = (file: File, taskId: string): Promise<any>  => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('taskId', taskId);

    return this.baseService.executePost('v2/tasks/addFile', formData);
  }

  public deleteTaskFile = (fileId: string, taskId: string): Promise<any>  => {
    return this.baseService.executeDelete('v2/tasks/deleteFile', { params: { fileId, taskId } });
  }

  public getSettings = (): Observable<any> => {
    return this.baseService.executeGet(`settings`);
  }

  private transformParams(params): string {
    const languageLevels = params.languageLevels;
    let languageLevelsString = '';

    delete params.languageLevels;
    const checkParamsList = ['levels', 'categories', 'skills'];
    checkParamsList.forEach(item => {
      if (params[item] && !params[item].length) {
        delete params[item];
      }
    });

    if (!params.countryCode || params.countryCode === 'Irrelevant') {
      delete params.countryCode;
    }

    let newParams = new NativeHttpParams();
    Object.keys(params).forEach(param => {
      newParams = newParams.append(param, params[param]);
    });

    const queryString = newParams.toString();

    if (languageLevels && languageLevels.length) {
      for (let i = 0; i < languageLevels.length; i++) {
        languageLevels[i].index = i;
      }
      languageLevelsString = languageLevels.map(l => {
        return `&languageLevels%5B${l.index}%5D.code=${l.code}&languageLevels%5B${l.index}%5D.level=${l.level}`;
      }).join('');
    }

    return `${queryString}${languageLevelsString}`;
  }

  private countProjectsByType = (count): void => {
    this.resetProjectsCount();

    this.activeProjectsCount = count.activeCount;
    this.draftProjectsCount = count.draftCount;
    this.completedProjectsCount = count.endedCount;
  }

  private resetProjectsCount(): void {
    this.activeProjectsCount = 0;
    this.draftProjectsCount = 0;
    this.completedProjectsCount = 0;
  }

}
