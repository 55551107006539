<div
  [class.error-state]="errorState"
  class="input-field"
  [class.has-value]="!!control.value"
>
  <textarea
    [formControl]="control"
    [placeholder]="placeholder"
    (keyup)="keyup.emit()"
    (blur)="onBlur.emit()"
  ></textarea>
  <div *ngIf="showLabel" class="label">
    <ng-content select="[label]"></ng-content>
  </div>
</div>

<mat-hint [align]="hintAlign" *ngIf="!errorState">
  <ng-content select="[hint]"></ng-content>
</mat-hint>

<mat-error [class.-invisible]="!errorState">
  {{ error | translate}}
</mat-error>
