<tpt-dialog>
  <div class="heading mb-20">Инструкция по предоставлению прав</div>

  <div class="subhead-1 mb-8">В мобильном приложении “Мой налог”</div>

  <ol class="mb-24 regular-16-24">
    <li>Перейдите в раздел "Прочее"</li>
    <li>Перейдите в раздел "Партнеры"</li>
    <li>Выберите платформу Talent Cloud</li>
    <li>Подтвердите список предоставленных прав</li>
    <li>Нажмите на кнопку "Подключить"</li>
  </ol>

  <div class="subhead-1 mb-8">В личном кабинете самозанятого на сайте
    <a href="https://lknpd.nalog.ru/" target="_blank" class="detected-link">https://lknpd.nalog.ru/</a>
  </div>

  <ol class="mb-24 regular-16-24">
    <li>Перейдите в раздел "Настройки"</li>
    <li>Перейдите в раздел "Партнеры"</li>
    <li>Выберите платформу Talent Cloud</li>
    <li>Подтвердите список предоставленных прав</li>
    <li>Нажмите на кнопку "Подключить"</li>
  </ol>

</tpt-dialog>
